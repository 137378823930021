import * as tslib_1 from "tslib";
/**
 * Convenience class for easier and more error safe handling with URLs.
 */
var Url = /** @class */ (function () {
    /**
     * c'tor
     * @param prefix
     * @param url_string
     */
    function Url(prefix, url_string) {
        var e_1, _a;
        if (url_string === void 0) { url_string = null; }
        /// available prefixes to be used for a URL
        this.prefixes = {
            ssl: 'https://',
            standard: 'http://',
            socket: 'ws://',
        };
        /// contains all elements of a URL
        this.routes = [];
        if (prefix !== null && prefix !== undefined) {
            this.routes.push(this.prefixes[prefix]);
        }
        if (url_string !== null) {
            var segments = url_string.split('/');
            try {
                for (var segments_1 = tslib_1.__values(segments), segments_1_1 = segments_1.next(); !segments_1_1.done; segments_1_1 = segments_1.next()) {
                    var piece = segments_1_1.value;
                    this.routes.push(piece);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (segments_1_1 && !segments_1_1.done && (_a = segments_1.return)) _a.call(segments_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    }
    /**
     * Adds a new route to the URL.
     * @param next
     */
    Url.prototype.push = function (next) {
        if (next[next.length - 1] === '/') {
            this.routes.push(next.slice(0, next.length - 1));
            return;
        }
        this.routes.push(next);
    };
    /**
     * Pops the last route.
     */
    Url.prototype.pop = function () {
        return this.routes.pop();
    };
    /**
     * Converts the URL to a machine readable URL string.
     */
    Url.prototype.toString = function () {
        var output = '';
        if (this.prefix !== null && this.prefix !== undefined) {
            output += this.prefix;
        }
        for (var i = 0; i < this.routes.length; ++i) {
            if (i === 0) {
                output += this.routes[i];
            }
            else {
                output += '/' + this.routes[i];
            }
        }
        return output;
    };
    return Url;
}());
export { Url };
