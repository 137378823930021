import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ApiService } from '../../services/api.service';
import { MockDataService } from '../../mock-data.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { UserService } from '../../user.service';
import { RemainingTimeIndicatorComponent } from '../../remaining-time-indicator/remaining-time-indicator.component';
var LiveDetails = /** @class */ (function () {
    function LiveDetails(_apiService, _mockService, _userService) {
        this._apiService = _apiService;
        this._mockService = _mockService;
        this._userService = _userService;
        this.refresh = [];
        this.zoomlevels = [
            /*
            {
                name: '1 Monat',
                level: 6,
                interval: 60 * 60 * 24,
                offset: 60 * 24 * 30,
                resolution: 60 * 60 * 24 * 7,
                format: '%d.%m.%Y'
            },
            {
                name: '1 Woche',
                level: 5,
                interval: 60 * 60 * 24,
                offset: 60 * 24 * 7,
                resolution: 60 * 60 * 24,
                format: '%d.%m.%Y'
            },
            */
            {
                name: '1 Tag',
                hint: 'ø pro 15 Minuten',
                level: 4,
                interval: 15 * 60,
                offset: 60 * 24,
                resolution: 60 * 60 * 4,
                format: '%H:%M Uhr'
            },
            {
                name: '12 Stunden',
                hint: 'ø pro Minute',
                level: 3,
                interval: 60,
                offset: 60 * 12,
                resolution: 60 * 60,
                format: '%H:%M Uhr'
            },
            {
                name: '1 Stunde',
                hint: 'ø pro Minute',
                level: 2,
                interval: 60,
                offset: 60,
                resolution: 60 * 15,
                format: '%H:%M Uhr'
            },
            {
                name: '5 Minuten',
                hint: 'ø pro Sekunde',
                level: 1,
                interval: 1,
                offset: 5,
                resolution: 60,
                format: '%H:%M:%S Uhr'
            }
        ];
        this.zoomlevel = 1;
        this.position = 1;
        this.disabled = true;
        this.chart = {};
        this.status = {
            trend: 0,
            noZone: true,
            since: null
        };
        this.current_demo_idx = 0;
        /*
         * Happy hour stuff
         */
        this.userHasHappyHour = false;
        this.currentDataset = [];
        this.yesterdayHappyHourStart = null;
        this.yesterdayHappyHourEnd = null;
        this.todayHappyHourStart = null;
        this.todayHappyHourEnd = null;
        this.currentHappyHourStart = null;
        this.currentHappyHourEnd = null;
        this.onUserHasHappyHour = new Subject();
    }
    LiveDetails.prototype.ngOnInit = function () {
        var _this = this;
        this.initializeChart();
        this.onUserHasHappyHour.subscribe(function (value) {
            if (value) {
                _this.userHasHappyHour = value;
                _this.getLiveConsumption();
            }
        });
    };
    LiveDetails.prototype.ngAfterViewInit = function () {
        // this.initializeChart();
        if (this._apiService.isDemoMode()) {
            this.initializeMockData();
            return;
        }
        this.initializeApiConnection();
    };
    LiveDetails.prototype.ngOnDestroy = function () {
        if (this.refresh) {
            clearInterval(this.refresh);
        }
        if (this.mock_data_subscription !== null && this.mock_data_subscription !== undefined) {
            this.mock_data_subscription.unsubscribe();
        }
    };
    /**
     * Initialize demo mode mock data
     */
    LiveDetails.prototype.initializeMockData = function () {
        var _this = this;
        this.getMockConsumption();
        this.getMockStatus();
        this.refresh = setInterval(function () {
            _this.getMockConsumption();
            _this.getMockStatus();
        }, 10000);
    };
    /**
     * Initialize live api connections
     */
    LiveDetails.prototype.initializeApiConnection = function () {
        var _this = this;
        if (this._userService.getActiveUserProvider().toLowerCase() === 'enviam') {
            this.determineUserHasHappyHour();
        }
        this.getLiveConsumption();
        this.getCurrentConsumption();
        this.getStatus();
        this.refresh = setInterval(function () {
            _this.getLiveConsumption();
            _this.getCurrentConsumption();
            _this.getStatus();
        }, 10000);
    };
    /**
     *
     */
    LiveDetails.prototype.getMockConsumption = function () {
        var _this = this;
        var zoom = this.zoomlevels.filter(function (item) { return item.level === _this.zoomlevel; });
        var offset = zoom[0].offset * this.position;
        var limit = offset - zoom[0].offset;
        var interval = zoom[0].interval;
        var level = zoom[0].level;
        this.mock_data_subscription = this._mockService.getLiveData(offset, level, limit, interval).subscribe(function (values) {
            if (values.length > 0) {
                _this.chart.removeSerie(0);
                _this.chart.addSerie({
                    name: null,
                    data: values.map(function (element) {
                        return [
                            new Date(element.timestamp).getTime(),
                            element.power
                        ];
                    })
                });
                var current_consumption = values[values.length - 1].power;
                var diff = current_consumption - Math.floor(current_consumption);
                if (diff < 0.5) {
                    _this.current_consumption = Math.floor(current_consumption).toLocaleString('de-DE');
                }
                else {
                    _this.current_consumption = Math.ceil(current_consumption).toLocaleString('de-DE');
                }
                var chart = $('#chart-live-details').highcharts().hideLoading();
                _this.disabled = false;
            }
            else {
                var chart = $('#chart-live-details').highcharts();
                _this.current_consumption = '0';
                // this.chart.removeSerie(0);
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                _this.disabled = false;
            }
        }, function () {
            var chart = $('#chart-live-details').highcharts();
            chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            _this.disabled = false;
        });
    };
    /**
     * Daten von API für Livediagramm
     */
    LiveDetails.prototype.getLiveConsumption = function () {
        var _this = this;
        var zoom = this.zoomlevels.filter(function (item) { return item.level === _this.zoomlevel; });
        var offset = zoom[0].offset * (this.position);
        var limit = offset - zoom[0].offset;
        var interval = zoom[0].interval;
        this._apiService.getLive(offset, limit, interval).subscribe(function (response) {
            _this.handleLiveDataResponse(response);
        }, function (error) {
            console.log('error', error);
            // let chart: any = $('#chart-live-details').highcharts();
            if (_this.chart !== null && _this.chart !== undefined) {
                _this.chart.ref.highcharts();
                _this.chart.ref.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            }
            _this.disabled = false;
        });
        if (this.userHasHappyHour) {
            this.getHappyHourSchedule(offset);
        }
    };
    /**
     * Get current energy consumption-alert
     */
    LiveDetails.prototype.getCurrentConsumption = function () {
        var _this = this;
        this._apiService.getCurrentConsumption().subscribe(function (response) {
            if ('data' in response) {
                if ('electricity' in response.data) {
                    if ('power' in response.data.electricity) {
                        _this.current_consumption = response.data.electricity.power.toLocaleString('de-DE');
                    }
                }
            }
        });
    };
    /**
     * Aktueller Verbrauchsstatus/Zone (niedrig, mittel, hoch, seit wann)
     */
    LiveDetails.prototype.getStatus = function () {
        var _this = this;
        this._apiService.getHomeStateStatus().subscribe(function (data) {
            if (data.data.current_zone == 4) {
                _this.status.noZone = true;
                return;
            }
            _this.status.noZone = false;
            var date = new Date(data.data.since);
            var currentDate = new Date();
            // calculate difference
            var diffMs = (currentDate.getTime() - date.getTime()); // milliseconds between now & Christmas
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            var since = '';
            if (diffHrs > 0) {
                if (diffHrs == 1) {
                    since = diffHrs + ' Stunde ';
                }
                else {
                    since = diffHrs + ' Stunden ';
                }
            }
            if (diffMins == 1) {
                since += diffMins + ' Minute';
            }
            else {
                since += diffMins + ' Minuten';
            }
            _this.status = {
                trend: data.data.current_zone,
                since: since
            };
        }, function (response) {
            _this.status.noZone = true;
        });
    };
    /**
     *
     */
    LiveDetails.prototype.getMockStatus = function () {
        var _this = this;
        this._mockService.getHomeStateStatus().subscribe(function (data) {
            _this.status.noZone = false;
            var date = new Date(data.data.since);
            var currentDate = new Date();
            // calculate difference
            var diffMs = (currentDate.getTime() - date.getTime()); // milliseconds between now & Christmas
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            var since = '';
            if (diffHrs > 0) {
                if (diffHrs == 1) {
                    since = diffHrs + ' Stunde ';
                }
                else {
                    since = diffHrs + ' Stunden ';
                }
            }
            if (diffMins == 1) {
                since += diffMins + ' Minute';
            }
            else {
                since += diffMins + ' Minuten';
            }
            _this.status = {
                trend: data.data.current_zone,
                since: since
            };
        });
    };
    /**
     * Request whether the user participates in the happy hour program
     */
    LiveDetails.prototype.determineUserHasHappyHour = function () {
        var _this = this;
        this._apiService.userParticipatesInHappyHour().subscribe(function (response) {
            if (response === null || response === undefined) {
                console.log('undefined from response');
                return;
            }
            if ('data' in response) {
                var now_1 = moment();
                var parsed_response_data = response.data;
                // filter by elements with date earlier than today
                var filtered = parsed_response_data.filter(function (element) { return now_1 >= moment(element.from_date, 'DD/MM/YYYY'); });
                var sorted = filtered.sort(function (a, b) {
                    var a_from = moment(a.from_date, 'DD/MM/YYYY');
                    var b_from = moment(b.from_date, 'DD/MM/YYYY');
                    if (a_from.unix() > b_from.unix()) {
                        return -1;
                    }
                    else if (a_from.unix() < b_from.unix()) {
                        return 1;
                    }
                    return 0;
                });
                if ('value' in sorted[0]) {
                    _this.onUserHasHappyHour.next(sorted[0].value === 1);
                }
            }
        }, function (error) {
        });
    };
    /**
     * Request the users happyhour schedule
     */
    LiveDetails.prototype.getHappyHourSchedule = function (offset) {
        var _this = this;
        var date = moment().subtract(offset, 'minutes');
        // console.log('current offset date:', date.format('DD-MM-YYYY'));
        this._apiService.getHappyHourSchedule(date.year(), date.month() + 1).subscribe(function (response) {
            var today_schedule = null;
            var yesterday_schedule = null;
            var weekday = date.isoWeekday();
            // console.log('weekday', weekday);
            for (var i = 0; i < response.data.length; ++i) {
                var element = response.data[i];
                if (element.dayofweek === weekday) {
                    today_schedule = element;
                }
                if (element.dayofweek === 7) {
                    yesterday_schedule = response.data[0];
                }
                else if (element.dayofweek === 1) {
                    yesterday_schedule = response.data[6];
                }
                else {
                    yesterday_schedule = response.data[i - 1];
                }
            }
            // console.log(today_schedule, yesterday_schedule);
            // update these values only if they were not set before
            if (_this.currentHappyHourStart === null && _this.currentHappyHourEnd === null) {
                _this.currentHappyHourStart = date.hour(today_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
                _this.currentHappyHourEnd = date.hour(today_schedule.endtime).minute(0).second(0).millisecond(0).toDate();
            }
            _this.hhIndicator.setStartTime(_this.currentHappyHourStart, _this.currentHappyHourEnd);
            _this.todayHappyHourStart = date.hour(today_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
            _this.todayHappyHourEnd = date.hour(today_schedule.endtime).minute(0).second(0).millisecond(0).toDate();
            var yesterday = moment(date).add(1, 'day');
            _this.yesterdayHappyHourStart = yesterday.hour(yesterday_schedule.starttime).minute(0).second(0).millisecond(0).toDate();
            _this.yesterdayHappyHourEnd = yesterday.hour(yesterday_schedule.endtime).minute(0).second(0).millisecond(0).toDate();
            // console.log('today start', this.todayHappyHourStart);
            // console.log('yesterday start', this.yesterdayHappyHourStart);
            // console.log('=================================');
            if (_this.chart.ref !== null && _this.chart.ref !== undefined) {
                _this.chart.ref.update({ plotOptions: { area: { zones: _this.calculateNewZones() } } });
            }
        });
    };
    /**
     * Zoom in
     */
    LiveDetails.prototype.zoomIn = function () {
        this.setZoom(this.zoomlevel - 1);
    };
    /**
     * Zoom out
     */
    LiveDetails.prototype.zoomOut = function () {
        this.setZoom(this.zoomlevel + 1);
    };
    /**
     * Set specific zoom level
     */
    LiveDetails.prototype.setZoom = function (level) {
        if ((level !== this.zoomlevel) && (!this.disabled)) {
            var zoom = this.zoomlevels.filter(function (item) { return item.level === level; });
            if (zoom.length > 0) {
                this.zoomlevel = level;
                this.position = 1;
                this.chart.ref.update({
                    xAxis: {
                        tickInterval: zoom[0].resolution * 1000
                    },
                    tooltip: {
                        xDateFormat: zoom[0].format
                    },
                    series: [
                        {
                            name: null,
                            data: []
                        }
                    ]
                });
                this.resetChart();
            }
        }
    };
    LiveDetails.prototype.positionForward = function () {
        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }
    };
    LiveDetails.prototype.positionBack = function () {
        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
    };
    LiveDetails.prototype.positionNow = function () {
        if (!this.disabled) {
            this.position = 1;
            this.resetChart();
        }
    };
    LiveDetails.prototype.resetChart = function () {
        var chart = $('#chart-live-details').highcharts();
        chart.showLoading();
        this.disabled = true;
        if (this._apiService.isDemoMode()) {
            this.current_demo_idx = 0;
            this.getMockConsumption();
            return;
        }
        this.getLiveConsumption();
    };
    LiveDetails.prototype.initializeChart = function () {
        this.chart = new Chart({
            chart: {
                type: 'area',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 40, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 60 * 1000,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%H:%M<br/>%d.%m',
                    week: '%d.%m',
                    month: '%m.%Y',
                    year: '%Y'
                },
                labels: {
                    style: {
                        fontSize: '13px'
                    }
                },
                crosshair: {
                    width: 1,
                    color: 'rgba(0,0,0,0.6)',
                    zIndex: 3
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 60,
                    y: 15,
                    step: 2,
                    formatter: function () {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' W';
                        }
                        else {
                            return null;
                        }
                    },
                    style: {
                        fontSize: '13px'
                    }
                },
                gridZIndex: 10,
                gridLineColor: 'rgba(0,0,0,0.20)'
            },
            tooltip: {
                xDateFormat: '%H:%M:%S Uhr',
                hideDelay: 0,
                animation: false,
                shadow: false,
                positioner: function (boxWidth, boxHeight, point) {
                    if ((point.plotX + this.chart.plotLeft + boxWidth) > this.chart.plotWidth) {
                        return {
                            x: (point.plotX + this.chart.plotLeft) - boxWidth - 10,
                            y: 120
                        };
                    }
                    else {
                        return {
                            x: point.plotX + this.chart.plotLeft + 10,
                            y: 120
                        };
                    }
                },
                useHTML: true,
                headerFormat: '<div class="large">{point.key}</div>',
                pointFormat: '<div class="small">{point.y:,.0f} Watt</div>',
                style: {
                    color: '#143C8C',
                    zIndex: 15,
                },
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                borderWidth: 0,
                borderRadius: 0
            },
            plotOptions: {
                series: {
                    showInLegend: false,
                    animation: false,
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                area: {
                    fillOpacity: 1,
                    lineWidth: 0,
                    marker: {
                        enabled: false
                    },
                    zoneAxis: 'x',
                    zones: [
                        { value: 0 },
                        { color: 'rgb(0, 170, 225)' },
                    ]
                }
            },
            series: [
                {
                    name: null,
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });
    };
    LiveDetails.prototype.calculateNewZones = function () {
        return [
            { color: 'rgba(0, 170, 225,1)' },
            { value: this.todayHappyHourStart.getTime() },
            { color: 'rgba(20, 60, 140,1)' },
            { value: this.todayHappyHourEnd.getTime() },
            { color: 'rgba(0, 170, 225, 1)' },
            { value: this.yesterdayHappyHourStart.getTime() },
            { color: 'rgba(20, 60, 140,1)' },
            { value: this.yesterdayHappyHourEnd.getTime() },
            { color: 'rgba(0, 170, 225,1)' },
            { value: this.currentHappyHourStart.getTime() },
            { color: 'rgba(20, 60, 140,1)' },
            { value: this.currentHappyHourEnd.getTime() },
            { color: 'rgba(0, 170, 225, 1)' },
        ];
    };
    /**
     * Handle live data response from API.
     * @param response
     */
    LiveDetails.prototype.handleLiveDataResponse = function (response) {
        var e_1, _a;
        if (response.status === 'ok' && 'data' in response) {
            var data = response.data.results;
            this.currentDataset = data;
            // calculate max power value
            var max = this.currentDataset.reduce(function (prev, current) {
                return (prev.power > current.power) ? prev : current;
            });
            var background = [];
            try {
                for (var _b = tslib_1.__values(this.currentDataset), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var element = _c.value;
                    var d = new Date(element.timestamp);
                    if (d >= this.todayHappyHourStart && d <= this.todayHappyHourEnd) {
                        background.push({ timestamp: element.timestamp, power: max.power });
                    }
                    else {
                        background.push({ timestamp: element.timestamp, power: 0 });
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.chart.removeSerie(0);
            this.chart.removeSerie(1);
            // add actual graph
            this.chart.addSerie({
                name: null,
                zIndex: 5,
                data: data.map(function (element) {
                    return [
                        new Date(element.timestamp).getTime(),
                        element.power
                    ];
                })
            });
            var chart = $('#chart-live-details').highcharts().hideLoading();
            this.disabled = false;
        }
    };
    return LiveDetails;
}());
export { LiveDetails };
