import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Chart } from 'angular-highcharts';
import { Globals } from '../../services/globals.service';
import { MockDataService } from '../../mock-data.service';
import { UserService } from '../../user.service';
var CompareWidget = /** @class */ (function () {
    function CompareWidget(_apiService, _globals, _mockData, _userService) {
        this._apiService = _apiService;
        this._globals = _globals;
        this._mockData = _mockData;
        this._userService = _userService;
        this.chart = {};
        this.trend = {
            monthname: null,
            direction: 0,
            percent: 0
        };
        this.showDiagrams = true;
        this.consumption_current = 0;
        this.consumption_comparision = 0;
    }
    CompareWidget.prototype.ngOnInit = function () {
        this.initializeChart();
        if (this._apiService.isDemoMode()) {
            this.getMockComparison();
            return;
        }
        this.getCompare();
    };
    /**
     * Chart Daten von API holen
     */
    CompareWidget.prototype.getCompare = function () {
        var _this = this;
        var currentDate = new Date();
        var lastMonth = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth() - 1;
        var lastYear = currentDate.getMonth() == 0 ? (currentDate.getFullYear() - 1) : currentDate.getFullYear();
        var daysThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        var daysLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
        var consumptionCurrent = 0;
        var consumptionCompare = 0;
        if (currentDate.getDate() == 1) { // Wenn heute der erste Tag im Monat ist
            // Anfrage für vergangenen Monat
            var date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            var hour_1 = currentDate.getHours();
            if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                hour_1 = hour_1 + 1;
            }
            // Wir müssen hier nur 2 Anfragen machen jewails Stundenweise für den gleichen Tag im vergangenen Monat und heute
            this._apiService.getConsumptionCustom('hours', date1, date1).subscribe(function (data) {
                var e_1, _a;
                if ('data' in data) {
                    try {
                        for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var con = _c.value;
                            var date = new Date(con.timestamp);
                            if (date.getHours() <= hour_1) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                if (('measured' in con)) {
                                    consumptionCompare += con.measured;
                                }
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                // Anfrage für jetzigen Monat
                var date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                _this._apiService.getConsumptionCustom('hours', date3, date3).subscribe(function (data) {
                    var e_2, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                var date = new Date(con.timestamp);
                                if (date.getHours() <= currentDate.getHours()) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                    if (('measured' in con)) {
                                        consumptionCurrent += con.measured;
                                    }
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                    _this.calculateConsumption(consumptionCurrent, consumptionCompare);
                });
            });
        }
        else if (daysThisMonth > daysLastMonth && currentDate.getDate() > daysLastMonth) { //Aktueller Monat hat mehr Tage als vorheriger UND currentDate.day ist größer als Anzahl Tage im Vormonat
            // Anfrage für für vergangenen Monat
            var date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            var date2 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);
            // Wir müssen hier einmal Tageweise den kompletten letzten Monat anfragen, dann Tageweise bis gestern, und vom heutigen Tag noch Stundenweise
            this._apiService.getConsumptionCustom('days', date1, date2).subscribe(function (data) {
                var e_3, _a;
                if ('data' in data) {
                    try {
                        for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var con = _c.value;
                            if (('measured' in con)) {
                                consumptionCompare += con.measured;
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
                // Anfragen für heutigen Monat
                var date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                var date4 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);
                _this._apiService.getConsumptionCustom('days', date3, date4).subscribe(function (data) {
                    var e_4, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                if (('measured' in con)) {
                                    consumptionCurrent += con.measured;
                                }
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                    }
                    // Anfragen für heutigen Tag stündlich
                    var date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                    _this._apiService.getConsumptionCustom('hours', date5, date5).subscribe(function (data) {
                        var e_5, _a;
                        if ('data' in data) {
                            try {
                                for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var con = _c.value;
                                    var date = new Date(con.timestamp);
                                    if (date.getHours() <= currentDate.getHours()) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if (('measured' in con)) {
                                            consumptionCurrent += con.measured;
                                        }
                                    }
                                }
                            }
                            catch (e_5_1) { e_5 = { error: e_5_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_5) throw e_5.error; }
                            }
                        }
                        _this.calculateConsumption(consumptionCurrent, consumptionCompare);
                    });
                });
            });
        }
        else {
            // Anfragen für vergangen Monat
            var date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            var date2 = new Date(lastYear, lastMonth, currentDate.getDate() - 1, 23, 59, 59);
            // Hier müssen wir den letzten Monat Tageweise anfragen bis gestern vor einem Monat, dann heute vor einem Monat Stundenweise und das gleiche nochmal für diesen Monat
            this._apiService.getConsumptionCustom('days', date1, date2).subscribe(// Anfrage letzter Monat Tageweise
            function (data) {
                var e_6, _a;
                if ('data' in data) {
                    try {
                        for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var con = _c.value;
                            if (('measured' in con)) {
                                consumptionCompare += con.measured;
                            }
                        }
                    }
                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_6) throw e_6.error; }
                    }
                }
                var date3 = new Date(lastYear, lastMonth, currentDate.getDate(), 0, 0, 0);
                var hour = currentDate.getHours();
                if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                    hour = hour + 1;
                }
                _this._apiService.getConsumptionCustom('hours', date3, date3).subscribe(// Anfrage gleicher Tag, letzter Monat, stundenweise
                function (data) {
                    var e_7, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                var date = new Date(con.timestamp);
                                if (date.getHours() <= hour) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                    if (('measured' in con)) {
                                        consumptionCompare += con.measured;
                                    }
                                }
                            }
                        }
                        catch (e_7_1) { e_7 = { error: e_7_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_7) throw e_7.error; }
                        }
                    }
                    // Anfragen für heutigen Monat
                    var date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                    var date6 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);
                    _this._apiService.getConsumptionCustom('days', date5, date6).subscribe(function (data) {
                        var e_8, _a;
                        if ('data' in data) {
                            try {
                                for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var con = _c.value;
                                    if (('measured' in con)) {
                                        consumptionCurrent += con.measured;
                                    }
                                }
                            }
                            catch (e_8_1) { e_8 = { error: e_8_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_8) throw e_8.error; }
                            }
                        }
                        // Anfragen für heutigen Tag stündlich
                        var date7 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                        _this._apiService.getConsumptionCustom('hours', date7, date7).subscribe(function (data) {
                            var e_9, _a;
                            if ('data' in data) {
                                try {
                                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        var con = _c.value;
                                        var date = new Date(con.timestamp);
                                        if (date.getHours() <= currentDate.getHours()) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                            if (('measured' in con)) {
                                                consumptionCurrent += con.measured;
                                            }
                                        }
                                    }
                                }
                                catch (e_9_1) { e_9 = { error: e_9_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_9) throw e_9.error; }
                                }
                            }
                            _this.calculateConsumption(consumptionCurrent, consumptionCompare);
                        });
                    });
                });
            });
        }
    };
    /**
     * Chart Daten von API holen
     */
    CompareWidget.prototype.getMockCompare = function () {
        var _this = this;
        var currentDate = new Date();
        var lastMonth = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth() - 1;
        var lastYear = currentDate.getMonth() == 0 ? (currentDate.getFullYear() - 1) : currentDate.getFullYear();
        var daysThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        var daysLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
        this.consumption_current = 0;
        this.consumption_comparision = 0;
        if (currentDate.getDate() == 1) { // Wenn heute der erste Tag im Monat ist
            // Anfrage für vergangenen Monat
            var date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            var hour_2 = currentDate.getHours();
            if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                hour_2 = hour_2 + 1;
            }
            // Wir müssen hier nur 2 Anfragen machen jewails Stundenweise für den gleichen Tag im vergangenen Monat und heute
            this._apiService.getConsumptionCustom('hours', date1, date1).subscribe(function (data) {
                var e_10, _a;
                if ('data' in data) {
                    try {
                        for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var con = _c.value;
                            var date = new Date(con.timestamp);
                            if (date.getHours() <= hour_2) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                if (('measured' in con)) {
                                    _this.consumption_comparision += con.measured;
                                }
                            }
                        }
                    }
                    catch (e_10_1) { e_10 = { error: e_10_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_10) throw e_10.error; }
                    }
                }
                // Anfrage für jetzigen Monat
                var date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                _this._apiService.getConsumptionCustom('hours', date3, date3).subscribe(function (data) {
                    var e_11, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                var date = new Date(con.timestamp);
                                if (date.getHours() <= currentDate.getHours()) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                    if (('measured' in con)) {
                                        _this.consumption_current += con.measured;
                                    }
                                }
                            }
                        }
                        catch (e_11_1) { e_11 = { error: e_11_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_11) throw e_11.error; }
                        }
                    }
                    _this.calculateConsumption(_this.consumption_current, _this.consumption_comparision);
                });
            });
        }
        else if (daysThisMonth > daysLastMonth && currentDate.getDate() > daysLastMonth) { //Aktueller Monat hat mehr Tage als vorheriger UND currentDate.day ist größer als Anzahl Tage im Vormonat
            // Anfrage für für vergangenen Monat
            var date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            var date2 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);
            // Wir müssen hier einmal Tageweise den kompletten letzten Monat anfragen, dann Tageweise bis gestern, und vom heutigen Tag noch Stundenweise
            this._apiService.getConsumptionCustom('days', date1, date2).subscribe(function (data) {
                var e_12, _a;
                if ('data' in data) {
                    try {
                        for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var con = _c.value;
                            if (('measured' in con)) {
                                _this.consumption_comparision += con.measured;
                            }
                        }
                    }
                    catch (e_12_1) { e_12 = { error: e_12_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_12) throw e_12.error; }
                    }
                }
                // Anfragen für heutigen Monat
                var date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                var date4 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);
                _this._apiService.getConsumptionCustom('days', date3, date4).subscribe(function (data) {
                    var e_13, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                if (('measured' in con)) {
                                    _this.consumption_current += con.measured;
                                }
                            }
                        }
                        catch (e_13_1) { e_13 = { error: e_13_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_13) throw e_13.error; }
                        }
                    }
                    // Anfragen für heutigen Tag stündlich
                    var date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                    _this._apiService.getConsumptionCustom('hours', date5, date5).subscribe(function (data) {
                        var e_14, _a;
                        if ('data' in data) {
                            try {
                                for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var con = _c.value;
                                    var date = new Date(con.timestamp);
                                    if (date.getHours() <= currentDate.getHours()) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if (('measured' in con)) {
                                            _this.consumption_current += con.measured;
                                        }
                                    }
                                }
                            }
                            catch (e_14_1) { e_14 = { error: e_14_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_14) throw e_14.error; }
                            }
                        }
                        _this.calculateConsumption(_this.consumption_current, _this.consumption_comparision);
                    });
                });
            });
        }
        else {
            // Anfragen für vergangen Monat
            var date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            var date2 = new Date(lastYear, lastMonth, currentDate.getDate() - 1, 23, 59, 59);
            // Hier müssen wir den letzten Monat Tageweise anfragen bis gestern vor einem Monat, dann heute vor einem Monat Stundenweise und das gleiche nochmal für diesen Monat
            this._mockData.getConsumptionByDate('days', date1, date2).subscribe(// Anfrage letzter Monat Tageweise
            function (data) {
                var e_15, _a;
                if ('data' in data) {
                    try {
                        for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var con = _c.value;
                            if (('measured' in con)) {
                                _this.consumption_comparision += con.measured;
                            }
                        }
                    }
                    catch (e_15_1) { e_15 = { error: e_15_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_15) throw e_15.error; }
                    }
                }
                var date3 = new Date(lastYear, lastMonth, currentDate.getDate(), 0, 0, 0);
                var hour = currentDate.getHours();
                if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                    hour = hour + 1;
                }
                _this._mockData.getConsumptionByDate('hours', date3, date3).subscribe(// Anfrage gleicher Tag, letzter Monat, stundenweise
                function (data) {
                    var e_16, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                var date = new Date(con.timestamp);
                                if (date.getHours() <= hour) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                    if (('measured' in con)) {
                                        _this.consumption_comparision += con.measured;
                                    }
                                }
                            }
                        }
                        catch (e_16_1) { e_16 = { error: e_16_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_16) throw e_16.error; }
                        }
                    }
                    // Anfragen für heutigen Monat
                    var date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                    var date6 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);
                    _this._mockData.getConsumptionByDate('days', date5, date6).subscribe(function (data) {
                        var e_17, _a;
                        if ('data' in data) {
                            try {
                                for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var con = _c.value;
                                    if (('measured' in con)) {
                                        _this.consumption_current += con.measured;
                                    }
                                }
                            }
                            catch (e_17_1) { e_17 = { error: e_17_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_17) throw e_17.error; }
                            }
                        }
                        // Anfragen für heutigen Tag stündlich
                        var date7 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                        _this._mockData.getConsumptionByDate('hours', date7, date7).subscribe(function (data) {
                            var e_18, _a;
                            if ('data' in data) {
                                try {
                                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        var con = _c.value;
                                        var date = new Date(con.timestamp);
                                        if (date.getHours() <= currentDate.getHours()) { // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                            if (('measured' in con)) {
                                                _this.consumption_current += con.measured;
                                            }
                                        }
                                    }
                                }
                                catch (e_18_1) { e_18 = { error: e_18_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_18) throw e_18.error; }
                                }
                            }
                            _this.calculateConsumption(_this.consumption_current, _this.consumption_comparision);
                        });
                    });
                });
            });
        }
    };
    CompareWidget.prototype.getMockComparison = function () {
        var _this = this;
        this._mockData.getMonthlyComparison().subscribe(function (data) {
            _this.calculateConsumption(data.data[0].measured, data.data[1].measured);
        });
    };
    /**
     * Verbrauch berechnen
     *
     * @param consumptionCurrent
     * @param consumptionCompare
     */
    CompareWidget.prototype.calculateConsumption = function (consumptionCurrent, consumptionCompare) {
        this.showDiagrams = consumptionCurrent > 0 || consumptionCompare > 0;
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        this.chart.addPoint({
            name: '01.' + month + '.-' + day + '.' + month + '.' + date.getFullYear(),
            y: Math.round(consumptionCompare / 1000),
            color: '#D27300'
        });
        date = new Date();
        day = ('0' + date.getDate()).slice(-2);
        month = ('0' + (date.getMonth() + 1)).slice(-2);
        this.chart.addPoint({
            name: '01.' + month + '.-' + day + '.' + month + '.' + date.getFullYear(),
            y: Math.round(consumptionCurrent / 1000),
            color: '#F59B00'
        });
        if (consumptionCurrent > consumptionCompare) {
            this.trend = {
                monthname: this._apiService.getMonthname(1),
                direction: 1,
                percent: Math.round(((consumptionCurrent - consumptionCompare) / consumptionCompare) * 100)
            };
        }
        else if (consumptionCurrent < consumptionCompare) {
            this.trend = {
                monthname: this._apiService.getMonthname(1),
                direction: -1,
                percent: Math.round((1 - (consumptionCurrent / consumptionCompare)) * 100)
            };
        }
    };
    CompareWidget.prototype.firstDetailViewAnalytics = function () {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Compare-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    };
    /**
     * Init chart
     */
    CompareWidget.prototype.initializeChart = function () {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 50, 150, 50],
                events: {
                    redraw: function () {
                        var bottom = this.plotHeight + 25;
                        $.each(this.series[0].data, function (i, data) {
                            data.dataLabel.attr({
                                y: bottom
                            });
                        });
                        this.reflow();
                    },
                    click: function () {
                        $('.sidebar').each(function () {
                            $(this).removeClass('visible');
                        });
                        $('html').addClass('sidebar-open');
                        $('.sb-overlay').addClass('visible');
                        $('.sidebar.myDevices.compare-details').toggleClass('visible');
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                lineColor: 'transparent',
                tickColor: 'transparent'
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointWidth: 175,
                    borderRadius: 10,
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        formatter: function () {
                            return '<div class="label date" style="color: ' + this.point.color + ';">' + this.point.name + '</div>'
                                + '<h3 class="label" style="color: ' + this.point.color + ';">' + this.y.toLocaleString('de-DE') + ' kWh</h3>';
                        },
                        verticalAlign: 'bottom'
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false
                }
            },
            series: [
                {
                    name: null,
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });
    };
    return CompareWidget;
}());
export { CompareWidget };
