import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { throwError as observableThrowError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2 } from 'angulartics2';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from '../../environments/environment';
import { constants } from '../constants';
import { UserService } from '../user.service';
import { OverlayService } from '../overlay/overlay.service';
import { Url } from '../Url';
import * as moment from 'moment';
var ApiService = /** @class */ (function () {
    /**
     * MINE
     */
    // private app_state = constants.application.states.none;
    function ApiService(_httpClient, _router, _notification, _analytics, _userService, _overlayService) {
        this._httpClient = _httpClient;
        this._router = _router;
        this._notification = _notification;
        this._analytics = _analytics;
        this._userService = _userService;
        this._overlayService = _overlayService;
        /**
         * API-Base URL's. Sollte sich die Schnittstellen URL ändern, ist hier die zentrale Stelle dafür
         */
        this.AUTH_BASE_URL = environment.urls.auth;
        this.API_BASE_URL = environment.urls.api;
        this.DAYS = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
        this.MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        this.meterStatus = 0;
        this.refreshtoken = false;
        this.checkMeterStatusInterval = [];
        this.checkMeterNotificationId = 0;
        this.countLoginError = 0;
        this.loggedOut = new Subject();
        this.onLoggedIn = new Subject();
        // Prüfen ob Tracking deaktiviert
        if (localStorage.getItem('disable_tracking') !== null) {
            this._analytics.developerMode(true);
        }
    }
    ApiService.prototype.ngOnDestroy = function () {
        clearInterval(this.checkMeterStatusInterval);
    };
    /**
     * Get actual Consumption
     */
    ApiService.prototype.getCurrentConsumption = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.instantaneous, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get actual Status
     */
    ApiService.prototype.getHomeStateStatus = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.homeState.current, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Status-Configuration
     */
    ApiService.prototype.getHomeStateConfig = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.homeState.config, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get live Consumption
     *
     * @param offset Now - X Minutes
     * @param limit Now - X Minutes
     * @param interval Seconds (1), Minutes (60)
     */
    ApiService.prototype.getLive = function (offset, limit, interval) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var time_from = new Date();
        time_from.setMinutes(time_from.getMinutes() - offset);
        var time_to = new Date();
        time_to.setMinutes(time_to.getMinutes() - limit);
        var method = time_from.toISOString() + '/' + time_to.toISOString() + '/' + interval;
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.power + '/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Consumer
     *
     * @param offset Now - X Months
     */
    ApiService.prototype.getElectricalAppliances = function (offset) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var date = new Date();
        date.setMonth(date.getMonth() - offset, 1);
        var method = date.getFullYear() + '/' + (date.getMonth() + 1);
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Consumer for specific Month
     *
     * @param month Month
     * @param offset Now - X Years
     */
    ApiService.prototype.getMonthConsumer = function (month, offset) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var date = new Date();
        date.setFullYear(date.getFullYear() - offset);
        var method = date.getFullYear() + '/' + month;
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Consumption
     *
     * @param offset Now - X Days
     */
    ApiService.prototype.getConsumption = function (offset) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var date = new Date();
        date.setDate(date.getDate() - offset);
        var method = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '/' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/days/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Consumption for specific Day
     *
     * @param offset Now - X Days
     */
    ApiService.prototype.getDayConsumption = function (offset) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var date = new Date();
        date.setDate(date.getDate() - offset);
        var method = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '/' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/hours/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Custom Consumption
     *
     * @param type month/day/....
     * @param date1 From
     * @param date2 To
     */
    ApiService.prototype.getConsumptionCustom = function (type, date1, date2) {
        var _this = this;
        // console.log(`type: ${type}, date1: ${date1}, date2: ${date2}`);
        var headers = this.getDefaultHeaders(this.getToken());
        var method = date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + date1.getDate() + '/' + date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate();
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/' + type + '/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Compare actual Month with previous Month
     */
    ApiService.prototype.getCompareMonth = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.compare + '/month', { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Compare-Data for specific Area
     *
     * @param from DateTime
     * @param to DateTime
     * @param area Months or Days
     */
    ApiService.prototype.getCompare = function (from, to, area) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var date_from = new Date(from);
        var date_to = new Date(to);
        var method = null;
        switch (area) {
            case 'months': {
                method = date_from.getFullYear() + '-' + (date_from.getMonth() + 1) + '/' + date_to.getFullYear() + '-' + (date_to.getMonth() + 1);
                break;
            }
            default: {
                method = date_from.getFullYear() + '-' + (date_from.getMonth() + 1) + '-' + date_from.getDate() + '/' + date_to.getFullYear() + '-' + (date_to.getMonth() + 1) + '-' + date_to.getDate();
                break;
            }
        }
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/' + area + '/' + method, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Meter for specific Date
     *
     * @param date Date in Format YYYY-MM-DD
     */
    ApiService.prototype.getConsumptionForDate = function (date) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.consumption.this + '/' + date, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get financial Budget
     */
    ApiService.prototype.getFinance = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.electricity.bill.prediction, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Meter-Informations
     */
    ApiService.prototype.getInitialization = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.initialization, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error_response) {
            if ('error' in error_response) {
                if ('error' in error_response.error) {
                    if ('code' in error_response.error.error) {
                        if (error_response.error.error.code === 125) {
                            _this._router.navigate(['/registrieren'], { queryParams: { jumpToOnboarding: true } });
                        }
                    }
                }
            }
            if ('_body' in error_response) {
                var jsonError = void 0;
                try {
                    jsonError = JSON.parse(error_response._body);
                }
                catch (e) {
                    return observableThrowError(error_response);
                }
                if ('error' in jsonError) {
                    if ('code' in jsonError.error && jsonError.error.code === 125) {
                        // user not yet onboarded -> Zum Onboarding springen
                        console.log('not yet onboarded rerouting');
                        _this._router.navigate(['/registrieren'], { queryParams: { jumpToOnboarding: true } });
                    }
                }
            }
            return observableThrowError(error_response);
        }));
    };
    /**
     * Get Contract-Informations
     */
    ApiService.prototype.getContract = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.profile.this, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Profile
     */
    ApiService.prototype.getProfile = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.profile.attributes, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Set Profile
     *
     * @param config Profile-Configuration
     */
    ApiService.prototype.setProfile = function (config) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.profile.attributes, config, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Set E-Mail
     *
     * @param email Email-Address
     */
    ApiService.prototype.setEmail = function (email) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.email, { email: email }, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Set E-Mail
     *
     * @param oldPass Altes Passwort
     * @param newPass Neues Passwort
     * @param newPassRepeat Wiederholung neues Passwort
     */
    ApiService.prototype.updatePassword = function (oldPass, newPass, newPassRepeat) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.setPassword, {
            oldpass: oldPass,
            newpass1: newPass,
            newpass2: newPassRepeat
        }, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            var msg = error.error.error.message;
            if ('error' in error) {
                if ('error' in error.error) {
                    if (error.error.error.code === 264) {
                        var msg_1 = error.error.error.message;
                        if (msg_1.includes('MAX length')) {
                            _this._notification.error('Das gewählte Passwort ist zu lang. Bitte verwenden Sie maximal 16 Zeichen.', 'Passwort ungültig', { timeOut: 6000 });
                        }
                        else if (msg_1.includes('Special characters')) {
                            _this._notification.error('Das gewählte Passwort enthält unzulässige Sonderzeichen. Bitte verwenden Sie nur Buchstaben und Zahlen, sowie die folgenden Sonderzeichen: ! \" ( ) = [ ] { } ? \\ + * ~ # , ; . - _ Umlaute und ß sind nicht möglich.', 'Passwort ungültig', { timeOut: 6000 });
                        }
                    }
                }
            }
            console.log(error);
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Information on whether the users device is online or not.
     */
    ApiService.prototype.getRegistrationOnline = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.online, { headers: headers })
            .pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get Information on what hardware device is connected to the users account.
     */
    ApiService.prototype.getRegistrationModel = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.model, { headers: headers })
            .pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    ApiService.prototype.getPlugStatus = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.plug.relay, { headers: headers })
            .pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    ApiService.prototype.setPlugStatus = function (value) {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var payload = {
            on_off: value
        };
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.plug.relay, payload, { headers: headers })
            .pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    ApiService.prototype.getStoredUserSettings = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.configuration.dashboard, { headers: headers })
            .pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    ApiService.prototype.setPlugAttributes = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        var payload = {
            config: this._userService.getLocalKeyValueStore()
        };
        console.log('sending payload to server: ', payload);
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.configuration.dashboard, payload, { headers: headers })
            .pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Reset Password
     *
     * @param email E-Mail
     */
    ApiService.prototype.resetPassword = function (email) {
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.registration.resetPassword + '/' + email, {}).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }));
    };
    /**
     * Validate Voucher-Code
     *
     * @param voucher Voucher-Code
     * @param email Email-Address
     */
    ApiService.prototype.validateVoucher = function (voucher, email) {
        var headers = this.getDefaultHeaders();
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.registration.voucher + '/' + voucher + '/' + email, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }));
    };
    /**
     * Register new User
     *
     * @param user User-ID
     */
    ApiService.prototype.registerUser = function (user) {
        var headers = this.getDefaultHeaders();
        return this._httpClient.post(this.API_BASE_URL + constants.api.routes.registration.noGateway, user, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }));
    };
    /**
     * Register new Device
     *
     * @param mac MAC-Address
     */
    ApiService.prototype.registerDevice = function (mac) {
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.onboard + '/' + mac, {}, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }));
    };
    /**
     * Gateway Registration
     */
    ApiService.prototype.optInDevice = function () {
        var headers = this.getDefaultHeaders(this.getToken());
        var body = { opt_in: 1 };
        return this._httpClient.put(this.API_BASE_URL + constants.api.routes.registration.optIn, body, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }));
    };
    /**
     * Gateway Registration
     */
    ApiService.prototype.getDeviceStatus = function () {
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.meter.status, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }));
    };
    /**
     * Login User
     *
     * @param username Username
     * @param password Password
     */
    ApiService.prototype.loginUser = function (username, password, first_call) {
        var _this = this;
        if (first_call === void 0) { first_call = false; }
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });
        var params = new HttpParams();
        params = params.append('method', 'login');
        params = params.append('username', username);
        params = params.append('password', password);
        var body = { method: 'login', username: username, password: password };
        this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), { headers: headers }).pipe(
        // this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers: headers}).pipe(
        // this._httpClient.post(this.AUTH_BASE_URL, body, {headers: headers}).pipe(
        map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }))
            .subscribe(function (response) {
            if (response === null || response === undefined) {
                console.log('response null || undefined');
            }
            if ('access_token' in response) {
                var expires = new Date();
                expires.setSeconds(response.expires_in);
                var u = {
                    email: username,
                    provider: null,
                    access_token: response.access_token,
                    refresh_token: response.refresh_token,
                    token_expires: expires,
                    charts: null,
                    nilm_status: null,
                    storage: null,
                    device: null
                };
                // console.log('token expires: ', response.expires_in, expires);
                _this._userService.setCurrentUser(u);
                if (!first_call) {
                    _this.getInitialization().subscribe(function (data) {
                        console.log('init data', data);
                        if ('data' in data) {
                            if ('profile' in data.data) {
                                var providername = data.data.profile.labelpartner;
                                _this._userService.setActiveUserProvider(providername);
                                if (_this._router.routerState.snapshot.url === '/login') {
                                    _this.trackUser(_this._userService.getActiveUserName(), data.data.profile);
                                    _this._router.navigate(['/']);
                                }
                            }
                        }
                    }, function (error) {
                        console.log('Error:', error);
                    });
                }
                else {
                    _this.onLoggedIn.next(true);
                }
            }
            else {
                _this._notification.error('Es ist ein Problem aufgetreten!');
            }
        }, function (response) {
            console.log(response);
            var error = response.error;
            _this.countLoginError++;
            if (_this.countLoginError >= 3) {
                _this._notification.error('Möchten Sie Ihr Passwort zurücksetzen? Sie erhalten zunächst eine Email mit einer Anleitung zum weiteren Vorgehen. <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                    disableTimeOut: true,
                    enableHtml: true
                });
            }
            if (response.status === 125) {
                console.log('User appears to not be onboarded yet');
            }
            switch (error.error) {
                case 'invalid_request': {
                    _this._notification.error('E-Mail-Adresse oder Passwort fehlt!');
                    break;
                }
                case 'invalid_grant': {
                    _this._notification.error('E-Mail-Adresse oder Passwort fehlerhaft!');
                    break;
                }
                case 'Maximum number of failed attempts reached. The account is now blocked.': {
                    _this._notification.error('Ihr Account wurde nach der 10-maligen Eingabe eines falschen Passworts gesperrt. Möchten Sie Ihr Passwort zurücksetzen? <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                        disableTimeOut: true,
                        enableHtml: true
                    });
                    break;
                }
                default: {
                    _this._notification.error('Es ist ein Problem aufgetreten!');
                }
            }
        });
    };
    /**
     * Logout current User
     */
    ApiService.prototype.logoutUser = function () {
        this._userService.logoutActiveUser();
        if (this.isDemoMode()) {
            this._analytics.eventTrack.next({
                action: 'login_demo_mode_end',
                properties: {
                    category: 'Login'
                }
            });
        }
        // notify login component to remove the notification interval
        if (this.isDemoMode()) {
            this.loggedOut.next(true);
        }
        // remove set local storage items
        localStorage.removeItem(constants.application.storage.keys.app_expire);
        localStorage.removeItem(constants.application.storage.keys.app_mode);
        this._userService.deleteAccessToken();
        this._overlayService.closeOverlay();
        console.log('logout at: ', new Date());
        console.log('logged out');
        this._router.navigate(['/login']);
        this.setApplicationState(constants.application.states.none);
        // clear all notifications
        this._notification.clear();
    };
    /**
     * Check and refresh current Token
     */
    ApiService.prototype.checkToken = function () {
        var token_expires = this._userService.getActiveUserTokenExpire();
        // console.log('token expires', token_expires);
        if (token_expires !== null) {
            var expires = new Date(token_expires);
            var time = new Date();
            // console.log(expires);
            // console.log(time);
            if (expires > time) {
                var diff = new Date(expires.getTime() - time.getTime());
                if (!this.isDemoMode()) {
                    if ((diff.getMinutes() <= 1) && (this.refreshtoken === false)) {
                        this.refreshToken();
                    }
                }
            }
            else {
                this.logoutUser();
            }
        }
        else {
            this.logoutUser();
        }
    };
    /**
     * Starten der regelmäßigen Abfrage des aktuellen Meter-Status -> um zu heraus zu finden, ob evtl. die Notification angezeigt werden muss, dass Meter noch nicht connected ist
     */
    ApiService.prototype.checkMeterStatus = function () {
        var _this = this;
        if (!this.isDemoMode()) {
            this._notification.clear();
            this.meterStatusCall();
            this.checkMeterStatusInterval = setInterval(function () {
                _this.meterStatusCall();
            }, 5000);
        }
    };
    /**
     * Call an API, ob Meter connected ist, wenn nicht, Anzeige der Notification "Zähler wird verbunden", wenn doch, Interval clearen
     */
    ApiService.prototype.meterStatusCall = function () {
        var _this = this;
        var notification;
        var that = this;
        this.getDeviceStatus().subscribe(function (response) {
            if ('data' in response) {
                if ('current_status' in response.data) {
                    switch (response.data.current_status.toUpperCase()) {
                        case 'READY_FOR_METER_INCLUSION':
                            _this.meterStatus = 1;
                            localStorage.removeItem('isMeterConnected');
                            if (_this.checkMeterNotificationId == 0) {
                                notification = _this._notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
                                    timeOut: 0,
                                    enableHtml: true,
                                    toastClass: 'toast-meterstatus toast'
                                });
                                setTimeout(function () {
                                    $('.go-to-meterstate').click(function () {
                                        that._router.navigate(['/registrieren'], { queryParams: { jumpToMeterstate: true } });
                                    });
                                }, 500);
                                _this.checkMeterNotificationId = notification.toastId;
                                notification.onHidden.subscribe(function (value) {
                                    _this._notification.clear(_this.checkMeterNotificationId);
                                    _this.checkMeterNotificationId = 0;
                                });
                            }
                            break;
                        case 'CONTACT_WITH_METER':
                            _this.meterStatus = 2;
                            localStorage.removeItem('isMeterConnected');
                            if (_this.checkMeterNotificationId == 0) {
                                notification = _this._notification.info('Zähler wird verbunden <br /><a class="go-to-meterstate">Status ansehen</a>', '', {
                                    timeOut: 0,
                                    enableHtml: true,
                                    toastClass: 'toast-meterstatus toast'
                                });
                                setTimeout(function () {
                                    $('.go-to-meterstate').click(function () {
                                        that._router.navigate(['/registrieren'], { queryParams: { jumpToMeterstate: true } });
                                    });
                                }, 500);
                                _this.checkMeterNotificationId = notification.toastId;
                                notification.onHidden.subscribe(function (value) {
                                    _this._notification.clear(_this.checkMeterNotificationId);
                                    _this.checkMeterNotificationId = 0;
                                });
                            }
                            break;
                        case 'CONNECTED_WITH_METER':
                            _this.meterStatus = 3;
                            localStorage.setItem('isMeterConnected', '1');
                            if (_this.checkMeterNotificationId > 0) {
                                _this._notification.clear(_this.checkMeterNotificationId);
                            }
                            clearInterval(_this.checkMeterStatusInterval);
                            break;
                    }
                }
            }
        }, function (e) {
            console.log(e);
            // Error!
        });
    };
    /**
     * Get Changelog
     */
    ApiService.prototype.getChangelog = function () {
        return this._httpClient.get('assets/changelog.json').pipe(map(function (res) { return res; }));
    };
    /**
     * Get Difference of Date
     *
     * @param date DateTime
     */
    ApiService.prototype.getDifference = function (date) {
        var diff = new Date().getTime() - date.getTime();
        var days = Math.floor(diff / (1000 * 60 * 60 * 24));
        diff = diff % (1000 * 60 * 60 * 24);
        var hours = Math.floor(diff / (1000 * 60 * 60));
        diff = diff % (1000 * 60 * 60);
        var minutes = Math.floor(diff / (1000 * 60));
        diff = diff % (1000 * 60);
        var seconds = Math.floor(diff / 1000);
        if (days > 0) {
            if (days === 1) {
                return days + ' Tag';
            }
            else {
                return days + ' Tagen';
            }
        }
        else if (hours > 0) {
            if (hours === 1) {
                return hours + ' Stunde';
            }
            else {
                return hours + ' Stunden';
            }
        }
        else if (minutes > 0) {
            if (minutes === 1) {
                return minutes + ' Minute';
            }
            else {
                return minutes + ' Minuten';
            }
        }
        else if (seconds > 0) {
            if (seconds === 1) {
                return seconds + ' Sekunde';
            }
            else {
                return seconds + ' Sekunden';
            }
        }
        else {
            return 'jetzt';
        }
    };
    /**
     * Get Weeknumber of Date
     *
     * @param date DateTime
     */
    ApiService.prototype.getWeek = function (date) {
        var now = new Date(date);
        now.setHours(0, 0, 0);
        now.setDate(now.getDate() + 4 - (now.getDay() || 7));
        var start = new Date(now.getFullYear(), 0, 1);
        return Math.ceil((((now.valueOf() - start.valueOf()) / 86400000) + 1) / 7);
    };
    /**
     * Get Date
     *
     * @param offset Now - X Days
     */
    ApiService.prototype.getDate = function (offset) {
        var date = new Date();
        date.setDate(date.getDate() - offset);
        return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
    };
    /**
     * Get Dayname
     *
     * @param offset Now - X Days
     */
    ApiService.prototype.getDayname = function (offset) {
        var date = new Date();
        date.setDate(date.getDate() - offset);
        return this.DAYS[date.getDay()];
    };
    /**
     * Get Monthname
     *
     * @param offset Now - X Days
     */
    ApiService.prototype.getMonthname = function (offset) {
        var date = new Date();
        date.setMonth(date.getMonth() - offset);
        return this.MONTHS[date.getMonth()];
    };
    /**
     * Refresh current Token
     */
    ApiService.prototype.refreshToken = function () {
        // console.log('refresh token called');
        var _this = this;
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });
        var params = new HttpParams();
        params = params.append('method', 'refresh');
        params = params.append('refresh_token', this._userService.getActiveUserRefreshToken());
        this.refreshtoken = true;
        var body = { method: 'refresh', refresh_token: this._userService.getActiveUserRefreshToken() };
        this._httpClient.post(this.AUTH_BASE_URL, JSON.stringify(body), { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            return observableThrowError(error);
        }))
            .subscribe(function (response) {
            if ('access_token' in response) {
                var expires = new Date();
                expires.setSeconds(response.expires_in);
                _this._userService.updateActiveUserAccessToken(response.access_token);
                _this._userService.updateActiveUserRefreshToken(response.refresh_token);
                _this._userService.updateActiveUserTokenExpire(expires);
                _this.getInitialization().subscribe(function (data) {
                    if ('data' in data) {
                        if ('profile' in data.data) {
                            var providername = data.data.profile.labelpartner;
                            _this._userService.setActiveUserProvider(providername);
                            if (_this._router.routerState.snapshot.url === '/login') {
                                _this.trackUser(_this._userService.getActiveUserName(), data.data.profile);
                                _this._router.navigate(['/']);
                            }
                        }
                    }
                }, function (error) {
                    console.log('Error:', error);
                });
            }
        }, function () {
            _this.logoutUser();
        }, function () {
            _this.refreshtoken = false;
        });
    };
    /**
     * Standard-Header für API-Calls
     *
     * @param accessToken
     */
    ApiService.prototype.getDefaultHeaders = function (accessToken) {
        if (accessToken === void 0) { accessToken = ''; }
        var headers = new HttpHeaders({
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });
        if (typeof accessToken != 'undefined' && accessToken !== null && accessToken.length > 0) {
            var header_token = new HttpHeaders({ 'Authorization': 'Bearer ' + accessToken });
            return header_token;
        }
        return headers;
    };
    /**
     * Get current Token
     */
    ApiService.prototype.getToken = function () {
        // console.log('getToken() - token from service', this._userService.getActiveUserAccessToken());
        return this._userService.getActiveUserAccessToken();
    };
    /**
     * Request the current NILM status
     */
    ApiService.prototype.getNilmStatus = function () {
        var _this = this;
        var headers = this.getDefaultHeaders(this.getToken());
        return this._httpClient.get(this.API_BASE_URL + constants.api.routes.nilm.status, { headers: headers }).pipe(map(function (res) { return res; }), catchError(function (error) {
            if (error.status === 401) {
                _this.logoutUser();
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Send User-Data to Google Analytics
     *
     * @param user Email-Address
     * @param profile Profile-Informations
     */
    ApiService.prototype.trackUser = function (user, profile) {
        this._analytics.eventTrack.next({
            action: 'user_id',
            properties: {
                category: 'User Properties',
                label: new Md5().appendStr(user).end()
            }
        });
        this._analytics.eventTrack.next({
            action: 'device_id',
            properties: {
                category: 'User Properties',
                label: profile.labelpartner_id
            }
        });
        this._analytics.eventTrack.next({
            action: 'labelpartner',
            properties: {
                category: 'User Properties',
                label: profile.labelpartner
            }
        });
        this._analytics.eventTrack.next({
            action: 'gateway_id',
            properties: {
                category: 'User Properties',
                label: profile.smartbridge_mac
            }
        });
    };
    /**
     * HAPPY HOURS
     */
    ApiService.prototype.userParticipatesInHappyHour = function () {
        var headers = this.getDefaultHeaders(this.getToken());
        var url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.happyHour.participation);
        return this._httpClient.get(url.toString(), { headers: headers })
            .map(function (d) { return d; })
            .catch(function (e) {
            console.log(e);
            return observableThrowError(e);
        });
    };
    ApiService.prototype.getHappyHourSchedule = function (year, month) {
        var headers = this.getDefaultHeaders(this.getToken());
        var url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.happyHour.schedule);
        url.push(year.toString());
        url.push(month.toString());
        return this._httpClient.get(url.toString(), { headers: headers })
            .map(function (d) { return d; })
            .catch(function (e) {
            console.log(e);
            return observableThrowError(e);
        });
    };
    /**
     * Get the consumption-alert during happy hour for a specific timeframe
     * @param fct
     * @param first
     * @param second
     */
    ApiService.prototype.getHappyHourConsumptionFor = function (fct, first, second) {
        var headers = this.getDefaultHeaders(this.getToken());
        var url = new Url(null);
        url.push(this.API_BASE_URL);
        switch (fct) {
            case 'day':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;
            case 'month':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.months);
                break;
            case 'year':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.years);
                break;
            case 'week':
                url.push(constants.api.routes.iona.happyHour.consumption.electricity.days);
                break;
        }
        url.push(first.toString());
        url.push(second.toString());
        return this._httpClient.get(url.toString(), { headers: headers })
            .map(function (res) {
            var e_1, _a;
            if (fct === 'week') {
                if ('data' in res) {
                    var extracted_data = {};
                    var data = res['data'];
                    try {
                        for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                            var element = data_1_1.value;
                            var week = moment(element.timestamp).week();
                            if (extracted_data[week] === null || extracted_data[week] === undefined) {
                                extracted_data[week] = { value: 0, timestamp: element.timestamp };
                            }
                            extracted_data[week].value += element.measured;
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    var final_data = [];
                    for (var key in extracted_data) {
                        var obj = extracted_data[key];
                        final_data.push({
                            timestamp: obj.timestamp,
                            measured: obj.value
                        });
                    }
                    return { status: 'ok', data: final_data };
                }
                return res;
            }
            else {
                return res;
            }
        })
            .catch(function (e) {
            console.log(e);
            return observableThrowError(e);
        });
    };
    /**
     * Change some tracking state
     * @param state
     */
    ApiService.prototype.changeTrackingState = function (state) {
        if (state) {
            this._analytics.developerMode(false);
        }
        else {
            this._analytics.developerMode(true);
        }
    };
    ApiService.prototype.setApplicationState = function (state) {
        localStorage.setItem(constants.application.storage.keys.app_mode, state);
    };
    ApiService.prototype.enableDemoMode = function (current_time) {
        localStorage.setItem(constants.application.storage.keys.app_mode, constants.application.states.demo);
        localStorage.setItem(constants.application.storage.keys.app_expire, current_time.toString());
    };
    ApiService.prototype.getAppMode = function () {
        return localStorage.getItem(constants.application.storage.keys.app_mode);
    };
    ApiService.prototype.isLiveMode = function () {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.live;
    };
    ApiService.prototype.isDemoMode = function () {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.demo;
    };
    ApiService.prototype.isNoneMode = function () {
        return localStorage.getItem(constants.application.storage.keys.app_mode) === constants.application.states.none;
    };
    return ApiService;
}());
export { ApiService };
