import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Globals } from '../../services/globals.service';
import { MockDataService } from '../../mock-data.service';
var TodayWidget = /** @class */ (function () {
    function TodayWidget(_apiService, _globals, _mockData) {
        this._apiService = _apiService;
        this._globals = _globals;
        this._mockData = _mockData;
        this.refresh = [];
        this.week = {
            dayname: null,
            date: null
        };
        this.today = {
            kwh: 0,
            costs: '0,00'
        };
        this.history = {
            kwh: 0,
            costs: '0,00'
        };
        this.trend = {
            direction: 0,
            percent: 0,
            scale: {
                left: 1,
                right: 1
            }
        };
        this.showDiagrams = true;
    }
    TodayWidget.prototype.ngOnInit = function () {
        this.week = {
            dayname: this._apiService.getDayname(7),
            date: this._apiService.getDate(7)
        };
        if (this._apiService.isDemoMode()) {
            this.initializeMockData();
            return;
        }
        this.initializeApiConnection();
    };
    TodayWidget.prototype.ngOnDestroy = function () {
        clearInterval(this.refresh);
    };
    /**
     * Initializes the API / Mock data connections
     */
    TodayWidget.prototype.initializeMockData = function () {
        var _this = this;
        this.getMockConsumption();
        this.refresh = setInterval(function () {
            _this.week = {
                dayname: _this._apiService.getDayname(7),
                date: _this._apiService.getDate(7)
            };
            _this.getMockConsumption();
        }, 10000);
        return;
    };
    /**
     * Initializes connection to live API
     */
    TodayWidget.prototype.initializeApiConnection = function () {
        var _this = this;
        this.getConsumption();
        this.refresh = setInterval(function () {
            _this.week = {
                dayname: _this._apiService.getDayname(7),
                date: _this._apiService.getDate(7)
            };
            _this.getConsumption();
        }, 10000);
    };
    /**
     * Verbrauchs-Daten von API holen
     */
    TodayWidget.prototype.getConsumption = function () {
        var _this = this;
        this._apiService.getConsumption(0).subscribe(function (data) {
            if ('data' in data) {
                _this.showDiagrams = true;
                var costsFormated = '';
                // if (typeof data.data[0].cost_measured != 'undefined') {
                if (data.data[0].cost_measured !== null || data.data[0].cost_measured !== undefined) {
                    costsFormated = data.data[0].cost_measured.toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                }
                _this.today = {
                    kwh: (data.data[0].measured / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    costs: costsFormated
                };
                _this.getTrend(data.data[0].measured);
            }
            else {
                _this.showDiagrams = false;
            }
        }, function () {
            _this.showDiagrams = false;
        });
    };
    /**
     * Gather mock consumption-alert data
     */
    TodayWidget.prototype.getMockConsumption = function () {
        var _this = this;
        this._mockData.getDayConsumption(0).subscribe(function (data) {
            var e_1, _a;
            if ('data' in data) {
                // console.log('detail data', data);
                var consumption = 0;
                var costs = 0;
                _this.today.hours = [];
                try {
                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var con = _c.value;
                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;
                            _this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                costs: con.cost_measured.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            });
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                _this.today.hours.reverse();
                _this.today.kwh = (consumption / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                _this.today.costs = costs.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                // this.getTrend(consumption-alert);
                _this.getMockTrend(consumption);
            }
        });
    };
    /**
     * Trend berechnen
     *
     * @param today
     */
    TodayWidget.prototype.getTrend = function (today) {
        var _this = this;
        this._apiService.getDayConsumption(7).subscribe(function (data) {
            if ('data' in data) {
                _this.calculateTrend(data, today);
            }
        });
    };
    /**
     * Calculate the current trend based on mock data
     * @param today
     */
    TodayWidget.prototype.getMockTrend = function (today) {
        var _this = this;
        this._mockData.getDayConsumption(7).subscribe(function (data) {
            _this.calculateTrend(data, today);
        });
    };
    /**
     * Calculates the current trend based on todays summed consumption-alert and the collected dataset from a week ago
     * @param data
     * @param today
     */
    TodayWidget.prototype.calculateTrend = function (data, today) {
        var e_2, _a;
        var consumption = 0;
        var costs = 0;
        var now = new Date();
        try {
            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                var con = _c.value;
                var date = new Date(con.timestamp);
                if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                    if (('measured' in con) && ('cost_measured' in con)) {
                        consumption += con.measured;
                        costs += con.cost_measured;
                    }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.history = {
            kwh: (consumption / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            costs: costs.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        };
        if (today > consumption) {
            var percent = Math.round(((today - consumption) / consumption) * 100);
            var scale = 0;
            if (percent > 80) {
                scale = 20;
            }
            else {
                scale = 100 - percent;
            }
            this.trend = {
                direction: 1,
                percent: percent,
                scale: {
                    left: scale / 100,
                    right: 1
                }
            };
        }
        else if (today < consumption) {
            var percent = Math.round((1 - (today / consumption)) * 100);
            var scale = 0;
            if (percent > 80) {
                scale = 20;
            }
            else {
                scale = 100 - percent;
            }
            this.trend = {
                direction: -1,
                percent: percent,
                scale: {
                    left: 1,
                    right: scale / 100
                }
            };
        }
        else {
            this.trend = {
                direction: 0,
                percent: 0,
                scale: {
                    left: 1,
                    right: 1
                }
            };
        }
    };
    /**
     * Analytic stuff
     */
    TodayWidget.prototype.firstDetailViewAnalytics = function () {
        if (!(this._globals.getFirstDetailsViewed())) {
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Today-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    };
    return TodayWidget;
}());
export { TodayWidget };
