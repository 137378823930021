import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Angulartics2 } from 'angulartics2';
import { ApiService } from '../../services/api.service';
import { MockDataService } from '../../mock-data.service';
import { forkJoin, Subject } from 'rxjs';
import { UserService } from '../../user.service';
import * as moment from 'moment';
var CompareDetails = /** @class */ (function () {
    function CompareDetails(_analytics, _apiService, _mockData, _userService) {
        this._analytics = _analytics;
        this._apiService = _apiService;
        this._mockData = _mockData;
        this._userService = _userService;
        this.year = 0;
        this.mode = 1;
        this.timeframe = 3;
        this.position = 1;
        this.compare = {
            from_day: 0,
            from_kw: 0,
            from_month: 0,
            from_year: 0,
            to_day: 0,
            to_kw: 0,
            to_month: 0,
            to_year: 0
        };
        this.limit = 6;
        this.disabled = true;
        this.chart = {};
        this.lastYearColor = '#D27300';
        this.thisYearColor = '#F59B00';
        this.positionFinal = false;
        this.MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        // happyhour
        this.userHasHappyHour = false;
        this.onUserHasHappyHour = new Subject();
        this.onDataModeChange = new Subject();
        this.showConsumptionFor = 'all';
    }
    CompareDetails.prototype.ngOnInit = function () {
        var _this = this;
        this.onUserHasHappyHour.subscribe(function (value) {
            _this.userHasHappyHour = value;
        });
        this.onDataModeChange.subscribe(function (value) {
            _this.showConsumptionFor = value;
            _this.buildCompareDates();
            _this.resetChart();
        });
        this.initializeStaticChart();
    };
    CompareDetails.prototype.ngAfterViewInit = function () {
        if (this._apiService.isDemoMode()) {
            this.buildCompareDates();
            this.getMockStatic();
            return;
        }
        if (this._userService.getActiveUserProvider().toLowerCase() === 'enviam') {
            this.determineUserHasHappyHour();
        }
        this.buildCompareDates();
        this.getStatic();
    };
    /**
     * Vergleichs-Daten zusammen bauen
     */
    CompareDetails.prototype.buildCompareDates = function () {
        var dateRight = new Date(), dateLeft = new Date();
        this.year = dateRight.getFullYear();
        switch (this.timeframe) {
            case 2: { // Woche
                dateLeft.setDate(dateRight.getDate() - 7);
                break;
            }
            case 3: { // Monat
                dateLeft.setMonth(dateRight.getMonth() - 1);
                break;
            }
            case 4: { // Jahr
                dateLeft.setFullYear(dateRight.getFullYear() - 1);
                break;
            }
            default: { // Tag
                dateLeft.setDate(dateRight.getDate() - 1);
            }
        }
        this.compare = {
            left_day: dateLeft.getDate(),
            left_kw: this._apiService.getWeek(dateLeft),
            left_month: dateLeft.getMonth() + 1,
            left_year: dateLeft.getFullYear(),
            right_day: dateRight.getDate(),
            right_kw: this._apiService.getWeek(dateRight),
            right_month: dateRight.getMonth() + 1,
            right_year: dateRight.getFullYear()
        };
    };
    /**
     * Daten für Chart "Statisch"
     */
    CompareDetails.prototype.getStatic = function () {
        var _this = this;
        var e_1, _a;
        var mode = this.mode;
        var area = this.timeframe;
        var position = this.position;
        var _loop_1 = function (year) {
            var from = {};
            var to = {};
            var api = null;
            switch (this_1.timeframe) {
                case 2: {
                    from = new Date();
                    from.setFullYear(year.year);
                    from.setDate(from.getDate() - (this_1.position * (this_1.limit * 7)) + 7);
                    from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));
                    to = new Date();
                    to.setFullYear(year.year);
                    to.setDate(to.getDate() - (this_1.position * (this_1.limit * 7)) + (this_1.limit * 7));
                    to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));
                    api = 'days';
                    break;
                }
                case 3: {
                    from = new Date();
                    from.setFullYear(year.year);
                    from.setMonth(from.getMonth() - (this_1.position * this_1.limit) + 1);
                    to = new Date();
                    to.setFullYear(year.year);
                    to.setMonth(to.getMonth() - (this_1.position * this_1.limit) + this_1.limit);
                    api = 'months';
                    break;
                }
                case 4: {
                    from = new Date(0);
                    from.setFullYear(year.year);
                    from.setMonth(0);
                    to = new Date(0);
                    to.setFullYear(year.year);
                    to.setMonth(11);
                    to.setDate(31);
                    api = 'months';
                    break;
                }
                default: {
                    from = new Date();
                    from.setFullYear(year.year);
                    from.setDate(from.getDate() - (this_1.position * this_1.limit) + 1);
                    to = new Date();
                    to.setFullYear(year.year);
                    to.setDate(to.getDate() - (this_1.position * this_1.limit) + this_1.limit);
                    api = 'days';
                }
            }
            this_1._apiService.getCompare(from, to, api).subscribe(function (data) {
                var e_2, _a, e_3, _b, e_4, _c, e_5, _d;
                if ((mode === _this.mode) && (area === _this.timeframe) && (position === _this.position) && ('data' in data)) {
                    var chart = $('#chart-compare-details').highcharts();
                    if (data.data.length > 0) {
                        var categories = [];
                        switch (_this.timeframe) {
                            case 2: {
                                var consumption = [];
                                try {
                                    for (var _e = tslib_1.__values(data.data), _f = _e.next(); !_f.done; _f = _e.next()) {
                                        var con = _f.value;
                                        var kw = _this._apiService.getWeek(new Date(con.timestamp));
                                        var index = consumption.map(function (con) { return con.kw; }).indexOf(kw);
                                        if (index > -1) {
                                            if ('measured' in con) {
                                                consumption[index].wh += con.measured;
                                            }
                                        }
                                        else {
                                            if ('measured' in con) {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: con.measured
                                                });
                                            }
                                            else {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: 0
                                                });
                                            }
                                        }
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                                try {
                                    for (var consumption_1 = tslib_1.__values(consumption), consumption_1_1 = consumption_1.next(); !consumption_1_1.done; consumption_1_1 = consumption_1.next()) {
                                        var con = consumption_1_1.value;
                                        categories.push('KW ' + con.kw);
                                    }
                                }
                                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                finally {
                                    try {
                                        if (consumption_1_1 && !consumption_1_1.done && (_b = consumption_1.return)) _b.call(consumption_1);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                }
                                _this.chart.addSerie({
                                    name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                    data: consumption.map(function (con) {
                                        return Number((con.wh / 1000).toFixed(2));
                                    }),
                                    color: year.color
                                });
                                break;
                            }
                            case 4: {
                                var consumption = 0;
                                try {
                                    for (var _g = tslib_1.__values(data.data), _h = _g.next(); !_h.done; _h = _g.next()) {
                                        var con = _h.value;
                                        if ('measured' in con) {
                                            consumption += con.measured;
                                        }
                                    }
                                }
                                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                                finally {
                                    try {
                                        if (_h && !_h.done && (_c = _g.return)) _c.call(_g);
                                    }
                                    finally { if (e_4) throw e_4.error; }
                                }
                                categories = ['', ''];
                                _this.chart.addSerie({
                                    name: year.year,
                                    data: [Number((consumption / 1000).toFixed(2))],
                                    color: year.color
                                });
                                break;
                            }
                            default: {
                                try {
                                    for (var _j = tslib_1.__values(data.data), _k = _j.next(); !_k.done; _k = _j.next()) {
                                        var con = _k.value;
                                        var date = new Date(con.timestamp);
                                        switch (_this.timeframe) {
                                            case 3: {
                                                categories.push(_this._apiService.MONTHS[date.getMonth()]);
                                                break;
                                            }
                                            default: {
                                                categories.push(date.getDate() + '. ' + _this._apiService.MONTHS[date.getMonth()]);
                                            }
                                        }
                                    }
                                }
                                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                                finally {
                                    try {
                                        if (_k && !_k.done && (_d = _j.return)) _d.call(_j);
                                    }
                                    finally { if (e_5) throw e_5.error; }
                                }
                                _this.chart.addSerie({
                                    name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                    data: data.data.map(function (con) {
                                        return Number((con.measured / 1000).toFixed(2));
                                    }),
                                    color: year.color
                                });
                            }
                        }
                        chart.update({
                            xAxis: {
                                categories: categories
                            }
                        });
                    }
                    chart.hideLoading();
                    _this.disabled = false;
                }
            }, function () {
                var chart = $('#chart-compare-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                _this.disabled = false;
            });
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values([{ year: this.year - 1, color: '#D27300' }, { year: this.year, color: '#F59B00' }]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var year = _c.value;
                _loop_1(year);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    CompareDetails.prototype.getMockStatic = function () {
        var _this = this;
        var e_6, _a;
        var _loop_2 = function (year) {
            var req_params = this_2.determineStaticComparisonApiParams(year);
            var from = req_params.from;
            var to = req_params.to;
            var api = req_params.api;
            this_2._mockData.getConsumptionByDate(api, from, to).subscribe(function (data) {
                var e_7, _a, e_8, _b, e_9, _c, e_10, _d;
                if ('data' in data) {
                    var chart = $('#chart-compare-details').highcharts();
                    if (data.data.length > 0) {
                        var categories = [];
                        switch (_this.timeframe) {
                            case 2: {
                                var consumption = [];
                                try {
                                    for (var _e = tslib_1.__values(data.data), _f = _e.next(); !_f.done; _f = _e.next()) {
                                        var con = _f.value;
                                        var kw = _this._apiService.getWeek(new Date(con.timestamp));
                                        var index = consumption.map(function (con) { return con.kw; }).indexOf(kw);
                                        if (index > -1) {
                                            if ('measured' in con) {
                                                consumption[index].wh += con.measured;
                                            }
                                        }
                                        else {
                                            if ('measured' in con) {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: con.measured
                                                });
                                            }
                                            else {
                                                consumption.push({
                                                    kw: kw,
                                                    wh: 0
                                                });
                                            }
                                        }
                                    }
                                }
                                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                                finally {
                                    try {
                                        if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                                    }
                                    finally { if (e_7) throw e_7.error; }
                                }
                                try {
                                    for (var consumption_2 = tslib_1.__values(consumption), consumption_2_1 = consumption_2.next(); !consumption_2_1.done; consumption_2_1 = consumption_2.next()) {
                                        var con = consumption_2_1.value;
                                        categories.push('KW ' + con.kw);
                                    }
                                }
                                catch (e_8_1) { e_8 = { error: e_8_1 }; }
                                finally {
                                    try {
                                        if (consumption_2_1 && !consumption_2_1.done && (_b = consumption_2.return)) _b.call(consumption_2);
                                    }
                                    finally { if (e_8) throw e_8.error; }
                                }
                                _this.chart.addSerie({
                                    name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                    data: consumption.map(function (con) {
                                        return Number((con.wh / 1000).toFixed(2));
                                    }),
                                    color: year.color
                                });
                                break;
                            }
                            case 4: {
                                var consumption = 0;
                                try {
                                    for (var _g = tslib_1.__values(data.data), _h = _g.next(); !_h.done; _h = _g.next()) {
                                        var con = _h.value;
                                        if ('measured' in con) {
                                            consumption += con.measured;
                                        }
                                    }
                                }
                                catch (e_9_1) { e_9 = { error: e_9_1 }; }
                                finally {
                                    try {
                                        if (_h && !_h.done && (_c = _g.return)) _c.call(_g);
                                    }
                                    finally { if (e_9) throw e_9.error; }
                                }
                                categories = ['', ''];
                                _this.chart.addSerie({
                                    name: year.year,
                                    data: [Number((consumption / 1000).toFixed(2))],
                                    color: year.color
                                });
                                break;
                            }
                            default: {
                                try {
                                    for (var _j = tslib_1.__values(data.data), _k = _j.next(); !_k.done; _k = _j.next()) {
                                        var con = _k.value;
                                        var date = new Date(con.timestamp);
                                        switch (_this.timeframe) {
                                            case 3: {
                                                categories.push(_this._apiService.MONTHS[date.getMonth()]);
                                                break;
                                            }
                                            default: {
                                                categories.push(date.getDate() + '. ' +
                                                    _this._apiService.MONTHS[date.getMonth()]);
                                            }
                                        }
                                    }
                                }
                                catch (e_10_1) { e_10 = { error: e_10_1 }; }
                                finally {
                                    try {
                                        if (_k && !_k.done && (_d = _j.return)) _d.call(_j);
                                    }
                                    finally { if (e_10) throw e_10.error; }
                                }
                                _this.chart.addSerie({
                                    name: new Date(data.data[data.data.length - 1].timestamp).getFullYear().toString(),
                                    data: data.data.map(function (con) {
                                        return Number((con.measured / 1000).toFixed(2));
                                    }),
                                    color: year.color
                                });
                            }
                        }
                        _this.chart.options.xAxis.categories = categories;
                        chart.update({ xAxis: { categories: categories } });
                    }
                    chart.hideLoading();
                    _this.disabled = false;
                }
            }, function () {
                var chart = $('#chart-compare-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                _this.disabled = false;
            });
        };
        var this_2 = this;
        try {
            for (var _b = tslib_1.__values([{ year: this.year - 1, color: '#D27300' }, { year: this.year, color: '#F59B00' }]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var year = _c.value;
                _loop_2(year);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    /**
     * Daten für Chart "Anpassbar"
     */
    CompareDetails.prototype.getDynamic = function () {
        var _this = this;
        var mode = this.mode;
        var area = this.timeframe;
        var position = this.position;
        var left_from = {};
        var left_to = {};
        var right_from = {};
        var right_to = {};
        var api = null;
        switch (this.timeframe) {
            case 2: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setDate(left_from.getDate() + (this.compare.left_kw * 7) - 7);
                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setDate(left_to.getDate() + (this.compare.left_kw * 7) - 1);
                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setDate(right_from.getDate() + (this.compare.right_kw * 7) - 7);
                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setDate(right_to.getDate() + (this.compare.right_kw * 7) - 1);
                api = 'days';
                break;
            }
            case 3: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setFullYear(this.compare.left_year);
                left_to = left_from;
                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setFullYear(this.compare.right_year);
                right_to = right_from;
                api = 'months';
                break;
            }
            case 4: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);
                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);
                api = 'months';
                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setDate(this.compare.left_day);
                left_to = left_from;
                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setDate(this.compare.right_day);
                right_to = right_from;
                api = 'days';
            }
        }
        var left_wh = 0;
        var right_wh = 0;
        this._apiService.getCompare(left_from, left_to, api).subscribe(function (data) {
            var e_11, _a;
            if ((mode === _this.mode) && (area === _this.timeframe) && (position === _this.position) && ('data' in data)) {
                try {
                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var con = _c.value;
                        if ('measured' in con) {
                            left_wh += con.measured;
                        }
                    }
                }
                catch (e_11_1) { e_11 = { error: e_11_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_11) throw e_11.error; }
                }
                _this._apiService.getCompare(right_from, right_to, api).subscribe(function (data) {
                    var e_12, _a;
                    if ((area === _this.timeframe) && (position === _this.position) && ('data' in data)) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                if ('measured' in con) {
                                    right_wh += con.measured;
                                }
                            }
                        }
                        catch (e_12_1) { e_12 = { error: e_12_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_12) throw e_12.error; }
                        }
                        _this.chart.addSerie({
                            name: null,
                            data: [
                                {
                                    name: null,
                                    y: Number((left_wh / 1000).toFixed(2)),
                                    color: '#D27300'
                                },
                                {
                                    name: null,
                                    y: Number((right_wh / 1000).toFixed(2)),
                                    color: '#F59B00'
                                }
                            ]
                        });
                        var chart = $('#chart-compare-details').highcharts();
                        chart.hideLoading();
                        _this.disabled = false;
                    }
                }, function () {
                    var chart = $('#chart-compare-details').highcharts();
                    chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    _this.disabled = false;
                });
            }
        }, function () {
            var chart = $('#chart-compare-details').highcharts();
            chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            _this.disabled = false;
        });
    };
    /**
     *
     */
    CompareDetails.prototype.getMockDynamic = function () {
        var _this = this;
        var times = this.determineDynamicComparisonApiParams();
        var left_from = times.left_from;
        var left_to = times.left_to;
        var right_from = times.right_from;
        var right_to = times.right_to;
        var api = times.api;
        var left_wh = 0;
        var right_wh = 0;
        this._mockData.getConsumptionByDate(api, left_from, left_to).subscribe(function (data) {
            var e_13, _a;
            if ('data' in data) {
                try {
                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var con = _c.value;
                        if ('measured' in con) {
                            left_wh += con.measured;
                        }
                    }
                }
                catch (e_13_1) { e_13 = { error: e_13_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_13) throw e_13.error; }
                }
                _this._mockData.getConsumptionByDate(api, right_from, right_to).subscribe(function (data) {
                    var e_14, _a;
                    if ('data' in data) {
                        try {
                            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var con = _c.value;
                                if ('measured' in con) {
                                    right_wh += con.measured;
                                }
                            }
                        }
                        catch (e_14_1) { e_14 = { error: e_14_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_14) throw e_14.error; }
                        }
                        _this.chart.addSerie({
                            name: null,
                            data: [
                                {
                                    name: null,
                                    y: Number((left_wh / 1000).toFixed(2)),
                                    color: '#D27300'
                                },
                                {
                                    name: null,
                                    y: Number((right_wh / 1000).toFixed(2)),
                                    color: '#F59B00'
                                }
                            ]
                        });
                        var chart = $('#chart-compare-details').highcharts();
                        chart.hideLoading();
                        _this.disabled = false;
                    }
                }, function () {
                    var chart = $('#chart-compare-details').highcharts();
                    chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                    _this.disabled = false;
                });
            }
        }, function () {
            var chart = $('#chart-compare-details').highcharts();
            chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            _this.disabled = false;
        });
    };
    CompareDetails.prototype.getHappyHourStatic = function () {
        var _this = this;
        var api_mode;
        var left_start;
        var left_end;
        var right_start;
        var right_end;
        var day_format = 'YYYY-MM-DD';
        var month_format = 'YYYY-MM';
        this.positionFinal = false;
        // determine start and end days
        switch (this.timeframe) {
            case 1:
                api_mode = 'day';
                left_start = moment().subtract((this.position * this.limit) - 1, 'days').format(day_format);
                left_end = moment().subtract((this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format).subtract(1, 'year').format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format).subtract(1, 'year').format(day_format);
                break;
            case 2:
                // using day api for weeks
                api_mode = 'week';
                left_start = moment().subtract((7 * this.position * this.limit) - 7, 'days').format(day_format);
                left_end = moment().subtract(7 * (this.position - 1) * this.limit, 'days').format(day_format);
                right_start = moment(left_start, day_format).subtract(1, 'year').format(day_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(day_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, day_format).subtract(1, 'year').format(day_format);
                break;
            case 3:
                api_mode = 'month';
                left_start = moment().subtract((this.position * this.limit) - 1, 'months').format(month_format);
                left_end = moment().subtract((this.position - 1) * this.limit, 'months').format(month_format);
                right_start = moment(left_start, month_format).subtract(1, 'year').format(month_format);
                if (moment(right_start, day_format).year() < 2017) {
                    right_start = moment('01-01-2017', 'DD-MM-YYYY').format(month_format);
                    this.positionFinal = true;
                }
                right_end = moment(left_end, month_format).subtract(1, 'year').format(month_format);
                break;
            case 4:
                api_mode = 'year';
                left_start = moment().year();
                left_end = moment().year();
                right_start = moment().subtract(this.position, 'year').year();
                right_end = right_start;
                break;
            default:
                console.log('Unknown case');
                break;
        }
        // console.log(`left from ${left_start} to ${left_end}`);
        // console.log(`right from ${right_start} to ${right_end}`);
        var chart = $('#chart-compare-details').highcharts();
        var this_year_request = this._apiService.getHappyHourConsumptionFor(api_mode, left_start, left_end);
        var last_year_request = this._apiService.getHappyHourConsumptionFor(api_mode, right_start, right_end);
        forkJoin([this_year_request, last_year_request]).subscribe(function (responses) {
            var e_15, _a, e_16, _b, e_17, _c;
            var this_year_data = responses[0];
            var last_year_data = responses[1];
            if ('data' in last_year_data) {
                _this.chart.addSerie({
                    name: new Date(last_year_data.data[last_year_data.data.length - 1].timestamp).getFullYear().toString(),
                    data: last_year_data.data.map(function (con) {
                        return Number((con.measured / 1000).toFixed(2));
                    }),
                    color: _this.lastYearColor
                });
            }
            if ('data' in this_year_data) {
                var categories = [];
                if (api_mode === 'day') {
                    try {
                        for (var _d = tslib_1.__values(this_year_data.data), _e = _d.next(); !_e.done; _e = _d.next()) {
                            var consumption = _e.value;
                            var date = moment(consumption.timestamp).toDate();
                            var date_string = date.getDate() + ". " + _this.MONTHS[date.getMonth()];
                            categories.push(date_string);
                        }
                    }
                    catch (e_15_1) { e_15 = { error: e_15_1 }; }
                    finally {
                        try {
                            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                        }
                        finally { if (e_15) throw e_15.error; }
                    }
                }
                else if (api_mode === 'week') {
                    try {
                        for (var _f = tslib_1.__values(this_year_data.data), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var consumption = _g.value;
                            var date_string = "KW " + moment(consumption.month).week();
                            categories.push(date_string);
                        }
                    }
                    catch (e_16_1) { e_16 = { error: e_16_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_16) throw e_16.error; }
                    }
                }
                else if (api_mode === 'month') {
                    try {
                        for (var _h = tslib_1.__values(this_year_data.data), _j = _h.next(); !_j.done; _j = _h.next()) {
                            var consumption = _j.value;
                            var date = moment(consumption.timestamp).toDate();
                            var date_string = "" + _this.MONTHS[date.getMonth()];
                            categories.push(date_string);
                        }
                    }
                    catch (e_17_1) { e_17 = { error: e_17_1 }; }
                    finally {
                        try {
                            if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                        }
                        finally { if (e_17) throw e_17.error; }
                    }
                }
                else if (api_mode === 'year') {
                    categories = ['', ''];
                }
                _this.chart.addSerie({
                    name: new Date(this_year_data.data[this_year_data.data.length - 1].timestamp).getFullYear().toString(),
                    data: this_year_data.data.map(function (con) {
                        return Number((con.measured / 1000).toFixed(2));
                    }),
                    color: _this.thisYearColor
                });
                if (api_mode !== 'year') {
                    _this.chart.options.xAxis.categories = categories;
                    _this.chart.ref.update({ xAxis: { categories: categories } });
                }
            }
            _this.disabled = false;
            chart.hideLoading();
        });
    };
    CompareDetails.prototype.getHappyHourDynamic = function () {
        var _this = this;
        var api_mode;
        var right_start;
        var right_end;
        var left_start;
        var left_end;
        var day_format = 'YYYY-MM-DD';
        var month_format = 'YYYY-MM';
        var date_str_right = this.compare.right_year + "-" + this.compare.right_month + "-" + this.compare.right_day;
        var date_str_left = this.compare.left_year + "-" + this.compare.left_month + "-" + this.compare.left_day;
        switch (this.timeframe) {
            case 1:
                api_mode = 'day';
                right_start = moment(date_str_right, day_format).format(day_format);
                left_start = moment(date_str_left, day_format).format(day_format);
                right_end = right_start;
                left_end = left_start;
                break;
            case 2:
                api_mode = 'week';
                moment.locale('de');
                right_start = moment().year(this.compare.right_year).week(this.compare.right_kw).startOf('week').format(day_format);
                right_end = moment(right_start, day_format).endOf('week').format(day_format);
                left_start = moment().year(this.compare.left_year).week(this.compare.left_kw).format(day_format);
                left_end = moment(left_start, day_format).endOf('week').format(day_format);
                break;
            case 3:
                api_mode = 'month';
                right_start = moment(date_str_right, day_format).format(month_format);
                right_end = right_start;
                left_start = moment(date_str_left, day_format).format(month_format);
                left_end = left_start;
                break;
            case 4:
                right_start = moment(date_str_right, day_format).year();
                right_end = right_start;
                left_start = moment(date_str_left, day_format).year();
                left_end = left_start;
                api_mode = 'year';
                break;
            default:
                console.log('Unknown case');
        }
        // console.log(`left from ${left_start} to ${left_end}`);
        // console.log(`right from ${right_start} to ${right_end}`);
        var chart = $('#chart-compare-details').highcharts();
        var right_request = this._apiService.getHappyHourConsumptionFor(api_mode, right_start, right_end);
        var left_request = this._apiService.getHappyHourConsumptionFor(api_mode, left_start, left_end);
        forkJoin([right_request, left_request]).subscribe(function (responses) {
            var right_data = responses[0];
            var left_data = responses[1];
            if ('data' in left_data && 'data' in right_data) {
                _this.chart.addSerie({
                    name: null,
                    data: [
                        {
                            name: null,
                            y: Number((left_data.data[0].measured / 1000).toFixed(2)),
                            color: _this.lastYearColor
                        },
                        {
                            name: null,
                            y: Number((right_data.data[0].measured / 1000).toFixed(2)),
                            color: _this.thisYearColor
                        }
                    ]
                });
            }
            // if ('data' in last_year_data) {
            //     this.chart.addSerie(
            //         {
            //             name: new Date(last_year_data.data[last_year_data.data.length - 1].timestamp).getFullYear().toString(),
            //             data:
            //                 last_year_data.data.map((con: any) => {
            //                     return Number((con.measured / 1000).toFixed(2));
            //                 }),
            //             color: this.lastYearColor
            //         }
            //     );
            // }
            //
            // if ('data' in this_year_data) {
            //     const categories_this_year = [];
            //     if (api_mode === 'day') {
            //         for (const consumption-alert of this_year_data.data) {
            //             const date = moment(consumption-alert.timestamp).toDate();
            //             const date_string = `${date.getDate()}. ${this.MONTHS[date.getMonth()]}`;
            //             categories_this_year.push(date_string);
            //         }
            //     } else if (api_mode === 'week') {
            //         for (const consumption-alert of this_year_data.data) {
            //             const date_string = `KW ${moment(consumption-alert.month).week()}`;
            //             categories_this_year.push(date_string);
            //         }
            //     } else if (api_mode === 'month') {
            //         for (const consumption-alert of this_year_data.data) {
            //             const date = moment(consumption-alert.timestamp).toDate();
            //             const date_string = `${this.MONTHS[date.getMonth()]}`;
            //             categories_this_year.push(date_string);
            //         }
            //     }
            //
            //     this.chart.addSerie(
            //         {
            //             name: new Date(this_year_data.data[this_year_data.data.length - 1].timestamp).getFullYear().toString(),
            //             data:
            //                 this_year_data.data.map((con: any) => {
            //                     return Number((con.measured / 1000).toFixed(2));
            //                 }),
            //             color: this.thisYearColor
            //         }
            //     );
            //
            //     if (api_mode !== 'year') {
            //         this.chart.options.xAxis.categories = categories_this_year;
            //         this.chart.ref.update({xAxis: {categories: categories_this_year}});
            //     }
            //
            // }
            _this.disabled = false;
            chart.hideLoading();
        });
        this.disabled = false;
    };
    /**
     * Modus "Statisch" / "Anpassbar" wechseln
     *
     * @param mode
     */
    CompareDetails.prototype.setMode = function (mode) {
        if (mode !== this.mode) {
            this.mode = mode;
            this.resetChart();
            this._analytics.eventTrack.next({
                action: 'screen_view',
                properties: {
                    category: 'Screens',
                    label: 'screen: Vergleich - ' + (this.mode === 2 ? 'Anpassbar' : 'Statisch') +
                        '; previous_screen: Vergleich - ' + (this.mode === 2 ? 'Statisch' : 'Anpassbar')
                }
            });
        }
    };
    /**
     * Skala wechseln (Tag/Woche/Monat/Jahr)
     *
     * @param timeframe
     */
    CompareDetails.prototype.setTimeframe = function (timeframe) {
        if ((parseInt(timeframe, 10) !== this.timeframe) && (!this.disabled)) {
            this.timeframe = parseInt(timeframe, 10);
            this.position = 1;
            this.buildCompareDates();
            this.resetChart();
        }
    };
    /**
     * Anpassbares Datum setzen
     *
     * @param key
     * @param value
     */
    CompareDetails.prototype.setCompare = function (key, value) {
        this.compare[key] = parseInt(value, 10);
        this.resetChart();
    };
    /**
     * Eine Einheit vor
     */
    CompareDetails.prototype.positionForward = function () {
        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }
    };
    /**
     * Eine Einheit zurück
     */
    CompareDetails.prototype.positionBack = function () {
        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
    };
    /**
     * zu "Jetzt" springen
     */
    CompareDetails.prototype.positionNow = function () {
        if (!this.disabled) {
            this.position = 1;
            this.resetChart();
        }
    };
    /**
     * Zurücksetzen des Diagramms und Werte neu holen
     */
    CompareDetails.prototype.resetChart = function () {
        var chart = $('#chart-compare-details').highcharts();
        chart.showLoading();
        this.disabled = true;
        if (this.mode === 2) {
            this.initializeDynamicChart();
            if (this._apiService.isDemoMode()) {
                this.getMockDynamic();
                return;
            }
            if (this.showConsumptionFor === 'all') {
                this.getDynamic();
            }
            else if (this.showConsumptionFor === 'happyhour') {
                this.getHappyHourDynamic();
            }
        }
        else {
            this.initializeStaticChart();
            if (this._apiService.isDemoMode()) {
                this.getMockStatic();
                return;
            }
            if (this.showConsumptionFor === 'all') {
                this.getStatic();
            }
            else if (this.showConsumptionFor === 'happyhour') {
                this.getHappyHourStatic();
            }
        }
    };
    CompareDetails.prototype.loop = function (start, times) {
        var loop = [];
        for (var i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    };
    /**
     * Chart-Konfiguration "Statisch"
     */
    CompareDetails.prototype.initializeStaticChart = function () {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'categories',
                categories: []
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    align: 'right',
                    x: 75,
                    y: 15,
                    step: 2,
                    formatter: function () {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' kWh';
                        }
                        else {
                            return null;
                        }
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return this.y.toLocaleString('de-DE') + ' kWh';
                },
                borderRadius: 10,
                shadow: false
            },
            plotOptions: {
                column: {
                    borderRadius: 10,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                padding: 0
            },
            series: [],
            credits: {
                enabled: false
            }
        });
    };
    /**
     * Chart-Konfiguration "Anpassbar"
     */
    CompareDetails.prototype.initializeDynamicChart = function () {
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 0, 0]
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null
                },
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    align: 'right',
                    x: 75,
                    y: 15,
                    step: 2,
                    formatter: function () {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE') + ' kWh';
                        }
                        else {
                            return null;
                        }
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return this.y.toLocaleString('de-DE') + ' kWh';
                },
                borderRadius: 10,
                shadow: false
            },
            plotOptions: {
                column: {
                    borderRadius: 10,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        });
    };
    /**
     * Determine API params for the static comparison
     * @param year
     */
    CompareDetails.prototype.determineStaticComparisonApiParams = function (year) {
        var from = {};
        var to = {};
        var api = null;
        switch (this.timeframe) {
            case 2: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * (this.limit * 7)) + 7);
                from.setDate(from.getDate() - (from.getDay() > 0 ? (from.getDay() - 1) : 6));
                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * (this.limit * 7)) + (this.limit * 7));
                to.setDate(to.getDate() + (to.getDay() > 0 ? (7 - to.getDay()) : 0));
                api = 'days';
                break;
            }
            case 3: {
                from = new Date();
                from.setFullYear(year.year);
                from.setMonth(from.getMonth() - (this.position * this.limit) + 1);
                to = new Date();
                to.setFullYear(year.year);
                to.setMonth(to.getMonth() - (this.position * this.limit) + this.limit);
                api = 'months';
                break;
            }
            case 4: {
                from = new Date(0);
                from.setFullYear(year.year);
                from.setMonth(0);
                to = new Date(0);
                to.setFullYear(year.year);
                to.setMonth(11);
                to.setDate(31);
                api = 'months';
                break;
            }
            default: {
                from = new Date();
                from.setFullYear(year.year);
                from.setDate(from.getDate() - (this.position * this.limit) + 1);
                to = new Date();
                to.setFullYear(year.year);
                to.setDate(to.getDate() - (this.position * this.limit) + this.limit);
                api = 'days';
            }
        }
        return { from: from, to: to, api: api };
    };
    /**
     * Determine API params for the dynamic comparison
     * @param year
     */
    CompareDetails.prototype.determineDynamicComparisonApiParams = function () {
        var left_from = {};
        var left_to = {};
        var right_from = {};
        var right_to = {};
        var api = null;
        switch (this.timeframe) {
            case 2: { // Woche
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setDate(left_from.getDate() + (this.compare.left_kw * 7) - 7);
                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setDate(left_to.getDate() + (this.compare.left_kw * 7) - 1);
                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setDate(right_from.getDate() + (this.compare.right_kw * 7) - 7);
                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setDate(right_to.getDate() + (this.compare.right_kw * 7) - 1);
                api = 'days';
                break;
            }
            case 3: { // Monat
                left_from = new Date(0);
                left_from.setDate(1);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setFullYear(this.compare.left_year);
                left_to = left_from;
                right_from = new Date(0);
                right_from.setDate(1);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setFullYear(this.compare.right_year);
                right_to = right_from;
                api = 'months';
                break;
            }
            case 4: { // Jahr
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_to = new Date(0);
                left_to.setFullYear(this.compare.left_year);
                left_to.setMonth(11);
                left_to.setDate(31);
                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_to = new Date(0);
                right_to.setFullYear(this.compare.right_year);
                right_to.setMonth(11);
                right_to.setDate(31);
                api = 'months';
                break;
            }
            default: { // Tag
                left_from = new Date(0);
                left_from.setFullYear(this.compare.left_year);
                left_from.setMonth(this.compare.left_month - 1);
                left_from.setDate(this.compare.left_day);
                left_to = left_from;
                right_from = new Date(0);
                right_from.setFullYear(this.compare.right_year);
                right_from.setMonth(this.compare.right_month - 1);
                right_from.setDate(this.compare.right_day);
                right_to = right_from;
                api = 'days';
            }
        }
        return { left_from: left_from, left_to: left_to, right_from: right_from, right_to: right_to, api: api };
    };
    /**
     * Request whether the user participates in the happy hour program
     */
    CompareDetails.prototype.determineUserHasHappyHour = function () {
        var _this = this;
        this._apiService.userParticipatesInHappyHour().subscribe(function (response) {
            if (response === null || response === undefined) {
                console.log('undefined from response');
                return;
            }
            if ('data' in response) {
                var now_1 = moment();
                var parsed_response_data = response.data;
                // filter by elements with date earlier than today
                var filtered = parsed_response_data.filter(function (element) { return now_1 >= moment(element.from_date, 'DD/MM/YYYY'); });
                var sorted = filtered.sort(function (a, b) {
                    var a_from = moment(a.from_date, 'DD/MM/YYYY');
                    var b_from = moment(b.from_date, 'DD/MM/YYYY');
                    if (a_from.unix() > b_from.unix()) {
                        return -1;
                    }
                    else if (a_from.unix() < b_from.unix()) {
                        return 1;
                    }
                    return 0;
                });
                if ('value' in sorted[0]) {
                    _this.onUserHasHappyHour.next(sorted[0].value === 1);
                }
            }
        }, function (error) {
        });
    };
    /**
     * On Mode change
     */
    CompareDetails.prototype.setDataMode = function (mode) {
        this.onDataModeChange.next(mode);
    };
    return CompareDetails;
}());
export { CompareDetails };
