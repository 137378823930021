import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Angulartics2 } from 'angulartics2';
import { ApiService } from '../../services/api.service';
import { MockDataService } from '../../mock-data.service';
var ConsumerDetails = /** @class */ (function () {
    function ConsumerDetails(_analytics, _apiService, _mockData) {
        this._analytics = _analytics;
        this._apiService = _apiService;
        this._mockData = _mockData;
        this.months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        this.date = null;
        this.colors = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];
        this.color = 0;
        this.tempdata = [];
        this.mode = 1;
        this.offset = 0;
        this.month = 0;
        this.disabled = true;
        this.chart = {};
        this.consumer = [];
        this.showConsumers = true;
        this.nilmdata = null;
    }
    ConsumerDetails.prototype.ngOnInit = function () {
        this.date = this.months[new Date().getMonth()] + ' ' + new Date().getFullYear();
        this.initializeChart();
        if (this._apiService.isDemoMode()) {
            this.getMockElectricalAppliances();
            this.getMockNilmStatus();
            return;
        }
        this.getElectricalAppliances();
        this.getNilmStatus();
    };
    /**
     * Alle Verbraucher-Daten holen (Monatsübersicht)
     */
    ConsumerDetails.prototype.getElectricalAppliances = function () {
        var _this = this;
        var mode = this.mode;
        var offset = this.offset;
        this.showConsumers = true;
        this._apiService.getElectricalAppliances(this.offset).subscribe(function (data) {
            if ((mode === _this.mode) && (offset === _this.offset) && ('data' in data)) {
                _this.drawChart(data);
            }
        }, function () {
            var chart = $('#chart-consumer-details').highcharts();
            chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            _this.showConsumers = false;
            _this.disabled = false;
        });
    };
    ConsumerDetails.prototype.getMockElectricalAppliances = function () {
        var _this = this;
        var mode = this.mode;
        var offset = this.offset;
        this.showConsumers = true;
        this._mockData.getElectricalAppliances(offset).subscribe(function (data) {
            if (data.data !== null && data.data !== undefined) {
                if ('data' in data) {
                    _this.drawChart(data);
                }
            }
            else {
                var chart = $('#chart-consumer-details').highcharts();
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
                _this.showConsumers = false;
                _this.disabled = false;
            }
        });
    };
    ConsumerDetails.prototype.getMockNilmStatus = function () {
        var _this = this;
        this._mockData.getNILMStatus().subscribe(function (data) {
            if ('data' in data) {
                _this.nilmdata = data.data;
            }
        });
    };
    ConsumerDetails.prototype.getNilmStatus = function () {
        var _this = this;
        this._apiService.getNilmStatus().subscribe(function (data) {
            if ('data' in data) {
                _this.nilmdata = data.data;
            }
        }, function (error) {
            console.log(error);
        });
    };
    /**
     * Alle Verbraucher-Daten holen (Jahresübersicht)
     */
    ConsumerDetails.prototype.getElectricalAppliancesYear = function () {
        var _this = this;
        var e_1, _a;
        var mode = this.mode;
        var offset = this.offset;
        this.showConsumers = true;
        try {
            for (var _b = tslib_1.__values([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var month = _c.value;
                this._apiService.getMonthConsumer(month, this.offset).subscribe(function (data) {
                    var e_2, _a;
                    _this.month++;
                    if ((mode === _this.mode) && (offset === _this.offset) && ('data' in data)) {
                        try {
                            for (var _b = tslib_1.__values(Object.keys(data.data.electricity.used_budget.categories)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var consumer = _c.value;
                                if (data.data.electricity.used_budget.categories[consumer].usage > 0) {
                                    if (consumer in _this.tempdata) {
                                        _this.tempdata[consumer].push({
                                            usage: data.data.electricity.used_budget.categories[consumer].usage,
                                            cost: data.data.electricity.used_budget.categories[consumer].cost
                                        });
                                    }
                                    else {
                                        _this.tempdata[consumer] = [{
                                                usage: data.data.electricity.used_budget.categories[consumer].usage,
                                                cost: data.data.electricity.used_budget.categories[consumer].cost
                                            }];
                                    }
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                    _this.updateChart();
                }, function () {
                    _this.month++;
                    _this.updateChart();
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     *
     */
    ConsumerDetails.prototype.getMockElectricalAppliancesYear = function () {
        var _this = this;
        var e_3, _a;
        try {
            for (var _b = tslib_1.__values([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]), _c = _b.next(); !_c.done; _c = _b.next()) {
                var month = _c.value;
                this._mockData.getElectricalAppliancesForMonth(month, this.offset).subscribe(function (data) {
                    var e_4, _a;
                    if (data.data !== null && data.data !== undefined) {
                        ++_this.month;
                        if ('data' in data) {
                            try {
                                for (var _b = tslib_1.__values(Object.keys(data.data.electricity.used_budget.categories)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var appliance = _c.value;
                                    if (data.data.electricity.used_budget.categories[appliance].usage > 0) {
                                        if (appliance in _this.tempdata) {
                                            _this.tempdata[appliance].push({
                                                usage: data.data.electricity.used_budget.categories[appliance].usage,
                                                cost: data.data.electricity.used_budget.categories[appliance].cost
                                            });
                                        }
                                        else {
                                            _this.tempdata[appliance] = [{
                                                    usage: data.data.electricity.used_budget.categories[appliance].usage,
                                                    cost: data.data.electricity.used_budget.categories[appliance].cost
                                                }];
                                        }
                                    }
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                        }
                    }
                    else {
                        _this.month++;
                        _this.updateChart();
                    }
                    _this.updateChart();
                });
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    /**
     * Modus wechseln (Monat/Jahr)
     *
     * @param mode
     */
    ConsumerDetails.prototype.setMode = function (mode) {
        if (mode !== this.mode) {
            this.mode = mode;
            this.offset = 0;
            this.resetChart();
            this._analytics.eventTrack.next({
                action: 'screen_view',
                properties: {
                    category: 'Screens',
                    label: 'screen: Meine Geräte - ' + (this.mode === 2 ? 'Jahresübersicht' : 'Monatsübersicht') + '; previous_screen: Meine Geräte - ' + (this.mode === 2 ? 'Monatsübersicht' : 'Jahresübersicht')
                }
            });
        }
    };
    /**
     * Eine Einheit vor
     */
    ConsumerDetails.prototype.positionForward = function () {
        if ((this.offset > 0) && (!this.disabled)) {
            this.offset--;
            this.resetChart();
        }
    };
    /**
     * Eine Einheit zurück
     */
    ConsumerDetails.prototype.positionBack = function () {
        if (!this.disabled) {
            this.offset++;
            this.resetChart();
        }
    };
    /**
     * Diagramm zurücksetzen und Daten neu holen
     */
    ConsumerDetails.prototype.resetChart = function () {
        this.updateDate();
        var chart = $('#chart-consumer-details').highcharts();
        chart.showLoading();
        this.chart.removeSerie(0);
        this.chart.addSerie({
            name: null,
            innerSize: '65%',
            data: []
        });
        this.disabled = true;
        this.consumer = [];
        if (this.mode === 2) {
            if (this._apiService.isDemoMode()) {
                this.getMockElectricalAppliancesYear();
                return;
            }
            this.getElectricalAppliancesYear();
        }
        else {
            if (this._apiService.isDemoMode()) {
                this.getMockElectricalAppliances();
                return;
            }
            this.getElectricalAppliances();
        }
    };
    /**
     * Diagramm aktualisieren
     */
    ConsumerDetails.prototype.updateChart = function () {
        var e_5, _a, e_6, _b, e_7, _c;
        this.showConsumers = true;
        if (this.month === 12) {
            var tempdata = [];
            try {
                for (var _d = tslib_1.__values(Object.keys(this.tempdata)), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var appliance = _e.value;
                    var usage = 0;
                    var cost = 0;
                    try {
                        for (var _f = tslib_1.__values(this.tempdata[appliance]), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var month = _g.value;
                            usage += month.usage;
                            cost += month.cost;
                        }
                    }
                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_6) throw e_6.error; }
                    }
                    tempdata.push({
                        name: appliance,
                        usage: usage,
                        cost: cost
                    });
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_5) throw e_5.error; }
            }
            tempdata.sort(function (a, b) {
                if (a.usage < b.usage) {
                    return 1;
                }
                if (b.usage < a.usage) {
                    return -1;
                }
                return 0;
            });
            try {
                for (var tempdata_1 = tslib_1.__values(tempdata), tempdata_1_1 = tempdata_1.next(); !tempdata_1_1.done; tempdata_1_1 = tempdata_1.next()) {
                    var appliance = tempdata_1_1.value;
                    var color = this.getColor();
                    this.chart.addPoint({
                        name: appliance.name,
                        y: Math.round(appliance.usage),
                        x: appliance.cost,
                        color: color
                    });
                    this.consumer.push({
                        name: appliance.name,
                        kwh: Math.round(appliance.usage).toLocaleString('de-DE'),
                        cost: appliance.cost.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }),
                        color: color
                    });
                }
            }
            catch (e_7_1) { e_7 = { error: e_7_1 }; }
            finally {
                try {
                    if (tempdata_1_1 && !tempdata_1_1.done && (_c = tempdata_1.return)) _c.call(tempdata_1);
                }
                finally { if (e_7) throw e_7.error; }
            }
            this.showConsumers = this.consumer.length != 0;
            var chart = $('#chart-consumer-details').highcharts();
            if (this.consumer.length > 0) {
                chart.hideLoading();
            }
            else {
                chart.showLoading('Keine Auswertung für diesen Zeitraum verfügbar!');
            }
            this.month = 0;
            this.color = 0;
            this.tempdata = [];
            this.disabled = false;
        }
    };
    /**
     * Datum zurücksetzen
     */
    ConsumerDetails.prototype.updateDate = function () {
        switch (this.mode) {
            case 1: {
                var date = new Date();
                date.setMonth(date.getMonth() - this.offset, 1);
                this.date = this.months[date.getMonth()] + ' ' + date.getFullYear();
                break;
            }
            case 2: {
                var date = new Date();
                date.setFullYear(date.getFullYear() - this.offset);
                this.date = date.getFullYear();
                break;
            }
        }
    };
    /**
     * Farbe für Verbraucher
     */
    ConsumerDetails.prototype.getColor = function () {
        if (this.color < this.colors.length) {
            this.color++;
            return this.colors[this.color - 1];
        }
        else {
            return this.colors[this.colors.length - 1];
        }
    };
    /**
     * Übersetzung der Verbraucher von API
     *
     * @param text
     */
    ConsumerDetails.prototype.translate = function (text) {
        switch (text) {
            case 'AlwaysOn': {
                return 'Standby';
            }
            case 'Refrigeration': {
                return 'Kühlung';
            }
            case 'SpaceHeating': {
                return 'Nachtspeicherofen';
            }
            case 'WaterHeating': {
                return 'Durchlauferhitzer';
            }
            case 'Cooking': {
                return 'Kochen';
            }
            case 'Laundry': {
                return 'Waschen & Trocknen';
            }
            case 'Lighting': {
                return 'Beleuchtung';
            }
            case 'Entertainment': {
                return 'Unterhaltung';
            }
            case 'ElectricVehicle': {
                return 'E-Auto';
            }
            case 'PoolOrSauna': {
                return 'Pool & Sauna';
            }
            case 'Other': {
                return 'Sonstige';
            }
            default: {
                return text;
            }
        }
    };
    /**
     * Draw
     * @param data
     */
    ConsumerDetails.prototype.drawChart = function (data) {
        var e_8, _a, e_9, _b;
        try {
            for (var _c = tslib_1.__values(Object.keys(data.data.electricity.used_budget.categories)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var consumer = _d.value;
                if (data.data.electricity.used_budget.categories[consumer].usage > 0) {
                    this.tempdata.push({
                        name: consumer,
                        usage: data.data.electricity.used_budget.categories[consumer].usage,
                        cost: data.data.electricity.used_budget.categories[consumer].cost
                    });
                }
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_8) throw e_8.error; }
        }
        this.tempdata.sort(function (a, b) {
            if (a.usage < b.usage) {
                return 1;
            }
            if (b.usage < a.usage) {
                return -1;
            }
            return 0;
        });
        try {
            for (var _e = tslib_1.__values(this.tempdata), _f = _e.next(); !_f.done; _f = _e.next()) {
                var consumer = _f.value;
                var color = this.getColor();
                this.consumer.push({
                    name: consumer.name,
                    kwh: Math.round(consumer.usage).toLocaleString('de-DE'),
                    cost: consumer.cost.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }),
                    color: color
                });
                this.chart.addPoint({
                    name: consumer.name,
                    y: Math.round(consumer.usage),
                    x: consumer.cost,
                    color: color
                });
            }
        }
        catch (e_9_1) { e_9 = { error: e_9_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_9) throw e_9.error; }
        }
        var chart = $('#chart-consumer-details').highcharts();
        if (chart !== null && chart !== undefined) {
            chart.hideLoading();
        }
        this.color = 0;
        this.tempdata = [];
        this.disabled = false;
        this.showConsumers = this.consumer.length != 0;
    };
    /**
     * Initialize the chart
     */
    ConsumerDetails.prototype.initializeChart = function () {
        var self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0]
            },
            title: {
                text: null
            },
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner: function (boxWidth, boxHeight) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter: function () {
                    var html = '<div style="text-align: center;">';
                    var point_name = this.point.name.toLowerCase();
                    if (point_name !== 'other') {
                        if (point_name === 'laundry' || point_name === 'refrigeration' || point_name === 'entertainment' || point_name === 'cooking') {
                            var profile_complete = self.determineProfileCompleteness(point_name);
                            // nilm condition
                            if (profile_complete) {
                                html += "<i class=\"ico chart-icon icon-" + point_name + "\" style=\"color: " + this.point.color + "\"></i>";
                            }
                            else {
                                var inner = "<i class=\"boeppel\"></i>";
                                html += "<i class=\"ico iic chart-icon icon-" + point_name + "\" style=\"color: " + this.point.color + "\">" + inner + "</i>";
                            }
                        }
                        else {
                            html += "<i class=\"ico chart-icon icon-" + point_name + "\" style=\"color: " + this.point.color + "\"></i>";
                        }
                    }
                    html += '<h3>' + this.point.y.toLocaleString('de-DE') + ' kWh</h3>';
                    html += '<hr>';
                    html += '<h3>' + this.point.x.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + '</h3>';
                    html += '</div>';
                    return html;
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter: function () {
                            return '<div class="label" style="color: ' + this.point.color + ';">' + Math.round(this.percentage) + ' %</div>';
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 1,
                        softConnector: false
                    },
                    startAngle: -180,
                    states: {
                        hover: {
                            brightness: 0,
                            halo: false
                        }
                    },
                    point: {
                        events: {
                            mouseOver: function () {
                                this.graphic.attr({
                                    r: this.shapeArgs.r + 5
                                });
                            },
                            mouseOut: function () {
                                this.graphic.attr({
                                    r: this.shapeArgs.r
                                });
                            }
                        }
                    }
                }
            },
            series: [
                {
                    name: null,
                    innerSize: '65%',
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });
    };
    /**
     * Determines the profile completenes for a certain category.
     * @param point_name
     */
    ConsumerDetails.prototype.determineProfileCompleteness = function (point_name) {
        var e_10, _a, e_11, _b;
        if (point_name === 'refrigeration') {
            if (this.nilmdata.nonTimeBasedAppliances.refrigeration !== null || this.nilmdata.nonTimeBasedAppliances.refrigeration !== undefined) {
                var el = this.nilmdata.nonTimeBasedAppliances.refrigeration;
                return this.determineCompletenesOfAppliance(el);
            }
        }
        else if (point_name === 'cooking') {
            var oven = this.nilmdata.timeBasedAppliances.oven;
            // const hob = this.nilmdata.timeBasedAppliances.hob;
            var profiles = [oven];
            if (profiles.every(function (element) { return element !== false && element !== undefined && element !== null; })) {
                var results = [];
                try {
                    for (var profiles_1 = tslib_1.__values(profiles), profiles_1_1 = profiles_1.next(); !profiles_1_1.done; profiles_1_1 = profiles_1.next()) {
                        var element = profiles_1_1.value;
                        results.push(this.determineCompletenesOfAppliance(element));
                    }
                }
                catch (e_10_1) { e_10 = { error: e_10_1 }; }
                finally {
                    try {
                        if (profiles_1_1 && !profiles_1_1.done && (_a = profiles_1.return)) _a.call(profiles_1);
                    }
                    finally { if (e_10) throw e_10.error; }
                }
                return results.every(function (el) { return el !== false && el !== undefined && el !== null; });
            }
        }
        else if (point_name === 'laundry') {
            var washingMachine = this.nilmdata.timeBasedAppliances.washingMachine;
            var dryer = this.nilmdata.timeBasedAppliances.dryer;
            var dishWasher = this.nilmdata.timeBasedAppliances.dishWasher;
            var profiles = [washingMachine, dryer, dishWasher];
            if (profiles.every(function (element) { return element !== false && element !== undefined && element !== null; })) {
                var results = [];
                try {
                    for (var profiles_2 = tslib_1.__values(profiles), profiles_2_1 = profiles_2.next(); !profiles_2_1.done; profiles_2_1 = profiles_2.next()) {
                        var element = profiles_2_1.value;
                        results.push(this.determineCompletenesOfAppliance(element));
                    }
                }
                catch (e_11_1) { e_11 = { error: e_11_1 }; }
                finally {
                    try {
                        if (profiles_2_1 && !profiles_2_1.done && (_b = profiles_2.return)) _b.call(profiles_2);
                    }
                    finally { if (e_11) throw e_11.error; }
                }
                return results.every(function (el) { return el !== false && el !== undefined && el !== null; });
            }
        }
        else if (point_name === 'entertainment') {
            var el = void 0;
            if (this.nilmdata.nonTimeBasedAppliances.entertainment !== null ||
                this.nilmdata.nonTimeBasedAppliances.entertainment !== undefined) {
                el = this.nilmdata.nonTimeBasedAppliances.entertainment;
            }
            if (el.profileComplete || el.profileComplete === false && el.profileAdded === true) {
                return true;
            }
        }
        else {
            return true;
        }
        return false;
    };
    ConsumerDetails.prototype.determineCompletenesOfAppliance = function (el) {
        return el.profileComplete || el.profileComplete === false && el.profileAdded === true;
    };
    return ConsumerDetails;
}());
export { ConsumerDetails };
