import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { Globals } from '../services/globals.service';
import { MockDataService } from '../mock-data.service';
import { UserService } from '../user.service';
import { getProviderIconClass, getProviderMenuName } from '../ProivderUtil';
import { static_links } from '../constants';
var HouseholdComponent = /** @class */ (function () {
    function HouseholdComponent(_titleService, _notification, _apiService, _globals, _mockData, _userService) {
        this._titleService = _titleService;
        this._notification = _notification;
        this._apiService = _apiService;
        this._globals = _globals;
        this._mockData = _mockData;
        this._userService = _userService;
        this.refresh = [];
        /**
         * Anbieter-Daten
         */
        this.provider = 0;
        this.providerclass = '';
        this.providername = '';
        this.privacyLink = '';
        this.providerMenuName = null;
        this.username = null;
        this.fullname = null;
        /**
         * Haushalts-Daten
         */
        this.household = {
            Attributes: {
                PropertyType: null,
                PropertySize: null,
                PropertyAge: null,
                Occupants: null,
                SpaceHeating: null,
                WaterHeating: null,
                OvenHeating: null,
                GrillHeating: null,
                StoveHeating: null
            },
            Appliances: []
        };
        this.appliances = [];
        this.version = '0.0.0';
        this.changelog = [];
        this.changelog_visible = false;
    }
    HouseholdComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._titleService.setTitle('Mein Haushalt | iONA');
        this.getVersion();
        this.getChangelog();
        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.privacyLink = static_links.privacy[this.providername];
        if (this.providername === null || this.providername === undefined) {
            console.log('provider not determined yet');
        }
        this.providerclass = getProviderIconClass(this.providername);
        this.providerMenuName = getProviderMenuName(this.providername);
        if (this._apiService.isDemoMode()) {
            this.getMockInitialize();
            this.getMockProfile();
            return;
        }
        /* DE SCRUBIFY THIS */
        this._apiService.checkToken();
        this._apiService.checkMeterStatus();
        this.refresh = setInterval(function () {
            _this._apiService.checkToken();
        }, 10000);
        /* END DE SCRUBIFY THIS */
        this.getInitialize();
        this.getProfile();
    };
    HouseholdComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.refresh);
    };
    /**
     * Get initialize data
     */
    HouseholdComponent.prototype.getInitialize = function () {
        var _this = this;
        this._apiService.getInitialization().subscribe(function (data) {
            if ('data' in data) {
                if ('profile' in data.data) {
                    _this.processContractData(data);
                }
            }
        });
    };
    /**
     * Get Mock initialize data
     */
    HouseholdComponent.prototype.getMockInitialize = function () {
        var _this = this;
        this._mockData.getInitialize().subscribe(function (data) {
            _this.processContractData(data);
        });
    };
    /**
     * Get User Profile
     */
    HouseholdComponent.prototype.getProfile = function () {
        var _this = this;
        this._apiService.getProfile().subscribe(function (data) {
            if ('data' in data) {
                _this.processProfileData(data);
            }
        });
    };
    /**
     * Get Mock user profile
     */
    HouseholdComponent.prototype.getMockProfile = function () {
        var _this = this;
        this._mockData.getProfileAttributes().subscribe(function (data) {
            if ('data' in data) {
                _this.processProfileData(data);
            }
        });
    };
    /**
     * Setting ändern
     *
     * @param setting
     * @param value
     * @param type
     */
    HouseholdComponent.prototype.setSetting = function (setting, value, type) {
        switch (type) {
            case 'Attributes': {
                this.household.Attributes[setting] = value;
                break;
            }
            case 'Appliances': {
                this.household.Appliances[setting] = parseInt(value, 10);
                break;
            }
        }
    };
    /**
     * neue Settings speichern
     */
    HouseholdComponent.prototype.saveSettings = function () {
        var _this = this;
        var e_1, _a;
        var errors = [];
        // Prüfen der eingegebenen Daten
        if ((this.household.Appliances['A.04'] > 0) && (this.household.Attributes.OvenHeating === 'OH.00')) {
            errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        }
        else if ((this.household.Appliances['A.04'] === 0) && (this.household.Attributes.OvenHeating !== 'OH.00')) {
            errors.push('Ihre Angaben zum Backofen sind widersprüchlich!');
        }
        if ((this.household.Appliances['A.05'] > 0) && (this.household.Attributes.GrillHeating === 'GH.00')) {
            errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        }
        else if ((this.household.Appliances['A.05'] === 0) && (this.household.Attributes.GrillHeating !== 'GH.00')) {
            errors.push('Ihre Angaben zum Grill sind widersprüchlich!');
        }
        if ((this.household.Appliances['A.06'] > 0) && (this.household.Attributes.StoveHeating === 'STH.00')) {
            errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        }
        else if ((this.household.Appliances['A.06'] === 0) && (this.household.Attributes.StoveHeating !== 'STH.00')) {
            errors.push('Ihre Angaben zum Herd sind widersprüchlich!');
        }
        console.log('household', JSON.stringify(this.household));
        if (errors.length > 0) {
            try {
                for (var errors_1 = tslib_1.__values(errors), errors_1_1 = errors_1.next(); !errors_1_1.done; errors_1_1 = errors_1.next()) {
                    var error = errors_1_1.value;
                    this._notification.warning(error);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (errors_1_1 && !errors_1_1.done && (_a = errors_1.return)) _a.call(errors_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            // Wenn Onboarding-Prozess - Google Analytics Event
            if (this._globals.getIsOnboarding()) {
                this._apiService._analytics.eventTrack.next({
                    action: 'onboarding_profile_end',
                    properties: {
                        category: 'Onboarding'
                    }
                });
                this._globals.resetIsOnboarding();
            }
            if (!this._apiService.isDemoMode()) {
                this._apiService.setProfile(this.household).subscribe(function (response) {
                    _this._notification.success('Ihre Angaben zum Haushalt wurden übernommen!');
                }, function (error) {
                    _this._notification.error('Hoppla, da ist etwas schief gelaufen.', 'Fehler');
                    var error_obj = error.error;
                    if (error_obj.error.code === 180) {
                        console.log(error);
                        console.log(error_obj.error.message);
                    }
                });
            }
            else {
                this._notification.info('Die Angaben zum Haushalt können im Demo-Modus nicht verändert werden!');
            }
        }
    };
    /**
     * Gerät zufügen
     *
     * @param appliance
     */
    HouseholdComponent.prototype.subAmount = function (appliance) {
        if (this.household.Appliances[appliance] > 0) {
            this.household.Appliances[appliance] -= 1;
        }
    };
    /**
     * Gerät entfernen
     *
     * @param appliance
     */
    HouseholdComponent.prototype.addAmount = function (appliance) {
        if (this.household.Appliances[appliance] === null || this.household.Appliances[appliance] === undefined) {
            this.household.Appliances[appliance] = 0;
        }
        if (this.household.Appliances[appliance] < 99) {
            this.household.Appliances[appliance] += 1;
        }
    };
    /**
     * Ist dieses Gerät ausgewählt?
     */
    HouseholdComponent.prototype.isApplianceSelected = function () {
        var e_2, _a;
        try {
            for (var _b = tslib_1.__values(this.appliances), _c = _b.next(); !_c.done; _c = _b.next()) {
                var appliance = _c.value;
                if (this.household.Appliances[appliance] > 0) {
                    return true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return false;
    };
    /**
     * Get Current app version
     */
    HouseholdComponent.prototype.getVersion = function () {
        var _this = this;
        this._apiService.getChangelog().subscribe(function (data) {
            if (0 in data) {
                _this.version = data[0].version;
            }
        });
    };
    /**
     * Get changelog
     */
    HouseholdComponent.prototype.getChangelog = function () {
        var _this = this;
        this._apiService.getChangelog().subscribe(function (data) {
            _this.changelog = data;
        });
    };
    /**
     * Display the last changelog
     */
    HouseholdComponent.prototype.showChangelog = function () {
        this.changelog_visible = true;
    };
    /**
     * Hide changelog
     */
    HouseholdComponent.prototype.hideChangelog = function () {
        this.changelog_visible = false;
    };
    /**
     * Process contract data
     * @param data
     */
    HouseholdComponent.prototype.processContractData = function (data) {
        if (this.username === 'Demo') {
            this.fullname = 'Frau Maxi Mustermann';
        }
        else {
            this.fullname = data.data.profile.customer_name;
        }
    };
    /**
     * Process Profile information
     * @param data
     */
    HouseholdComponent.prototype.processProfileData = function (data) {
        if ('Attributes' in data.data) {
            this.household.Attributes = {
                PropertyType: data.data.Attributes.PropertyType,
                PropertySize: data.data.Attributes.PropertySize,
                PropertyAge: data.data.Attributes.PropertyAge,
                Occupants: data.data.Attributes.Occupants,
                SpaceHeating: data.data.Attributes.SpaceHeating,
                WaterHeating: data.data.Attributes.WaterHeating,
                OvenHeating: data.data.Attributes.OvenHeating,
                GrillHeating: data.data.Attributes.GrillHeating,
                StoveHeating: data.data.Attributes.StoveHeating
            };
        }
        if ('Appliances' in data.data) {
            this.household.Appliances = data.data.Appliances;
            this.appliances = Object.keys(this.household.Appliances);
            setTimeout(function () {
                var elements = $('.grid .box');
                elements.matchHeight();
            }, 100);
        }
    };
    return HouseholdComponent;
}());
export { HouseholdComponent };
