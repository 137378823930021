import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MockDataService } from '../../mock-data.service';
var TodayDetails = /** @class */ (function () {
    function TodayDetails(_apiService, _mockData) {
        this._apiService = _apiService;
        this._mockData = _mockData;
        this.refresh = [];
        this.week = {
            dayname: null,
            date: null
        };
        this.today = {
            kwh: 0,
            costs: '0,00',
            hours: []
        };
        this.history = {
            kwh: 0,
            costs: '0,00'
        };
        this.trend = {
            direction: 0,
            percent: 0,
            scale: {
                left: 1,
                right: 1
            }
        };
    }
    TodayDetails.prototype.ngOnInit = function () {
        this.week = {
            dayname: this._apiService.getDayname(7),
            date: this._apiService.getDate(7)
        };
        if (this._apiService.isDemoMode()) {
            this.initializeMockData();
            return;
        }
        this.initializeApiConnection();
    };
    TodayDetails.prototype.ngOnDestroy = function () {
        clearInterval(this.refresh);
    };
    /**
     * Initializes connection to the mock API
     */
    TodayDetails.prototype.initializeMockData = function () {
        var _this = this;
        this.getMockConsumption();
        this.refresh = setInterval(function () {
            _this.week = {
                dayname: _this._apiService.getDayname(7),
                date: _this._apiService.getDate(7)
            };
            _this.getMockConsumption();
        }, 10000);
        return;
    };
    /**
     * Initializes connection to live API
     */
    TodayDetails.prototype.initializeApiConnection = function () {
        var _this = this;
        this.getConsumption();
        this.refresh = setInterval(function () {
            _this.week = {
                dayname: _this._apiService.getDayname(7),
                date: _this._apiService.getDate(7)
            };
            _this.getConsumption();
        }, 10000);
    };
    /**
     * Get live consumption-alert data
     */
    TodayDetails.prototype.getConsumption = function () {
        var _this = this;
        this._apiService.getDayConsumption(0).subscribe(function (data) {
            var e_1, _a;
            if ('data' in data) {
                var consumption = 0;
                var costs = 0;
                _this.today.hours = [];
                try {
                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var con = _c.value;
                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;
                            _this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                costs: con.cost_measured.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            });
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                _this.today.hours.reverse();
                _this.today.kwh = (consumption / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                _this.today.costs = costs.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                _this.getTrend(consumption);
            }
        });
    };
    /**
     * Get Mock consumption-alert data
     */
    TodayDetails.prototype.getMockConsumption = function () {
        var _this = this;
        this._mockData.getDayConsumption(0).subscribe(function (data) {
            var e_2, _a;
            if ('data' in data) {
                // console.log('detail data', data);
                var consumption = 0;
                var costs = 0;
                _this.today.hours = [];
                try {
                    for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var con = _c.value;
                        if (('measured' in con) && ('cost_measured' in con)) {
                            consumption += con.measured;
                            costs += con.cost_measured;
                            _this.today.hours.push({
                                hour: new Date(con.timestamp).getHours(),
                                kwh: (con.measured / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                costs: con.cost_measured.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            });
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                _this.today.hours.reverse();
                _this.today.kwh = (consumption / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                _this.today.costs = costs.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                // this.getTrend(consumption-alert);
                _this.getMockTrend(consumption);
            }
        });
    };
    /**
     * Get the current Trend based on last weeks data
     * @param consumption_today
     */
    TodayDetails.prototype.getTrend = function (consumption_today) {
        var _this = this;
        this._apiService.getDayConsumption(7).subscribe(function (data) {
            if ('data' in data) {
                _this.calculateTrend(data, consumption_today);
            }
        });
    };
    /**
     * Get Mock trend
     */
    TodayDetails.prototype.getMockTrend = function (consumption_today) {
        var _this = this;
        this._mockData.getDayConsumption(7).subscribe(function (data) {
            _this.calculateTrend(data, consumption_today);
        });
    };
    /**
     * Calculates the current trend based on todays summed consumption-alert and the collected dataset from a week ago
     * @param data
     * @param today
     */
    TodayDetails.prototype.calculateTrend = function (data, today) {
        var e_3, _a;
        var consumption = 0;
        var costs = 0;
        var now = new Date();
        try {
            for (var _b = tslib_1.__values(data.data), _c = _b.next(); !_c.done; _c = _b.next()) {
                var con = _c.value;
                var date = new Date(con.timestamp);
                if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                    if (('measured' in con) && ('cost_measured' in con)) {
                        consumption += con.measured;
                        costs += con.cost_measured;
                    }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.history = {
            kwh: (consumption / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            costs: costs.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        };
        if (today > consumption) {
            var percent = Math.round(((today - consumption) / consumption) * 100);
            var scale = 0;
            if (percent > 80) {
                scale = 20;
            }
            else {
                scale = 100 - percent;
            }
            this.trend = {
                direction: 1,
                percent: percent,
                scale: {
                    left: scale / 100,
                    right: 1
                }
            };
        }
        else if (today < consumption) {
            var percent = Math.round((1 - (today / consumption)) * 100);
            var scale = 0;
            if (percent > 80) {
                scale = 20;
            }
            else {
                scale = 100 - percent;
            }
            this.trend = {
                direction: -1,
                percent: percent,
                scale: {
                    left: 1,
                    right: scale / 100
                }
            };
        }
        else {
            this.trend = {
                direction: 0,
                percent: 0,
                scale: {
                    left: 1,
                    right: 1
                }
            };
        }
    };
    return TodayDetails;
}());
export { TodayDetails };
