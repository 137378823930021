import * as tslib_1 from "tslib";
import { of as observableOf, throwError as observableThrowError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { constants } from './constants';
var MockDataService = /** @class */ (function () {
    function MockDataService(http) {
        this.http = http;
        this.timestamp_start = new Date();
        this.test = false;
        // private test: boolean = true;
        // private online_disabled: boolean = true;
        this.online_disabled = false;
        this.plugOff = false;
        // private plugOff: boolean = true;
        // private userHasPlug: boolean = false;
        this.userHasPlug = true;
        this.power_data = {
            power_5_min: null,
            power_1_hour: null,
            power_12_hours: null,
            power_24_hours: null,
        };
        this.consumption_data = {
            hours: null,
            days: null,
            months: null,
        };
    }
    /**
     * Get live data from mock files
     * @param offset
     * @param limit
     * @param interval
     */
    MockDataService.prototype.getLiveData = function (offset, level, limit, interval) {
        var _this = this;
        var dataset_file = '';
        var dataset_loaded = false;
        var dataset;
        switch (level) {
            case 1:
                dataset_file = constants.demo.files.power_5_seconds;
                dataset_loaded = this.power_data.power_5_min !== null;
                if (dataset_loaded) {
                    dataset = this.power_data.power_5_min;
                }
                break;
            case 2:
                dataset_file = constants.demo.files.power_1_minute;
                dataset_loaded = this.power_data.power_1_hour !== null;
                if (dataset_loaded) {
                    dataset = this.power_data.power_1_hour;
                }
                break;
            case (3):
                dataset_file = constants.demo.files.power_1_minute;
                dataset_loaded = this.power_data.power_12_hours !== null;
                if (dataset_loaded) {
                    dataset = this.power_data.power_12_hours;
                }
                break;
            case (4):
                dataset_file = constants.demo.files.power_15_minutes;
                dataset_loaded = this.power_data.power_24_hours !== null;
                if (dataset_loaded) {
                    dataset = this.power_data.power_24_hours;
                }
                break;
        }
        if (dataset_loaded) {
            return observableOf(dataset).pipe(map(function (value) {
                return _this.filterByTime(value, offset, limit);
            }), catchError(function (error) {
                return observableThrowError(error);
            }));
        }
        return this.http.get("assets/data/demo/" + dataset_file + ".json").pipe(map(function (value) {
            var data_aligned = _this.mapValues(value, offset, interval, level);
            switch (offset) {
                case 5:
                    _this.power_data.power_5_min = data_aligned;
                    break;
                case 60:
                    _this.power_data.power_1_hour = data_aligned;
                    break;
                case (60 * 12):
                    _this.power_data.power_12_hours = data_aligned;
                    break;
                case (60 * 24):
                    _this.power_data.power_24_hours = data_aligned;
                    break;
            }
            return _this.filterByTime(data_aligned, offset, limit);
        }), catchError(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return observableThrowError(error);
        }));
    };
    /**
     * Get the current consumption-alert status
     */
    MockDataService.prototype.getHomeStateStatus = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.home_state_current + ".json")
            .map(function (value) { return value; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    MockDataService.prototype.getHomeStateConfig = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.home_state_config + ".json").pipe(map(function (value) { return value; }), catchError(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        }));
    };
    /**
     * Returns the Past Consumption
     */
    MockDataService.prototype.getConsumption = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.consumption_hours + ".json")
            .map(function (values) {
            var e_1, _a;
            var consumption_summed = 0;
            var costs_summed = 0;
            var now = new Date();
            try {
                for (var _b = tslib_1.__values(values['data']), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var set = _c.value;
                    var date = new Date(set.timestamp);
                    if (((now.getMinutes() <= 29) && (date.getHours() <= now.getHours())) || ((now.getMinutes() >= 30) && (date.getHours() <= (now.getHours() + 1)))) {
                        consumption_summed += set.measured;
                        costs_summed += set.cost_measured;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var dataset_final = values['data'][0];
            dataset_final.measured = consumption_summed;
            dataset_final.cost_measured = costs_summed;
            return { status: 'ok', data: [dataset_final] };
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     *
     * @param offset
     */
    MockDataService.prototype.getDayConsumption = function (offset) {
        var dataset = '';
        switch (offset) {
            case 7:
                dataset = constants.demo.files.consumption_hours_last_week;
                break;
            case 0:
                dataset = constants.demo.files.consumption_hours;
                break;
        }
        return this.http.get("assets/data/demo/" + dataset + ".json")
            .map(function (values) {
            var e_2, _a;
            if (offset === 7) {
                return values;
            }
            var new_dataset = [];
            var today = new Date();
            var counter = 0;
            try {
                for (var _b = tslib_1.__values(values['data']), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var set = _c.value;
                    // const date = new Date(set.timestamp);
                    var date = new Date(today);
                    date.setHours(counter);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    if (((today.getMinutes() <= 29) && (date.getHours() <= today.getHours())) || ((today.getMinutes() >= 30) && (date.getHours() <= (today.getHours() + 1)))) {
                        set.timestamp = date;
                        new_dataset.push(set);
                    }
                    // console.log(date);
                    ++counter;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return { status: 'ok', data: new_dataset };
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     * Get current electrical appliances
     */
    MockDataService.prototype.getElectricalAppliances = function (offset) {
        return this.http.get("assets/data/demo/" + constants.demo.files.disagregation_history + ".json")
            .map(function (value) {
            var dataset = value;
            return { status: 'ok', data: dataset['data'][dataset['data'].length - offset - 1] };
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     *
     * @param month
     * @param offset This describes the used offset in 12 month steps
     */
    MockDataService.prototype.getElectricalAppliancesForMonth = function (month, offset) {
        return this.http.get("assets/data/demo/" + constants.demo.files.disagregation_history + ".json")
            .map(function (value) {
            var dataset = value['data'].reverse();
            var current_month = new Date().getMonth();
            var month_idx = month + (offset * 12) - 1;
            // console.log(month_idx);
            if (offset == 0 || month <= current_month) {
                return { status: 'ok', data: dataset[month_idx] };
            }
            return { status: 'ok', data: dataset[month_idx] };
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     * Load smart meter information data
     */
    MockDataService.prototype.getInitialize = function () {
        var filename;
        if (this.test) {
            filename = constants.demo.files.initialization_test;
        }
        else {
            filename = constants.demo.files.initialization;
        }
        return this.http.get("assets/data/demo/" + filename + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return observableThrowError(error);
        });
    };
    MockDataService.prototype.getMeterStatus = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.meter_status + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     * Get user profile data
     */
    MockDataService.prototype.getProfile = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.profile + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     *
     */
    MockDataService.prototype.getProfileAttributes = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.profile_attributes + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     *
     */
    MockDataService.prototype.getCurrentConsumption = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.instantaneous + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     * Return the consumption-alert for a specific date
     */
    MockDataService.prototype.getConsumptionForDate = function (date) {
        var _this = this;
        return this.http.get("assets/data/demo/" + constants.demo.files.consumption + ".json")
            .map(function (values) {
            var data = values['data'].reverse();
            for (var i = 0; i < data.length; ++i) {
                var start_time_cpy = new Date(_this.timestamp_start);
                var new_date = new Date(start_time_cpy.setDate(start_time_cpy.getDate() - i));
                data[i].timestamp = new_date;
            }
            var date_cpy = new Date(date);
            date_cpy.setHours(0, 0, 0, 0);
            var filtered = data.filter(function (element) {
                var element_date = new Date(element.timestamp);
                element_date.setHours(0, 0, 0, 0);
                var day_match = element_date.getDate() == date.getDate();
                var mon_match = element_date.getMonth() == date.getMonth();
                var year_match = element_date.getFullYear() == date.getFullYear();
                return day_match && mon_match && year_match;
            });
            return { status: 'ok', data: filtered };
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     *
     * @param filter
     * @param start_date
     * @param end_date
     */
    MockDataService.prototype.getConsumptionByDate = function (filter, start_date, end_date) {
        var _this = this;
        var dataset_file = '';
        var dataset_loaded = false;
        var dataset;
        switch (filter) {
            case 'months':
                dataset_file = constants.demo.files.consumption_months;
                dataset_loaded = this.consumption_data.months !== null;
                if (dataset_loaded) {
                    dataset = this.consumption_data.months;
                }
                break;
            case 'days':
                dataset_file = constants.demo.files.consumption_days;
                dataset_loaded = this.consumption_data.days !== null;
                if (dataset_loaded) {
                    dataset = this.consumption_data.days;
                }
                break;
            case 'hours':
                dataset_file = constants.demo.files.consumption_hours;
                dataset_loaded = this.consumption_data.hours !== null;
                if (dataset_loaded) {
                    dataset = this.consumption_data.hours;
                }
                break;
        }
        if (dataset_loaded) {
            var dataset_filtered = this.getComparison(dataset, filter, start_date, end_date);
            return Observable.of(dataset_filtered);
        }
        return this.http.get("assets/data/demo/" + dataset_file + ".json")
            .map(function (values) {
            var data = values['data'].reverse();
            // pre process datasets to feature the current time as starting point
            switch (filter) {
                case 'months':
                    for (var i = 0; i < data.length; ++i) {
                        var start_time_cpy = new Date(_this.timestamp_start);
                        var new_date = new Date(start_time_cpy.setMonth(start_time_cpy.getMonth() - i));
                        data[i].timestamp = new_date;
                    }
                    _this.consumption_data.months = data;
                    break;
                case 'days':
                    for (var i = 0; i < data.length; ++i) {
                        var start_time_cpy = new Date(_this.timestamp_start);
                        var new_date = new Date(start_time_cpy.setDate(start_time_cpy.getDate() - i));
                        data[i].timestamp = new_date;
                    }
                    _this.consumption_data.days = data;
                    break;
                case 'hours':
                    break;
            }
            return _this.getComparison(data, filter, start_date, end_date);
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    MockDataService.prototype.getComparison = function (data, filter, date_start, date_end) {
        var filtered = data.filter(function (element) {
            // if the dates match e.g when selecting a single day
            switch (filter) {
                case 'days':
                    if (date_start == date_end) {
                        var element_date = new Date(element.timestamp);
                        var day_match = element_date.getDate() == date_start.getDate();
                        var mon_match = element_date.getMonth() == date_start.getMonth();
                        var year_match = element_date.getFullYear() == date_start.getFullYear();
                        return day_match && mon_match && year_match;
                    }
                    break;
                case 'months':
                    if (date_start == date_end) {
                        var element_date = new Date(element.timestamp);
                        // const day_match = element_date.getDate() == date_start.getDate();
                        var mon_match = element_date.getMonth() == date_start.getMonth();
                        var year_match = element_date.getFullYear() == date_start.getFullYear();
                        return mon_match && year_match;
                    }
                    break;
                default:
                    break;
            }
            return element.timestamp >= date_start && element.timestamp <= date_end;
        });
        return { status: 'ok', data: filtered.reverse() };
    };
    MockDataService.prototype.getMonthlyComparison = function () {
        return this.http.get("assets/data/demo/" + constants.demo.files.consumption_months + ".json")
            .map(function (values) {
            var data = values['data'];
            return { status: 'ok', data: [data[data.length - 1], data[data.length - 2]] };
        })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    MockDataService.prototype.getBillPrediction = function () {
        var file = constants.demo.files.bill_prediction;
        if (this.test) {
            file = constants.demo.files.bill_prediction_test;
        }
        return this.http.get("assets/data/demo/" + file + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    MockDataService.prototype.getNILMStatus = function () {
        var filename = constants.demo.files.nilm_status;
        if (this.test) {
            filename = constants.demo.files.nilm_status_test;
        }
        return this.http.get("assets/data/demo/" + filename + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    MockDataService.prototype.getRegistrationOnline = function () {
        var filename = constants.demo.files.nilm_status;
        if (this.online_disabled) {
            filename = '';
        }
        else {
            if (this.test) {
                filename = constants.demo.files.registration_online_test;
            }
        }
        console.log('requesting', filename);
        return this.http.get("assets/data/demo/" + filename + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return observableThrowError(error);
        });
    };
    MockDataService.prototype.getRegistrationModel = function () {
        var filename = constants.demo.files.registration_model;
        if (!this.userHasPlug) {
            filename = constants.demo.files.registration_model_test;
        }
        return this.http.get("assets/data/demo/" + filename + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    MockDataService.prototype.getPlugStatus = function () {
        var filename = constants.demo.files.plug_relay;
        if (this.plugOff) {
            filename = constants.demo.files.plug_relay_test;
        }
        return this.http.get("assets/data/demo/" + filename + ".json")
            .map(function (values) { return values; })
            .catch(function (error) {
            if (error.status === 401) {
                console.log('MockDataService error: ', error);
            }
            return Observable.throw(error);
        });
    };
    /**
     * Map values based on the current time
     * @param response
     * @param interval
     */
    MockDataService.prototype.mapValues = function (response, offset, interval, position) {
        var e_3, _a;
        var data = response['data'].results;
        var num_history_steps = 0;
        switch (position) {
            case 1:
                num_history_steps = ~~((data.length - 300) / 300);
                break;
            case 2:
                num_history_steps = ~~(data.length / 60) - 5;
                break;
            case 3:
                num_history_steps = ~~(data.length / 720);
                break;
            case 4:
                num_history_steps = ~~(data.length / 144) + 1;
                break;
        }
        // console.log(`num history steps: ${num_history_steps}`);
        var start_time_cpy = new Date(this.timestamp_start);
        start_time_cpy.setMinutes(start_time_cpy.getMinutes() - (num_history_steps * offset));
        try {
            for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                var set = data_1_1.value;
                var new_date = start_time_cpy.setSeconds(start_time_cpy.getSeconds() + interval);
                set.timestamp = new_date;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return { status: 'ok', data: { results: data, ranges: response.ranges } };
    };
    /**
     * Filter Values by a given timeframe
     * @param data
     * @param offset
     * @param limit
     */
    MockDataService.prototype.filterByTime = function (data, offset, limit) {
        var timestamp_start = new Date();
        timestamp_start.setMinutes(timestamp_start.getMinutes() - offset);
        var timestamp_end = new Date();
        timestamp_end.setMinutes(timestamp_end.getMinutes() - limit);
        var filtered = data['data'].results.filter(function (value) {
            var element_date = new Date(value.timestamp);
            // return value.timestamp >= timestamp_start && value.timestamp <= timestamp_end;
            return element_date >= timestamp_start && element_date <= timestamp_end;
        });
        return filtered;
    };
    return MockDataService;
}());
export { MockDataService };
