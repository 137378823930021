import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MockDataService } from '../../mock-data.service';
import { NguCarousel } from '@ngu/carousel';
import { UserService } from '../../user.service';
import { PowercheckerService } from '../../powerchecker.service';
var PowerCheckerDetails = /** @class */ (function () {
    function PowerCheckerDetails(_apiService, _mockData, _userService, _pcService) {
        this._apiService = _apiService;
        this._mockData = _mockData;
        this._userService = _userService;
        this._pcService = _pcService;
        this.title = '';
        this.location = '';
        this.editModeEnabled = false;
        this.links = ['https://play.google.com/store/apps/details?id=com.innogy.smarthome&hl=de',
            'https://apps.apple.com/de/app/innogy-smarthome/id1133163302'];
        this.plugAttributes = {
            name: '',
            room: ''
        };
        this.plugName = 'Mein Gerät';
        this.plugRoom = 'Mein Zimmer';
        this.state = false;
        this.slideshowAnimations = [
            {
                path: 'assets/anim/powerchecker/powerchecker_1.json',
                renderer: 'svg',
                autoplay: true,
                autoplaySegments: false,
                loop: true,
                name: 'Smart Meter'
            },
            {
                path: 'assets/anim/powerchecker/powerchecker_2.json',
                renderer: 'svg',
                autoplay: true,
                autoplaySegments: false,
                loop: true,
                name: 'Smart Meter'
            },
            {
                path: 'assets/anim/powerchecker/powerchecker_3.json',
                renderer: 'svg',
                autoplay: true,
                autoplaySegments: false,
                loop: true,
                name: 'Smart Meter'
            },
            {
                path: 'assets/anim/powerchecker/powerchecker_4.json',
                renderer: 'svg',
                autoplay: true,
                autoplaySegments: false,
                loop: true,
                name: 'Smart Meter'
            },
        ];
        this.carouselTileConfig = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            speed: 300,
            point: { visible: true },
            touch: true,
            loop: true,
            animation: 'lazy',
            interval: {
                timing: 10000, initialDelay: 10000
            },
            load: 4
        };
        this.subscriptions = [];
    }
    PowerCheckerDetails.prototype.ngOnInit = function () {
        var _this = this;
        this.getPlugAttributes();
        // this.initPlugAttributes();
        this._pcService.stateChange.subscribe(function (value) {
            _this.state = value;
        });
        if (!this._apiService.isDemoMode()) {
            this.initializePlugStatus();
            return;
        }
        this._mockData.getPlugStatus().subscribe(function (response) {
            _this.state = response.data.on_off === 1;
        });
    };
    PowerCheckerDetails.prototype.ngOnDestroy = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.subscriptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var sub = _c.value;
                if (sub) {
                    sub.unsubscribe();
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * Initialize the plugs attributes
     */
    PowerCheckerDetails.prototype.initPlugAttributes = function () {
        this.plugAttributes = this._userService.getPlugAttributes();
        if (this.plugAttributes === null || this.plugAttributes === undefined) {
            this.getPlugAttributes();
            return;
        }
        this.plugName = this.plugAttributes.name;
        this.plugRoom = this.plugAttributes.room;
    };
    /**
     * Get the plugs current set status
     */
    PowerCheckerDetails.prototype.initializePlugStatus = function () {
        var _this = this;
        var sub = this._apiService.getPlugStatus().subscribe(function (response) {
            if ('data' in response) {
                if ('relay' in response.data) {
                    if ('on_off' in response.data.relay) {
                        var value = response.data.relay.on_off;
                        _this.state = value === 1;
                    }
                }
            }
        });
        this.subscriptions.push(sub);
    };
    /**
     * Get plug attributes from user storage
     */
    PowerCheckerDetails.prototype.getPlugAttributes = function () {
        var _this = this;
        if (!this._apiService.isDemoMode()) {
            var sub = this._apiService.getStoredUserSettings().subscribe(function (response) {
                if ('data' in response) {
                    var obj = void 0;
                    if (typeof response.data === 'string') {
                        obj = JSON.parse(response.data);
                    }
                    else {
                        obj = response.data;
                    }
                    if ('power_checker' in response.data) {
                        _this._userService.updatePlugAttributes(obj);
                        _this.plugAttributes = obj.power_checker;
                        if (_this.plugAttributes.name !== '') {
                            _this.plugName = _this.plugAttributes.name;
                        }
                        if (_this.plugAttributes.room !== '') {
                            _this.plugRoom = _this.plugAttributes.room;
                        }
                    }
                }
            });
            this.subscriptions.push(sub);
        }
    };
    /**
     * On plug stat changed
     * @param state
     */
    PowerCheckerDetails.prototype.onPlugStateChange = function (state) {
        var _this = this;
        this.state = state;
        this._pcService.stateChange.next(state);
        if (!this._apiService.isDemoMode()) {
            this._apiService.setPlugStatus(state ? 1 : 0).subscribe(function (response) {
                if ('data' in response) {
                    if ('relay' in response.data) {
                        if ('on_off' in response.data) {
                            var value = response.data.relay.on_off;
                            _this.state = value === 1;
                        }
                    }
                }
            }, function (error) {
                console.log(_this.constructor.name, error);
            });
        }
    };
    /**
     * On saved change
     * @param new_name
     * @param new_room
     */
    PowerCheckerDetails.prototype.onSaveChanges = function (new_name, new_room) {
        if (new_name === '' || new_room === '') {
            this.editModeEnabled = !this.editModeEnabled;
            return;
        }
        if (this.editModeEnabled) {
            this._userService.plugAttributesChanged.next({ name: new_name, room: new_room });
            if (this._apiService.isDemoMode()) {
                if (new_name !== this.plugAttributes.name || new_room !== this.plugAttributes.room) {
                    this.plugName = new_name;
                    this.plugRoom = new_room;
                }
                this.editModeEnabled = !this.editModeEnabled;
                return;
            }
            if (new_name !== this.plugAttributes.name || new_room !== this.plugAttributes.room) {
                this._userService.updatePlugAttributes({ name: new_name, room: new_room });
                this._apiService.setPlugAttributes().subscribe(function (response) {
                }, function (error) {
                    console.log('Error:', error);
                });
            }
        }
        this.editModeEnabled = !this.editModeEnabled;
    };
    PowerCheckerDetails.prototype.onInputFocus = function (evt) {
        var target = evt.target;
        target.select();
    };
    return PowerCheckerDetails;
}());
export { PowerCheckerDetails };
