import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var PowercheckerService = /** @class */ (function () {
    function PowercheckerService() {
        this.stateChange = new Subject();
    }
    PowercheckerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PowercheckerService_Factory() { return new PowercheckerService(); }, token: PowercheckerService, providedIn: "root" });
    return PowercheckerService;
}());
export { PowercheckerService };
