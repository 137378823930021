import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Globals } from '../../services/globals.service';
import { MockDataService } from '../../mock-data.service';
import { flatMap, map } from 'rxjs/operators';
var ConsumptionAlertWidget = /** @class */ (function () {
    function ConsumptionAlertWidget(_apiService, _globals, mockData) {
        this._apiService = _apiService;
        this._globals = _globals;
        this.mockData = mockData;
        this.currentZone = 0;
        this.since = '';
        this.power = '';
        this.showSinceInfo = false;
        this.showPowerInfo = false;
        this.ZONE_HYPERACTIVE = 2;
        this.ZONE_IDLE = 0;
        this.ZONE_UNKNOWN = 4;
        this.dateFormat = 'DD.MM.YYYY';
        this.mockSubscriptions = [];
    }
    /**
     * Widget Verbrauchsalarm ist aktuell deaktiviert und ohne Funktion
     */
    ConsumptionAlertWidget.prototype.ngOnInit = function () {
        if (this._apiService.isDemoMode()) {
            this.getHomeStateMock();
            return;
        }
        this.getHomeState();
    };
    ConsumptionAlertWidget.prototype.ngOnDestroy = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.mockSubscriptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var element = _c.value;
                if (element) {
                    element.unsubscribe();
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ConsumptionAlertWidget.prototype.getHomeState = function () {
        var _this = this;
        var s = this._apiService.getHomeStateStatus().pipe(flatMap(function (status) {
            return _this._apiService.getHomeStateConfig().pipe(map(function (config) {
                return { status: status, config: config };
            }));
        })).subscribe(function (res) {
            var status = res.status;
            _this.handleHomeStateStatus(status.data);
            var config = res.config;
            _this.handleHomeStateConfig(config.data);
        });
        this.mockSubscriptions.push(s);
    };
    ConsumptionAlertWidget.prototype.getHomeStateMock = function () {
        var _this = this;
        var s = this.mockData.getHomeStateStatus().pipe(flatMap(function (status) {
            return _this.mockData.getHomeStateConfig().pipe(map(function (config) {
                return { status: status, config: config };
            }));
        })).subscribe(function (res) {
            var status = res.status;
            if (status.status === 'ok') {
                _this.handleHomeStateStatus(status.data);
            }
            var config = res.config;
            if (config.status === 'ok') {
                _this.handleHomeStateConfig(config.data);
            }
        });
        this.mockSubscriptions.push(s);
    };
    ConsumptionAlertWidget.prototype.handleHomeStateStatus = function (status) {
        try {
            // this.currentZone = Math.max(this.ZONE_IDLE, Math.min(this.ZONE_UNKNOWN, status.current_zone));
            //
            // let since = null;
            // if (this._apiService.isDemoMode()) {
            //     const app_expire_date = localStorage.getItem(constants.application.storage.keys.app_expire);
            //     const d = new Date(app_expire_date);
            //     since = moment(d).subtract(5, 'minutes');
            // } else {
            //     since = moment(new Date(status.since));
            // }
            //
            //
            //
            //
            //
            // const diff_from_now = moment().diff(since) / 1000;
            // if (diff_from_now <= 60) {
            //     this.since = 'seit gerade eben';
            // }
            // if (diff_from_now > 60 && diff_from_now <= 3600) {
            //     const mins = diff_from_now / 60;
            //     this.since = `seit ${Math.round(mins)} Minute${mins > 1 ? 'n' : ''}`;
            // }
            // if (diff_from_now > 3600 && diff_from_now <= 86400) {
            //     this.since = `seit ${Math.round(diff_from_now / 360)} Stunden`;
            // }
            // if (diff_from_now > 86400) {
            //     this.since = `seit ${since.format(this.dateFormat)}`;
            // }
            this.since = 'Maximum der letzten 7 Tage:';
            this.showSinceInfo = true;
        }
        catch (e) {
            console.log('Error', e);
        }
    };
    ConsumptionAlertWidget.prototype.handleHomeStateConfig = function (config) {
        console.log('config', config);
        try {
            if (config.thresholds.length < 1) {
                this.power = 0 + " Watt";
            }
            else {
                this.power = Math.round(config.thresholds.reduce(function (a, b) { return Math.max(a, b); })).toLocaleString('de') + " Watt";
            }
            this.showPowerInfo = true;
        }
        catch (e) {
            console.log('Error', e);
        }
    };
    ConsumptionAlertWidget.prototype.firstDetailViewAnalytics = function () {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Consumption-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    };
    return ConsumptionAlertWidget;
}());
export { ConsumptionAlertWidget };
