import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Angulartics2 } from 'angulartics2';
import { ApiService } from '../services/api.service';
import { OverlayService } from '../overlay/overlay.service';
import { UserService } from '../user.service';
import { getProviderIconClass, getProviderMenuName } from '../ProivderUtil';
import { objectIsEmpty } from '../Util';
import { forkJoin } from 'rxjs';
import { MockDataService } from '../mock-data.service';
import { constants, static_links } from '../constants';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(_titleService, _notification, _analytics, _apiService, overlay_service, _userService, mockData, renderer) {
        var _this = this;
        this._titleService = _titleService;
        this._notification = _notification;
        this._analytics = _analytics;
        this._apiService = _apiService;
        this.overlay_service = overlay_service;
        this._userService = _userService;
        this.mockData = mockData;
        this.renderer = renderer;
        this.refresh = null;
        this.nilm_refresh = null;
        // Sortierung der Dashboard-Widgets
        this.sort_options = {
            onUpdate: function (event) {
                _this._userService.setActiveUserCharts(_this.charts_selected);
                var card = _this.charts.filter(function (item) { return item.id === _this.charts_selected[event.newIndex].id; })[0].name;
                _this._analytics.eventTrack.next({
                    action: 'dashboard_layout_change',
                    properties: {
                        category: 'Dashboard',
                        label: 'type: move; card: ' + card + '; position: ' + (event.newIndex + 1) + '; previous_position: ' + (event.oldIndex + 1)
                    }
                });
            }
        };
        this.providerclass = ''; // innogy/enviaM/...
        this.providername = '';
        this.providerMenuName = null;
        this.privacyLink = '';
        this.username = null;
        this.userHasPlug = false;
        this.userPlugAttributes = null;
        /**
         * Alle auswählbareb Charts
         */
        this.charts = [
            {
                id: 'live',
                name: 'Live',
                info: 'Anzeige Ihres Stromverbrauchs in Echtzeit'
            },
            {
                id: 'today',
                name: 'Heute',
                info: 'Ihr heutiger Stromverbrauch und die entstandenen Kosten im Überblick'
            },
            {
                id: 'consumer',
                name: 'Meine Geräte',
                info: 'Anzeige des Verbrauchs einzelner Geräte in Ihrem Haushalt und der dabei entstandenen Kosten'
            },
            {
                id: 'compare',
                name: 'Vergleich',
                info: 'Verbrauchsstatistik und Analysen'
            },
            {
                id: 'meter',
                name: 'Zählerstand',
                info: 'Anzeige Ihres Zählerstands'
            },
            {
                id: 'finance',
                name: 'Abschlags-Check',
                info: 'Gutschrift- oder Nachzahlungsprognose'
            },
            {
                id: 'consumption-alert',
                name: 'Verbrauchs-Alarm',
                info: 'Alarmfunktion bei hohem Stromverbrauch'
            }
            // {id: 'powerchecker', name: 'PowerChecker', info: 'Innogy PowerChecker'}
        ];
        /**
         * Verfügbare Charts
         */
        this.charts_available = [
        // {id: 'live'}, {id: 'today'}, {id: 'consumer'}, {id: 'compare'}, {id: 'meter'}, {id: 'finance'}
        ];
        /**
         * Ausgewählte Charts / Default ausgewählte Charts
         */
        this.charts_selected = [
        // { id: 'live' }, { id: 'today' }, { id: 'consumer' }, { id: 'compare' }, { id: 'meter' }, { id: 'finance' }, { id: 'consumption-alert' }
        ];
        this.version = '0.0.0';
        this.changelog = [];
        this.logs = false;
        this.last_popup_shown = null;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        history.pushState({ 'isDashboard': true }, 'Dashboard', '/#/');
        this._titleService.setTitle('Übersicht | iONA');
        this.username = this._userService.getActiveUserName();
        this.providername = this._userService.getActiveUserProvider();
        this.providerclass = getProviderIconClass(this.providername);
        this.privacyLink = static_links.privacy[this.providername];
        this.providerMenuName = getProviderMenuName(this.providername);
        /**
         * initialize the overlay to use the correct configuration
         */
        this.setupOverlay();
        this.initCharts();
        this.getVersionChangelog();
        this.onVisibilityFn = this.renderer.listen('document', 'visibilitychange', function () {
            if (!document.hidden) {
                if (_this._apiService.isDemoMode()) {
                    _this.checkMockNilmStatus();
                    return;
                }
                _this.checkNilmStatus();
            }
        });
        if (this._apiService.isDemoMode()) {
            this.checkMockNilmStatus();
            this.getMockRegistrationModel();
            return;
        }
        this._apiService.checkToken();
        this._apiService.checkMeterStatus();
        this.checkNilmStatus();
        this.getRegistrationModel();
        /**
         * Wiederkehrende Prüfung, ob der Nutzer eingeloggt ist
         */
        this.refresh = setInterval(function () {
            _this._apiService.checkToken();
        }, 10000);
        this.nilm_refresh = setInterval(function () {
            _this.checkNilmStatus();
        }, 300000);
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        if (this.refresh) {
            clearInterval(this.refresh);
        }
        if (this.nilm_refresh) {
            clearInterval(this.nilm_refresh);
        }
        if (this.onVisibilityFn) {
            this.onVisibilityFn();
        }
    };
    /**
     * Check the NILM status and display an overlay if something changed
     */
    DashboardComponent.prototype.checkNilmStatus = function () {
        var _this = this;
        var profile_sub = this._apiService.getProfile();
        var nilm_sub = this._apiService.getNilmStatus();
        forkJoin([profile_sub, nilm_sub]).subscribe(function (responses) {
            var profile_data = responses[0];
            var nilm_data = responses[1];
            if (profile_data['status'] === 'ok' && 'data' in profile_data) {
                if (nilm_data['status'] === 'ok' && 'data' in nilm_data) {
                    _this.determineNewlyAddedAppliances(nilm_data, profile_data);
                }
            }
        }, function (error) {
            console.log(error);
        });
    };
    /**
     * Mock test
     */
    DashboardComponent.prototype.checkMockNilmStatus = function () {
        var _this = this;
        var profile_sub = this.mockData.getProfileAttributes();
        var nilm_sub = this.mockData.getNILMStatus();
        forkJoin([profile_sub, nilm_sub]).subscribe(function (responses) {
            var profile_data = responses[0];
            var nilm_data = responses[1];
            if (profile_data['status'] === 'ok' && 'data' in profile_data) {
                if (nilm_data['status'] === 'ok' && 'data' in nilm_data) {
                    _this.determineNewlyAddedAppliances(nilm_data, profile_data);
                }
            }
        }, function (error) {
            console.log(error);
        });
    };
    DashboardComponent.prototype.getMockRegistrationModel = function () {
        var _this = this;
        this.mockData.getRegistrationModel().subscribe(function (response) {
            if ('data' in response) {
                if ('model_identifier' in response.data) {
                    _this.handlePlugResponse(response);
                }
            }
        });
    };
    DashboardComponent.prototype.getRegistrationModel = function () {
        var _this = this;
        this._apiService.getRegistrationModel().subscribe(function (response) {
            if ('data' in response) {
                if ('model_identifier' in response.data) {
                    _this.handlePlugResponse(response);
                }
            }
        });
    };
    /**
     * Get current version
     */
    DashboardComponent.prototype.getVersionChangelog = function () {
        var _this = this;
        this._apiService.getChangelog().subscribe(function (data) {
            if (0 in data) {
                _this.version = data[0].version;
            }
            _this.changelog = data;
        }, function (error) {
            console.log(error);
        });
    };
    DashboardComponent.prototype.handlePlugResponse = function (response) {
        var model = response.data.model_identifier;
        switch (model) {
            case constants.application.devices.plug:
                this._userService.updateUserDevice(constants.application.devices.plug);
                this.userHasPlug = true;
                break;
            case constants.application.devices.box:
                this._userService.updateUserDevice(constants.application.devices.box);
                break;
        }
        if (this.userHasPlug) {
            var p = { id: 'powerchecker', name: 'PowerChecker', info: 'Schalten Ihres PowerCheckers' };
            this.charts.splice(1, 0, p);
            console.log(this.charts);
            this.charts_available = [];
            this.charts_selected = [];
            this.initCharts();
        }
    };
    /**
     * Initialize available charts
     */
    DashboardComponent.prototype.initCharts = function () {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e;
        try {
            for (var _f = tslib_1.__values(this.charts), _g = _f.next(); !_g.done; _g = _f.next()) {
                var chart = _g.value;
                this.charts_available.push({
                    id: chart.id
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_a = _f.return)) _a.call(_f);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (this._apiService.isDemoMode()) {
            this.charts_selected = [{ id: 'live' }, { id: 'powerchecker' }, { id: 'today' }, { id: 'consumer' }, { id: 'compare' }, { id: 'meter' }, { id: 'finance' }, { id: 'consumption-alert' }];
            var _loop_1 = function (chart) {
                this_1.charts_available = this_1.charts_available.filter(function (item) { return item.id !== chart.id; });
            };
            var this_1 = this;
            try {
                for (var _h = tslib_1.__values(this.charts_selected), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var chart = _j.value;
                    _loop_1(chart);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_b = _h.return)) _b.call(_h);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else {
            var charts = this._userService.getActiveUserCharts();
            if (charts === null || charts === undefined) {
                this.charts_selected = [{ id: 'live' }, { id: 'today' }, { id: 'consumer' }, { id: 'compare' }, { id: 'meter' }, { id: 'finance' }, { id: 'consumption-alert' }];
                if (this.userHasPlug) {
                    this.charts_selected = [{ id: 'live' }, { id: 'powerchecker' }, { id: 'today' }, { id: 'consumer' }, { id: 'compare' }, { id: 'meter' }, { id: 'finance' }];
                }
                var _loop_2 = function (chart) {
                    this_2.charts_available = this_2.charts_available.filter(function (item) { return item.id !== chart.id; });
                };
                var this_2 = this;
                try {
                    for (var _k = tslib_1.__values(this.charts_selected), _l = _k.next(); !_l.done; _l = _k.next()) {
                        var chart = _l.value;
                        _loop_2(chart);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_l && !_l.done && (_c = _k.return)) _c.call(_k);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                this._userService.setConsumptionAlertAttribute(false);
                this._userService.setActiveUserCharts(this.charts_selected);
            }
            else if (objectIsEmpty(charts)) {
                var consumption_alert_attr = this._userService.getConsumptionAlertAttribute();
                var changes_made = false;
                if (consumption_alert_attr === null || consumption_alert_attr === undefined) {
                    console.log('no attribute');
                    this.charts_selected.push({ id: 'consumption-alert' });
                    this._userService.setConsumptionAlertAttribute(false);
                    changes_made = true;
                }
                else if (consumption_alert_attr) {
                    console.log('is attribute');
                    this.charts_selected.push({ id: 'consumption-alert' });
                    this._userService.setConsumptionAlertAttribute(false);
                    changes_made = true;
                }
                var _loop_3 = function (chart) {
                    this_3.charts_available = this_3.charts_available.filter(function (item) { return item.id !== chart.id; });
                };
                var this_3 = this;
                try {
                    for (var _m = tslib_1.__values(this.charts_selected), _o = _m.next(); !_o.done; _o = _m.next()) {
                        var chart = _o.value;
                        _loop_3(chart);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_o && !_o.done && (_d = _m.return)) _d.call(_m);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                if (changes_made) {
                    this._userService.setActiveUserCharts(this.charts_selected);
                }
            }
            else {
                if (charts.length > 0) {
                    this.charts_selected = charts;
                    // todo here check things man consumption alter pilot
                    var changes_made = false;
                    var consumption_alert_attr = this._userService.getConsumptionAlertAttribute();
                    if (consumption_alert_attr === null || consumption_alert_attr === undefined) {
                        console.log('no attribute');
                        this.charts_selected.push({ id: 'consumption-alert' });
                        this._userService.setConsumptionAlertAttribute(false);
                        changes_made = true;
                    }
                    else if (consumption_alert_attr) {
                        console.log('is attribute');
                        var found = this.charts_selected.find(function (el) { return el.id === 'consumption-alert'; });
                        if (!found) {
                            this.charts_selected.push({ id: 'consumption-alert' });
                        }
                        this._userService.setConsumptionAlertAttribute(false);
                        changes_made = true;
                    }
                    var _loop_4 = function (chart) {
                        this_4.charts_available = this_4.charts_available.filter(function (item) { return item.id !== chart.id; });
                    };
                    var this_4 = this;
                    try {
                        for (var _p = tslib_1.__values(this.charts_selected), _q = _p.next(); !_q.done; _q = _p.next()) {
                            var chart = _q.value;
                            _loop_4(chart);
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (_q && !_q.done && (_e = _p.return)) _e.call(_p);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                    if (changes_made) {
                        this._userService.setActiveUserCharts(this.charts_selected);
                    }
                }
            }
        }
    };
    /**
     * Setup the appearing overlay
     */
    DashboardComponent.prototype.setupOverlay = function () {
        var _this = this;
        this.overlay_service.onConfirm.subscribe(function (result) {
            _this._userService.updateActiveUserNilmStatusForAppliance(result.tag, result.amount);
            var change = { Appliance: {} };
            change.Appliance[result.id] = result.amount;
            if (!_this._apiService.isDemoMode()) {
                _this._apiService.setProfile(change);
            }
        });
    };
    /**
     * Fit
     * @param data
     * @param device_map
     */
    DashboardComponent.prototype.filterNilmDeviceModelCount = function (data, device_map) {
        var e_6, _a, e_7, _b;
        var result = {
            timeBasedAppliances: {
                dishWasher: null,
                dryer: null,
                oven: null,
                washingMachine: null
            }
        };
        try {
            for (var device_map_1 = tslib_1.__values(device_map), device_map_1_1 = device_map_1.next(); !device_map_1_1.done; device_map_1_1 = device_map_1.next()) {
                var category = device_map_1_1.value;
                var response_category = data[category.key];
                if (response_category === null || response_category === undefined) {
                    continue;
                }
                try {
                    for (var _c = tslib_1.__values(category.elements), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var dev = _d.value;
                        var value = response_category[dev].models;
                        if (value === null || value === undefined) {
                            continue;
                        }
                        result[category.key][dev] = value;
                    }
                }
                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_7) throw e_7.error; }
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (device_map_1_1 && !device_map_1_1.done && (_a = device_map_1.return)) _a.call(device_map_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        return result;
    };
    /**
     *
     */
    DashboardComponent.prototype.determineNewlyAddedAppliances = function (response, profile_data) {
        var old_nilm = this._userService.getActiveUserNilmStatus();
        if (old_nilm === null || old_nilm === undefined) {
            this._userService.updateActiveUserNilmStatus(response.data);
            return;
        }
        // setup nilm_devices filter
        var nilm_devices = [{
                key: 'timeBasedAppliances',
                elements: ['dishWasher', 'washingMachine', 'dryer', 'oven']
            }];
        var new_amounts = this.filterNilmDeviceModelCount(response.data, nilm_devices);
        var old_amounts = this.filterNilmDeviceModelCount(old_nilm, nilm_devices);
        for (var key in new_amounts) {
            if (!new_amounts.hasOwnProperty(key)) {
                continue;
            }
            var parent_old = old_amounts[key];
            var parent_new = new_amounts[key];
            for (var inner_key in parent_new) {
                if (parent_old[inner_key] === 0) {
                    console.log('log', inner_key, parent_new[inner_key]);
                    console.log('log', inner_key, response.data['timeBasedAppliances'][inner_key].profileComplete);
                    if (parent_new[inner_key] !== 0 && response.data['timeBasedAppliances'][inner_key].profileComplete === false) {
                        var config = {
                            title: 'iONA hat ein neues Gerät erkannt',
                            info: 'Wie viele # gibt es in Ihrem Haushalt?',
                            icon: null,
                            amount: -1,
                            tag: inner_key,
                            appliance: null
                        };
                        switch (inner_key) {
                            case 'washingMachine':
                                config.icon = 'A.11';
                                config.appliance = 'Waschmaschinen';
                                config.amount = profile_data.data.Appliances['A.11'];
                                break;
                            case 'dishWasher':
                                config.icon = 'A.10';
                                config.appliance = 'Spülmaschinen';
                                config.amount = profile_data.data.Appliances['A.10'];
                                break;
                            case 'dryer':
                                config.icon = 'A.12';
                                config.appliance = 'Trockner';
                                config.amount = profile_data.data.Appliances['A.12'];
                                break;
                            case 'oven':
                                config.icon = 'A.04';
                                config.appliance = 'Öfen';
                                config.amount = profile_data.data.Appliances['A.04'];
                                break;
                        }
                        console.log('before visibility check');
                        if (document.visibilityState === 'visible') {
                            this.overlay_service.initialize(config);
                            this.overlay_service.showOverlay(true);
                            this._userService.updateActiveUserNilmStatusForAppliance(inner_key, new_amounts.timeBasedAppliances[inner_key]);
                        }
                        else {
                            this.last_popup_shown = new Date();
                        }
                        return;
                    }
                }
            }
        }
    };
    /**
     * Neues Chart dem Dashboard zufügen
     *
     * @param id
     */
    DashboardComponent.prototype.addChart = function (id) {
        if (this.charts_available.filter(function (item) { return item.id === id; }).length > 0) {
            this.charts_available = this.charts_available.filter(function (item) { return item.id !== id; });
            this.charts_selected.push({ id: id });
            this._userService.setActiveUserCharts(this.charts_selected);
            this._notification.success('Der Bereich wurde der Übersicht hinzugefügt!');
        }
    };
    /**
     * Chart vom Dashboard entfernen
     * @param id
     */
    DashboardComponent.prototype.removeChart = function (id) {
        if (this.charts_selected.filter(function (item) { return item.id === id; }).length > 0) {
            this.charts_selected = this.charts_selected.filter(function (item) { return item.id !== id; });
            this.charts_available.push({
                id: id
            });
            // this._apiService.setCharts(this.charts_selected);
            this._userService.setActiveUserCharts(this.charts_selected);
            this._analytics.eventTrack.next({
                action: 'dashboard_layout_change',
                properties: {
                    category: 'Dashboard',
                    label: 'type: delete; card: ' + this.charts.filter(function (item) { return item.id === id; })[0].name
                }
            });
        }
    };
    /**
     * Wird dieses Chart auf dem Dashboard angezeigt?
     * @param id
     */
    DashboardComponent.prototype.isSelectedChart = function (id) {
        return this.charts_selected.filter(function (item) { return item.id === id; }).length;
    };
    /**
     * Get Chart name
     * @param id
     */
    DashboardComponent.prototype.getChartName = function (id) {
        var chart = this.charts.filter(function (item) { return item.id === id; });
        if (chart.length > 0) {
            return chart[0].name;
        }
        else {
            return null;
        }
    };
    /**
     * Show Changelog
     */
    DashboardComponent.prototype.showChangelog = function () {
        this.logs = true;
    };
    DashboardComponent.prototype.hideChangelog = function () {
        this.logs = false;
    };
    DashboardComponent.prototype.onVisibilityChange = function () {
        console.log(this);
    };
    return DashboardComponent;
}());
export { DashboardComponent };
