import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var UserService = /** @class */ (function () {
    function UserService() {
        this.storage_keys = {
            users: 'users',
            user_active: 'user-active',
        };
        this.plugAttributesChanged = new Subject();
    }
    UserService.prototype.setCurrentUser = function (user) {
        var e_1, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        var users_obj;
        if (users_str === null || users_str === undefined) {
            users_obj = [];
            // set initial value of consumption alert to false
            user.initial_consumption_alert = true;
            user.charts = null;
            users_obj.push(user);
            localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
        }
        else {
            users_obj = JSON.parse(users_str);
            var user_exists = false;
            try {
                for (var users_obj_1 = tslib_1.__values(users_obj), users_obj_1_1 = users_obj_1.next(); !users_obj_1_1.done; users_obj_1_1 = users_obj_1.next()) {
                    var u = users_obj_1_1.value;
                    if (u.email === user.email) {
                        u.access_token = user.access_token;
                        u.refresh_token = user.refresh_token;
                        u.token_expires = user.token_expires;
                        user_exists = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (users_obj_1_1 && !users_obj_1_1.done && (_a = users_obj_1.return)) _a.call(users_obj_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (!user_exists) {
                users_obj.push(user);
            }
            localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
        }
        this.setActiveUser(user.email);
    };
    UserService.prototype.getUser = function (email) {
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        return users_obj.find(function (user) { return user.email === email; });
    };
    UserService.prototype.getActiveUserName = function () {
        return this.getElementForKey(this.storage_keys.user_active);
    };
    UserService.prototype.setActiveUserProvider = function (provider) {
        var e_2, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_2 = tslib_1.__values(users_obj), users_obj_2_1 = users_obj_2.next(); !users_obj_2_1.done; users_obj_2_1 = users_obj_2.next()) {
                var user = users_obj_2_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.provider = provider;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (users_obj_2_1 && !users_obj_2_1.done && (_a = users_obj_2.return)) _a.call(users_obj_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getActiveUserProvider = function () {
        var e_3, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_3 = tslib_1.__values(users_obj), users_obj_3_1 = users_obj_3.next(); !users_obj_3_1.done; users_obj_3_1 = users_obj_3.next()) {
                var user = users_obj_3_1.value;
                if (user.email === this.getActiveUserName()) {
                    return user.provider.toLowerCase();
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (users_obj_3_1 && !users_obj_3_1.done && (_a = users_obj_3.return)) _a.call(users_obj_3);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return null;
    };
    UserService.prototype.setActiveUserCharts = function (charts) {
        var e_4, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_4 = tslib_1.__values(users_obj), users_obj_4_1 = users_obj_4.next(); !users_obj_4_1.done; users_obj_4_1 = users_obj_4.next()) {
                var user = users_obj_4_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.charts = charts;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (users_obj_4_1 && !users_obj_4_1.done && (_a = users_obj_4.return)) _a.call(users_obj_4);
            }
            finally { if (e_4) throw e_4.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getActiveUserCharts = function () {
        var e_5, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_5 = tslib_1.__values(users_obj), users_obj_5_1 = users_obj_5.next(); !users_obj_5_1.done; users_obj_5_1 = users_obj_5.next()) {
                var user = users_obj_5_1.value;
                if (user.email === this.getActiveUserName()) {
                    return user.charts;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (users_obj_5_1 && !users_obj_5_1.done && (_a = users_obj_5.return)) _a.call(users_obj_5);
            }
            finally { if (e_5) throw e_5.error; }
        }
        return null;
    };
    UserService.prototype.getActiveUserAccessToken = function () {
        var e_6, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_6 = tslib_1.__values(users_obj), users_obj_6_1 = users_obj_6.next(); !users_obj_6_1.done; users_obj_6_1 = users_obj_6.next()) {
                var user = users_obj_6_1.value;
                if (user.email === this.getActiveUserName()) {
                    var token = user.access_token;
                    return token;
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (users_obj_6_1 && !users_obj_6_1.done && (_a = users_obj_6.return)) _a.call(users_obj_6);
            }
            finally { if (e_6) throw e_6.error; }
        }
        return null;
    };
    UserService.prototype.updateActiveUserAccessToken = function (access_token) {
        var e_7, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_7 = tslib_1.__values(users_obj), users_obj_7_1 = users_obj_7.next(); !users_obj_7_1.done; users_obj_7_1 = users_obj_7.next()) {
                var user = users_obj_7_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.access_token = access_token;
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (users_obj_7_1 && !users_obj_7_1.done && (_a = users_obj_7.return)) _a.call(users_obj_7);
            }
            finally { if (e_7) throw e_7.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getActiveUserRefreshToken = function () {
        var e_8, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_8 = tslib_1.__values(users_obj), users_obj_8_1 = users_obj_8.next(); !users_obj_8_1.done; users_obj_8_1 = users_obj_8.next()) {
                var user = users_obj_8_1.value;
                if (user.email === this.getActiveUserName()) {
                    return user.refresh_token;
                }
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (users_obj_8_1 && !users_obj_8_1.done && (_a = users_obj_8.return)) _a.call(users_obj_8);
            }
            finally { if (e_8) throw e_8.error; }
        }
        return null;
    };
    UserService.prototype.updateActiveUserRefreshToken = function (refresh_token) {
        var e_9, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_9 = tslib_1.__values(users_obj), users_obj_9_1 = users_obj_9.next(); !users_obj_9_1.done; users_obj_9_1 = users_obj_9.next()) {
                var user = users_obj_9_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.refresh_token = refresh_token;
                }
            }
        }
        catch (e_9_1) { e_9 = { error: e_9_1 }; }
        finally {
            try {
                if (users_obj_9_1 && !users_obj_9_1.done && (_a = users_obj_9.return)) _a.call(users_obj_9);
            }
            finally { if (e_9) throw e_9.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getActiveUserTokenExpire = function () {
        var e_10, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_10 = tslib_1.__values(users_obj), users_obj_10_1 = users_obj_10.next(); !users_obj_10_1.done; users_obj_10_1 = users_obj_10.next()) {
                var user = users_obj_10_1.value;
                if (user.email === this.getActiveUserName()) {
                    return user.token_expires;
                }
            }
        }
        catch (e_10_1) { e_10 = { error: e_10_1 }; }
        finally {
            try {
                if (users_obj_10_1 && !users_obj_10_1.done && (_a = users_obj_10.return)) _a.call(users_obj_10);
            }
            finally { if (e_10) throw e_10.error; }
        }
        return null;
    };
    UserService.prototype.updateActiveUserTokenExpire = function (new_expire) {
        var e_11, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        var expires;
        try {
            for (var users_obj_11 = tslib_1.__values(users_obj), users_obj_11_1 = users_obj_11.next(); !users_obj_11_1.done; users_obj_11_1 = users_obj_11.next()) {
                var user = users_obj_11_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.token_expires = new_expire;
                }
            }
        }
        catch (e_11_1) { e_11 = { error: e_11_1 }; }
        finally {
            try {
                if (users_obj_11_1 && !users_obj_11_1.done && (_a = users_obj_11.return)) _a.call(users_obj_11);
            }
            finally { if (e_11) throw e_11.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.logoutActiveUser = function () {
        var e_12, _a;
        this.removeActiveUser();
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return;
        }
        var users = JSON.parse(users_str);
        try {
            for (var users_1 = tslib_1.__values(users), users_1_1 = users_1.next(); !users_1_1.done; users_1_1 = users_1.next()) {
                var user = users_1_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.refresh_token = '';
                    user.access_token = '';
                }
            }
        }
        catch (e_12_1) { e_12 = { error: e_12_1 }; }
        finally {
            try {
                if (users_1_1 && !users_1_1.done && (_a = users_1.return)) _a.call(users_1);
            }
            finally { if (e_12) throw e_12.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users));
    };
    UserService.prototype.getActiveUserNilmStatus = function () {
        var e_13, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_12 = tslib_1.__values(users_obj), users_obj_12_1 = users_obj_12.next(); !users_obj_12_1.done; users_obj_12_1 = users_obj_12.next()) {
                var user = users_obj_12_1.value;
                if (user.email === this.getActiveUserName()) {
                    return user.nilm_status;
                }
            }
        }
        catch (e_13_1) { e_13 = { error: e_13_1 }; }
        finally {
            try {
                if (users_obj_12_1 && !users_obj_12_1.done && (_a = users_obj_12.return)) _a.call(users_obj_12);
            }
            finally { if (e_13) throw e_13.error; }
        }
        return null;
    };
    UserService.prototype.updateActiveUserNilmStatus = function (nilm_status) {
        var e_14, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_13 = tslib_1.__values(users_obj), users_obj_13_1 = users_obj_13.next(); !users_obj_13_1.done; users_obj_13_1 = users_obj_13.next()) {
                var user = users_obj_13_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.nilm_status = nilm_status;
                }
            }
        }
        catch (e_14_1) { e_14 = { error: e_14_1 }; }
        finally {
            try {
                if (users_obj_13_1 && !users_obj_13_1.done && (_a = users_obj_13.return)) _a.call(users_obj_13);
            }
            finally { if (e_14) throw e_14.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.updateActiveUserNilmStatusForAppliance = function (appliance, model_count) {
        var e_15, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        var nilm_values;
        try {
            for (var users_obj_14 = tslib_1.__values(users_obj), users_obj_14_1 = users_obj_14.next(); !users_obj_14_1.done; users_obj_14_1 = users_obj_14.next()) {
                var user = users_obj_14_1.value;
                if (user.email === this.getActiveUserName()) {
                    nilm_values = user.nilm_status;
                }
            }
        }
        catch (e_15_1) { e_15 = { error: e_15_1 }; }
        finally {
            try {
                if (users_obj_14_1 && !users_obj_14_1.done && (_a = users_obj_14.return)) _a.call(users_obj_14);
            }
            finally { if (e_15) throw e_15.error; }
        }
        nilm_values['timeBasedAppliances'][appliance].models = model_count;
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getLocalKeyValueStore = function () {
        var e_16, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_15 = tslib_1.__values(users_obj), users_obj_15_1 = users_obj_15.next(); !users_obj_15_1.done; users_obj_15_1 = users_obj_15.next()) {
                var user = users_obj_15_1.value;
                if (user.email === this.getActiveUserName()) {
                    return user.storage;
                }
            }
        }
        catch (e_16_1) { e_16 = { error: e_16_1 }; }
        finally {
            try {
                if (users_obj_15_1 && !users_obj_15_1.done && (_a = users_obj_15.return)) _a.call(users_obj_15);
            }
            finally { if (e_16) throw e_16.error; }
        }
        return null;
    };
    UserService.prototype.getPlugAttributes = function () {
        var e_17, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_16 = tslib_1.__values(users_obj), users_obj_16_1 = users_obj_16.next(); !users_obj_16_1.done; users_obj_16_1 = users_obj_16.next()) {
                var user = users_obj_16_1.value;
                if (user.email === this.getActiveUserName()) {
                    if (user.storage === null) {
                        return null;
                    }
                    if (user.storage.power_checker === null) {
                        return null;
                    }
                    return user.storage.power_checker;
                }
            }
        }
        catch (e_17_1) { e_17 = { error: e_17_1 }; }
        finally {
            try {
                if (users_obj_16_1 && !users_obj_16_1.done && (_a = users_obj_16.return)) _a.call(users_obj_16);
            }
            finally { if (e_17) throw e_17.error; }
        }
        return null;
    };
    UserService.prototype.updatePlugAttributes = function (attributes) {
        var e_18, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_17 = tslib_1.__values(users_obj), users_obj_17_1 = users_obj_17.next(); !users_obj_17_1.done; users_obj_17_1 = users_obj_17.next()) {
                var user = users_obj_17_1.value;
                if (user.email === this.getActiveUserName()) {
                    if (user.storage === null) {
                        user.storage = {};
                    }
                    user.storage.power_checker = attributes;
                }
            }
        }
        catch (e_18_1) { e_18 = { error: e_18_1 }; }
        finally {
            try {
                if (users_obj_17_1 && !users_obj_17_1.done && (_a = users_obj_17.return)) _a.call(users_obj_17);
            }
            finally { if (e_18) throw e_18.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.deleteAccessToken = function () {
        var e_19, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_18 = tslib_1.__values(users_obj), users_obj_18_1 = users_obj_18.next(); !users_obj_18_1.done; users_obj_18_1 = users_obj_18.next()) {
                var user = users_obj_18_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.access_token = null;
                }
            }
        }
        catch (e_19_1) { e_19 = { error: e_19_1 }; }
        finally {
            try {
                if (users_obj_18_1 && !users_obj_18_1.done && (_a = users_obj_18.return)) _a.call(users_obj_18);
            }
            finally { if (e_19) throw e_19.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getUserDevice = function () {
        var e_20, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_19 = tslib_1.__values(users_obj), users_obj_19_1 = users_obj_19.next(); !users_obj_19_1.done; users_obj_19_1 = users_obj_19.next()) {
                var user = users_obj_19_1.value;
                if (user.email === this.getActiveUserName()) {
                    if (user.device === null || user.device === undefined) {
                        return null;
                    }
                    return user.device;
                }
            }
        }
        catch (e_20_1) { e_20 = { error: e_20_1 }; }
        finally {
            try {
                if (users_obj_19_1 && !users_obj_19_1.done && (_a = users_obj_19.return)) _a.call(users_obj_19);
            }
            finally { if (e_20) throw e_20.error; }
        }
        return null;
    };
    UserService.prototype.updateUserDevice = function (device) {
        var e_21, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_20 = tslib_1.__values(users_obj), users_obj_20_1 = users_obj_20.next(); !users_obj_20_1.done; users_obj_20_1 = users_obj_20.next()) {
                var user = users_obj_20_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.device = device;
                }
            }
        }
        catch (e_21_1) { e_21 = { error: e_21_1 }; }
        finally {
            try {
                if (users_obj_20_1 && !users_obj_20_1.done && (_a = users_obj_20.return)) _a.call(users_obj_20);
            }
            finally { if (e_21) throw e_21.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.getConsumptionAlertAttribute = function () {
        var e_22, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_21 = tslib_1.__values(users_obj), users_obj_21_1 = users_obj_21.next(); !users_obj_21_1.done; users_obj_21_1 = users_obj_21.next()) {
                var user = users_obj_21_1.value;
                if (user.email === this.getActiveUserName()) {
                    if (user.initial_consumption_alert === null || user.initial_consumption_alert === undefined) {
                        return null;
                    }
                    return user.initial_consumption_alert;
                }
            }
        }
        catch (e_22_1) { e_22 = { error: e_22_1 }; }
        finally {
            try {
                if (users_obj_21_1 && !users_obj_21_1.done && (_a = users_obj_21.return)) _a.call(users_obj_21);
            }
            finally { if (e_22) throw e_22.error; }
        }
        return null;
    };
    UserService.prototype.setConsumptionAlertAttribute = function (val) {
        var e_23, _a;
        var users_str = this.getElementForKey(this.storage_keys.users);
        if (users_str === null || users_str === undefined) {
            return null;
        }
        var users_obj = JSON.parse(users_str);
        try {
            for (var users_obj_22 = tslib_1.__values(users_obj), users_obj_22_1 = users_obj_22.next(); !users_obj_22_1.done; users_obj_22_1 = users_obj_22.next()) {
                var user = users_obj_22_1.value;
                if (user.email === this.getActiveUserName()) {
                    user.initial_consumption_alert = val;
                }
            }
        }
        catch (e_23_1) { e_23 = { error: e_23_1 }; }
        finally {
            try {
                if (users_obj_22_1 && !users_obj_22_1.done && (_a = users_obj_22.return)) _a.call(users_obj_22);
            }
            finally { if (e_23) throw e_23.error; }
        }
        localStorage.setItem(this.storage_keys.users, JSON.stringify(users_obj));
    };
    UserService.prototype.setActiveUser = function (email) {
        localStorage.setItem(this.storage_keys.user_active, email);
    };
    UserService.prototype.removeActiveUser = function () {
        localStorage.removeItem(this.storage_keys.user_active);
    };
    UserService.prototype.getElementForKey = function (key) {
        return localStorage.getItem(key);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
