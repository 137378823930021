import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var OverlayService = /** @class */ (function () {
    function OverlayService() {
        this.onShow = new Subject();
        this.onInit = new Subject();
        this.onConfirm = new Subject();
        this.closeOverlaySub = new Subject();
    }
    OverlayService.prototype.initialize = function (config) {
        this.onInit.next(config);
    };
    OverlayService.prototype.showOverlay = function (show) {
        this.onShow.next(show);
    };
    OverlayService.prototype.closeOverlay = function () {
        this.closeOverlaySub.next();
    };
    OverlayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OverlayService_Factory() { return new OverlayService(); }, token: OverlayService, providedIn: "root" });
    return OverlayService;
}());
export { OverlayService };
