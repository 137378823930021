import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ApiService } from '../../services/api.service';
import { Globals } from '../../services/globals.service';
import { MockDataService } from '../../mock-data.service';
var ConsumerWidget = /** @class */ (function () {
    function ConsumerWidget(_apiService, _globals, _mockData) {
        this._apiService = _apiService;
        this._globals = _globals;
        this._mockData = _mockData;
        this.monthname = null;
        this.colors = ['#C81E82', '#780A5F', '#808080', '#CCCCCC'];
        this.color = 0;
        this.tempdata = [];
        this.chart = {};
        this.consumer = [];
        this.showConsumers = true;
        this.showDiagrams = true;
        this.nilmdata = null;
    }
    ConsumerWidget.prototype.ngOnInit = function () {
        this.monthname = this._apiService.getMonthname(0);
        this.initializeChart();
        if (this._apiService.isDemoMode()) {
            this.getMockElectricalAppliances();
            this.getMockNilmStatus();
            return;
        }
        this.getElectricalApplicances();
        this.getNilmStatus();
    };
    /**
     * Get Demo Mock data
     */
    ConsumerWidget.prototype.getMockElectricalAppliances = function () {
        var _this = this;
        this._mockData.getElectricalAppliances(0).subscribe(function (data) {
            if ('data' in data) {
                _this.drawDiagram(data);
            }
            else {
                _this.showDiagrams = false;
            }
        });
    };
    ConsumerWidget.prototype.getElectricalApplicances = function () {
        var _this = this;
        this._apiService.getElectricalAppliances(0).subscribe(function (data) {
            if ('data' in data) {
                _this.drawDiagram(data);
            }
            else {
                _this.showDiagrams = false;
            }
        }, function () {
            _this.showDiagrams = false;
        });
    };
    ConsumerWidget.prototype.getMockNilmStatus = function () {
        var _this = this;
        this._mockData.getNILMStatus().subscribe(function (data) {
            if ('data' in data) {
                _this.nilmdata = data.data;
            }
        });
    };
    ConsumerWidget.prototype.getNilmStatus = function () {
        var _this = this;
        this._apiService.getNilmStatus().subscribe(function (data) {
            if ('data' in data) {
                _this.nilmdata = data.data;
            }
        }, function (error) {
            console.log(error);
        });
    };
    /**
     * Sort & align data --> draw the diagram
     * @param data
     */
    ConsumerWidget.prototype.drawDiagram = function (data) {
        var e_1, _a, e_2, _b, e_3, _c;
        try {
            for (var _d = tslib_1.__values(Object.keys(data.data.electricity.used_budget.categories)), _e = _d.next(); !_e.done; _e = _d.next()) {
                var appliance = _e.value;
                if (data.data.electricity.used_budget.categories[appliance].usage > 0) {
                    this.tempdata.push({ name: appliance, usage: data.data.electricity.used_budget.categories[appliance].usage });
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.tempdata.sort(function (a, b) { return a.usage < b.usage; });
        try {
            for (var _f = tslib_1.__values(this.tempdata.slice(0, 3)), _g = _f.next(); !_g.done; _g = _f.next()) {
                var appliance = _g.value;
                var color = this.getColor();
                this.consumer.push({
                    name: appliance.name,
                    color: color
                });
                this.chart.addPoint({
                    name: appliance.name,
                    y: appliance.usage,
                    color: color
                });
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
        var other = 0;
        try {
            for (var _h = tslib_1.__values(this.tempdata.slice(3)), _j = _h.next(); !_j.done; _j = _h.next()) {
                var appliance = _j.value;
                other += appliance.usage;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
            }
            finally { if (e_3) throw e_3.error; }
        }
        if (other > 0) {
            var color = this.getColor();
            this.consumer.push({
                name: 'Other',
                color: color
            });
            this.chart.addPoint({
                name: 'Other',
                y: other,
                color: color
            });
        }
        this.showConsumers = this.consumer.length != 0;
    };
    /**
     * Farbe für Verbraucher
     */
    ConsumerWidget.prototype.getColor = function () {
        if (this.color < this.colors.length) {
            this.color++;
            return this.colors[this.color - 1];
        }
        else {
            return this.colors[this.colors.length - 1];
        }
    };
    /**
     * Verbraucher von API Übersetzen
     *
     * @param text
     */
    ConsumerWidget.prototype.translate = function (text) {
        switch (text) {
            case 'AlwaysOn': {
                return 'Standby';
            }
            case 'Refrigeration': {
                return 'Kühlung';
            }
            case 'SpaceHeating': {
                return 'Nachtspeicherofen';
            }
            case 'WaterHeating': {
                return 'Durchlauferhitzer';
            }
            case 'Cooking': {
                return 'Kochen';
            }
            case 'Laundry': {
                return 'Waschen & Trocknen';
            }
            case 'Lighting': {
                return 'Beleuchtung';
            }
            case 'Entertainment': {
                return 'Unterhaltung';
            }
            case 'ElectricVehicle': {
                return 'E-Auto';
            }
            case 'PoolOrSauna': {
                return 'Pool & Sauna';
            }
            case 'Other': {
                return 'Sonstige';
            }
            default: {
                return text;
            }
        }
    };
    ConsumerWidget.prototype.firstDetailViewAnalytics = function () {
        if (!(this._globals.getFirstDetailsViewed())) {
            // Erstes aufrufen eines Detail Screens
            this._apiService._analytics.eventTrack.next({
                action: 'first_detail_view',
                properties: {
                    category: 'Screens',
                    label: 'Screen: Consumer-Details'
                }
            });
        }
        this._globals.setFirstDetailsViews();
    };
    /**
     * Initialize Chart
     */
    ConsumerWidget.prototype.initializeChart = function () {
        var self = this;
        this.chart = new Chart({
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 25, 0],
                events: {
                    redraw: function () {
                        this.reflow();
                    },
                    click: function () {
                        $('.sidebar').each(function () {
                            $(this).removeClass('visible');
                        });
                        $('html').addClass('sidebar-open');
                        $('.sb-overlay').addClass('visible');
                        $('.sidebar.myDevices.consumer-details').toggleClass('visible');
                    }
                }
            },
            title: {
                text: null
            },
            tooltip: {
                hideDelay: 0,
                shadow: false,
                positioner: function (boxWidth, boxHeight) {
                    return {
                        x: (this.chart.plotWidth / 2) - (boxWidth / 2),
                        y: (this.chart.plotHeight / 2) - (boxHeight / 2)
                    };
                },
                useHTML: true,
                formatter: function () {
                    var html = '<div style="text-align: center;">';
                    var point_name = this.point.name.toLowerCase();
                    if (point_name !== 'other') {
                        if (point_name === 'laundry' || point_name === 'refrigeration' || point_name === 'entertainment' || point_name === 'cooking') {
                            var profile_complete = self.determineProfileCompleteness(point_name);
                            // nilm condition
                            if (profile_complete) {
                                html += "<i class=\"ico chart-icon icon-" + point_name + "\" style=\"color: " + this.point.color + "\"></i>";
                            }
                            else {
                                var inner = "<i class=\"boeppel\"></i>";
                                html += "<i class=\"ico iic chart-icon icon-" + point_name + "\" style=\"color: " + this.point.color + "\">" + inner + "</i>";
                            }
                        }
                        else {
                            html += "<i class=\"ico chart-icon icon-" + point_name + "\" style=\"color: " + this.point.color + "\"></i>";
                        }
                    }
                    html += '<h3>' + Math.round(this.point.y).toString().replace(/\B(?=(\d{3})+(?!\d))/, '.') + ' kWh</h3>';
                    var name = self.translate(this.point.name);
                    html += '<h4 style="color: ' + this.point.color + ';">' + name + '</h4>';
                    html += '</div>';
                    return html;
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                borderWidth: 0
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        useHTML: true,
                        formatter: function () {
                            return '<div class="label" style="color: ' + this.point.color + ';">' + Math.round(this.percentage) + ' %</div>';
                        },
                        distance: 20,
                        padding: 0,
                        connectorWidth: 1,
                        softConnector: false
                    },
                    startAngle: -180,
                    states: {
                        hover: {
                            brightness: 0,
                            halo: false
                        }
                    },
                    point: {
                        events: {
                            mouseOver: function () {
                                this.graphic.attr({
                                    r: this.shapeArgs.r + 5
                                });
                            },
                            mouseOut: function () {
                                this.graphic.attr({
                                    r: this.shapeArgs.r
                                });
                            }
                        }
                    }
                }
            },
            series: [
                {
                    name: null,
                    innerSize: '65%',
                    data: []
                }
            ],
            credits: {
                enabled: false
            }
        });
    };
    /**
     * Determines the profile completenes for a certain category.
     * @param point_name
     */
    ConsumerWidget.prototype.determineProfileCompleteness = function (point_name) {
        var e_4, _a, e_5, _b;
        if (point_name === 'refrigeration') {
            if (this.nilmdata.nonTimeBasedAppliances.refrigeration !== null || this.nilmdata.nonTimeBasedAppliances.refrigeration !== undefined) {
                var el = this.nilmdata.nonTimeBasedAppliances.refrigeration;
                return this.determineCompletenesOfAppliance(el);
            }
        }
        else if (point_name === 'cooking') {
            var oven = this.nilmdata.timeBasedAppliances.oven;
            // const hob = this.nilmdata.timeBasedAppliances.hob;
            var profiles = [oven];
            if (profiles.every(function (element) { return element !== false && element !== undefined && element !== null; })) {
                var results = [];
                try {
                    for (var profiles_1 = tslib_1.__values(profiles), profiles_1_1 = profiles_1.next(); !profiles_1_1.done; profiles_1_1 = profiles_1.next()) {
                        var element = profiles_1_1.value;
                        results.push(this.determineCompletenesOfAppliance(element));
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (profiles_1_1 && !profiles_1_1.done && (_a = profiles_1.return)) _a.call(profiles_1);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                return results.every(function (el) { return el !== false && el !== undefined && el !== null; });
            }
        }
        else if (point_name === 'laundry') {
            var washingMachine = this.nilmdata.timeBasedAppliances.washingMachine;
            var dryer = this.nilmdata.timeBasedAppliances.dryer;
            var dishWasher = this.nilmdata.timeBasedAppliances.dishWasher;
            var profiles = [washingMachine, dryer, dishWasher];
            if (profiles.every(function (element) { return element !== false && element !== undefined && element !== null; })) {
                var results = [];
                try {
                    for (var profiles_2 = tslib_1.__values(profiles), profiles_2_1 = profiles_2.next(); !profiles_2_1.done; profiles_2_1 = profiles_2.next()) {
                        var element = profiles_2_1.value;
                        results.push(this.determineCompletenesOfAppliance(element));
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (profiles_2_1 && !profiles_2_1.done && (_b = profiles_2.return)) _b.call(profiles_2);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                return results.every(function (el) { return el !== false && el !== undefined && el !== null; });
            }
        }
        else if (point_name === 'entertainment') {
            var el = void 0;
            if (this.nilmdata.nonTimeBasedAppliances.entertainment !== null ||
                this.nilmdata.nonTimeBasedAppliances.entertainment !== undefined) {
                el = this.nilmdata.nonTimeBasedAppliances.entertainment;
            }
            if (el.profileComplete || el.profileComplete === false && el.profileAdded === true) {
                return true;
            }
        }
        else {
            return true;
        }
        return false;
    };
    ConsumerWidget.prototype.determineCompletenesOfAppliance = function (el) {
        return el.profileComplete || el.profileComplete === false && el.profileAdded === true;
    };
    return ConsumerWidget;
}());
export { ConsumerWidget };
